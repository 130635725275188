/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat-full-inside': {
    width: 264,
    height: 212,
    viewBox: '0 0 264 212',
    data: '<path pid="0" d="M191.962 0H14.866C6.669 0 0 6.68 0 14.89v106.42c0 8.21 6.669 14.89 14.866 14.89h11.483v39.618a6.237 6.237 0 006.227 6.238 6.222 6.222 0 004.405-1.827l43.958-44.028h8.82a6.233 6.233 0 006.227-6.238 6.233 6.233 0 00-6.228-6.238s-11.7.008-11.822.02a6.2 6.2 0 00-3.981 1.807l-35.15 35.207s-.01-31.191-.031-31.352c-.281-3.185-2.948-5.682-6.2-5.682H14.866a2.415 2.415 0 01-2.41-2.414V14.89a2.414 2.414 0 012.41-2.414h177.096c1.33 0 2.41 1.083 2.41 2.414v40.829a6.233 6.233 0 006.228 6.237 6.233 6.233 0 006.228-6.237v-40.83C206.828 6.68 200.159 0 191.962 0z" _fill="#181A28"/><path pid="1" d="M249.134 76.299H125.17c-8.197 0-14.866 6.68-14.866 14.89v72.599c0 8.21 6.669 14.89 14.866 14.89h77.609l31.443 31.494a6.22 6.22 0 006.787 1.352 6.237 6.237 0 003.845-5.763v-27.083h4.28c8.197 0 14.866-6.68 14.866-14.89v-72.6c0-8.21-6.668-14.89-14.866-14.89zm2.411 87.489a2.415 2.415 0 01-2.41 2.414h-10.506c-3.278 0-5.958 2.537-6.204 5.757-.016.137-.026 18.743-.026 18.743l-22.613-22.65c-.023-.024-.048-.046-.072-.07a4.446 4.446 0 00-.252-.229 6.19 6.19 0 00-4.1-1.551H125.17a2.415 2.415 0 01-2.41-2.414v-72.6a2.414 2.414 0 012.41-2.413h123.965a2.415 2.415 0 012.41 2.413v72.6z" _fill="#181A28"/><path pid="2" d="M187.154 121.25c-3.438 0-6.228 2.795-6.228 6.238 0 3.443 2.79 6.238 6.228 6.238 3.438 0 6.227-2.795 6.227-6.238 0-3.443-2.79-6.238-6.227-6.238zM160.087 41.242H46.741a6.233 6.233 0 00-6.227 6.238 6.233 6.233 0 006.227 6.238h113.346a6.233 6.233 0 006.228-6.238 6.233 6.233 0 00-6.228-6.238zM89.757 82.482H46.741a6.233 6.233 0 00-6.227 6.238 6.233 6.233 0 006.227 6.238h43.016a6.232 6.232 0 006.228-6.238 6.233 6.233 0 00-6.228-6.238zM158.213 121.25c-3.437 0-6.228 2.795-6.228 6.238 0 3.443 2.791 6.238 6.228 6.238 3.438 0 6.228-2.795 6.228-6.238 0-3.443-2.79-6.238-6.228-6.238zM216.088 121.25c-3.438 0-6.228 2.795-6.228 6.238 0 3.443 2.79 6.238 6.228 6.238 3.438 0 6.228-2.795 6.228-6.238 0-3.443-2.79-6.238-6.228-6.238z" _fill="#181A28"/>'
  }
})
