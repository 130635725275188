import axios from "axios/index";
import { getDate } from "~/services/base";
import { getPhoto } from "~/services/base";

export const state = () => ({
  reviewsSources: [],
  reviews: {},
  externalReviews: {}
});

export const mutations = {
  setReviewsSources(state, data) {
    state.reviewsSources = [];
    Object.keys(data).forEach(item => {
      state.reviewsSources.push({ id: item, name: data[item] });
    });
  },
  setReviews(state, data) {
    let reviews = data;
    for (let i = 0; i < reviews.items.length; i++) {
      reviews.items[i].created_at = getDate(reviews.items[i].created_at);
      if (reviews.items[i].buyer) {
        reviews.items[i].buyer.photo = getPhoto(reviews.items[i].buyer.id, reviews.items[i].buyer.photo_uploaded_at);
      }
      if (reviews.items[i].is_external == 1) {
        if (reviews.items[i].external) {
          reviews.items[i].external.price = reviews.items[
            i
          ].external.price.toString();
          reviews.items[i].external.source = reviews.items[
            i
          ].external.source.toString();

          reviews.items[i].external.created_at = getDate(reviews.items[i].external.created_at);
        }
      }
    }
    state.reviews = reviews;
  },
  setExternalReviews(state, data) {
    let reviews = data;
    for (let i = 0; i < reviews.items.length; i++) {
      reviews.items[i].created_at = getDate(reviews.items[i].created_at);
      if (reviews.items[i].buyer) {
        reviews.items[i].buyer.photo = getPhoto(reviews.items[i].buyer.id, reviews.items[i].buyer.photo_uploaded_at);
      }
      if (reviews.items[i].is_external == 1) {
        if (reviews.items[i].external) {
          reviews.items[i].external.price = reviews.items[
            i
          ].external.price.toString();
          reviews.items[i].external.source = reviews.items[
            i
          ].external.source.toString();

          reviews.items[i].external.created_at = getDate(reviews.items[i].external.created_at);
        }
      }
    }
    state.externalReviews = reviews;
  }
};

export const getters = {
  getReviews(state) {
    return state.reviews
  },
  getExternalReviews(state) {
    return state.externalReviews
  },
}

export const actions = {
  GET_REVIEWS_SOURCES({ commit, rootState }) {
    return axios
      .get("review-external/sources")
      .then(response => {
        if (response.data.success) {
          commit("setReviewsSources", response.data.data);
        }
        response.data.data = rootState.reviews.reviewsSources;
        return response.data;
      })
      .catch(exception => {
        return exception.response;
      });
  },
  ADD_EXTERNAL_REVIEW({ commit, rootState }, data) {
    let reviews = data.reviews;
    var userId = data.userId;
    return axios
      .post("/users/" + data.userId + "/reviews/external", { reviews })
      .then(response => {
        return response.data;
      })
      .catch(exception => {
        return exception.response.data;
      });
  },
  GET_REVIEWS({ commit, rootState }, data) {
    let userId = data.userId;
    let filter;
    let requestUrl;
    let reviewsRequest;

    let page = "";
    if (data.query && data.query.reviewsPage) {
      page = "&page=" + data.query.reviewsPage;
    }


    if (data.query && data.query.filter) {
      reviewsRequest = "/reviews?" + data.query.filter + "=" + userId + "&sort=-id" + page + "&expand=order,buyer,external&per-page=20";
    } else {
      reviewsRequest = "/users/" + userId + "/reviews?sort=-id&expand=order,buyer,external&per-page=20" + page;
    }


    return axios
      .get(reviewsRequest)
      .then(response => {
        if (response.data.success) {
          commit("setReviews", response.data.data);
        }
      })
      .catch(exception => {
        console.log(exception);
      });
  },
  GET_EXTERNAL_REVIEWS({ commit, rootState }, data) {
    let userId = data.userId;
    let filter;
    let requestUrl;
    let reviewsRequest;

    let page = "";
    if (data.query && data.query.reviewsPage) {
      page = "&page=" + data.query.reviewsPage;
    }


    if (data.query && data.query.filter) {
      reviewsRequest = "/reviews?" + data.query.filter + "=" + userId + "&sort=-id&is_external=1" + page + "&expand=order,buyer,external&per-page=20";
    } else {
      reviewsRequest = "/users/" + userId + "/reviews?sort=-id&is_external=1&expand=order,buyer,external&per-page=20" + page;
    }



    return axios
      .get(reviewsRequest)
      .then(response => {
        if (response.data.success) {
          commit("setExternalReviews", response.data.data);
        }
      })
      .catch(exception => {
        console.log(exception);
      });
  }
};
