<template>
  <div
    v-if="user"
    class="page-head">
    <div class="user-wrapper">
      <user
      :avatar="user.photo ? backendUrl + user.photo : null"
      :id="user.id"
      :size="48"
      :name="user.username"
      :role="'adasd'"
      :status="user.online"
      :premium="user.premium_expired_at"
      @change-online="handleUserChangeOnline"/>
      <span 
        v-if="
          chatUserInfo && 
          chatUserInfo.anotherUser && 
          chatUserInfo.anotherUser.disconnectedAt && 
          !chatUserInfo.anotherUser.online
        "
        class="user-disconnected-at">
        Был в сети
        <timeago
          :datetime="new Date(Number(chatUserInfo.anotherUser.disconnectedAt) * 1000).toISOString()"
          :auto-update="60"/>
      </span>
    </div>
    <div class="actions-wrapper">
      <div>
      <b-button
        v-if="user && $nuxt.$route.name.includes('reviews')"
        class="btn btn-outline-secondary user-feedback"
        @click="openAddReviewModal()">
        Добавить отзыв
      </b-button>
      <button
        class="btn btn-outline-secondary user-feedback"
        @click="startChat(user)">
        <svgicon
          name="dialogs"
          width="16"
          height="12"
          class="icon-feedback"/>
        Написать пользователю
      </button>
      </div>
      <template>
        <modalReview
          v-if="games && reviewsSources"
          id="modalReview"
          :games="games"
          :reviews-sources="reviewsSources"
          :user-id="userId"/>
      </template>
    </div>
  </div>
</template>

<script>
import user from "~/components/base/user";
import { getPhoto } from "~/services/base";
import { getDate } from "~/services/base";
import modalReview from "~/components/modals/uploadReview.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    modalReview,
    user
  },
  props: {
    headTitle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      user: null,
      backendUrl: process.env.backendUrl,
      reviewsSources: null,
      userId: null,
      chatUserInfo: null,
    };
  },
  computed: {
    ...mapGetters({
      games: "newOffer/getGames",
      isChatSocketConnected: 'chat/getIsSocketConnected'
    }),
  },
  watch: {
    userId: {
      handler: async function(id) {
        if (id == undefined || !id) {
          return;
        }
        axios
          .get("/users/" + id)
          .then(async response => {
            if (response.data.success) {
              this.user = response.data.data;
              this.user.photo = getPhoto(this.user.id, this.user.photo_uploaded_at);

              if (this.isChatSocketConnected && this.chatUserInfo == null) {
                await this.userChatInfo({
                    userId: this.$store.state.auth.user.jti, 
                    anotherUserId: Number(this.user.id)
                  }).then(res => {
                    console.log(res);
                    this.chatUserInfo = res;
                  }).catch();
              }
            }
          })
          .catch(exception => {
            console.log("Что-то пошло не так. " + exception.response);
            return exception.response;
          });
      },
      deep: true,
      immediate: true
    },
    isChatSocketConnected: {
      handler: async function(isConnected) {
        console.log('CONNECTED');
        if (isConnected && this.chatUserInfo == null) {
          try {
            console.log('TRYY');
            this.chatUserInfo = await this.userChatInfo({
              userId: this.$store.state.auth.user.jti, 
              anotherUserId: Number(this.user.id)
            })
          } catch (e) {console.log(e)}
        }
      }
    }
  },
  created: async function() {
    try {
      this.chatUserInfo = await this.userChatInfo({
        userId: this.$store.state.auth.user.jti, 
        anotherUserId: Number(this.user.id)
      })
    } catch (e) {}
    
    if (this.$route.name == 'administration-orders-id')
    {
      let orderResponse = await axios.get('/orders/' + this.$route.params.id + '?expand=user');
      if (orderResponse && orderResponse.data && orderResponse.data.data && orderResponse.data.data.user)
      {
        this.userId = orderResponse.data.data.user.id;
      }

    }
    else{
      this.userId = parseInt(this.$route.params.id);
    }
    if (this.userId) {
      await axios
        .get("/users/" + this.userId)
        .then(response => {
          if (response.data.success) {
            this.user = response.data.data;
            this.user.photo = getPhoto(this.user.id, this.user.photo_uploaded_at);
            this.user.created_at = getDate(this.user.created_at);
          }
        })
        .catch(exception => {
          console.log("Что-то пошло не так. " + exception.response);
          return exception.response;
        });

     await this.$store
        .dispatch("newOffer/GET_GAMES");

      await this.$store
        .dispatch("reviews/GET_REVIEWS_SOURCES")
        .then(response => {
          if (!response.success) {
            this.$noty.error("Не удалось получить список источников.");
          } else {
            this.reviewsSources = response.data;
          }
        })
        .catch(exception => {
          this.$noty.error("Не удалось получить список источников.");
        });
    }
  },
  methods: {
    ...mapActions({
      'userChatInfo': 'chat/SOCKET_GET_CHAT_ID'
    }),
    startChat: function(user) {
      this.$store.dispatch("chat/START_CHAT", user);
    },
    openAddReviewModal(user) {
      this.$bvModal.show("modalReview");
    },
    onSubmitReview: function(data) {
      if (data.to_user_id == this.order.user.id) {
        this.review.toUser = data;
      }
      if (data.to_user_id == this.order.seller.id) {
        this.review.toSeller = data;
      }
    },
    handleUserChangeOnline(payload) {
      if (payload.isOnline == true) {
        this.chatUserInfo = null;
      } else {
        this.chatUserInfo = {
          anotherUser: {
            disconnectedAt: payload.disconnectedAt
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.page-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 16px 10px;
  h1 {
    font-size: 20px;
    font-weight: 500;
  }
}

.user-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.actions-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.user-disconnected-at {
  font-size: $text-md;
  color: $grey;
  margin-left: 15px;
}
</style>
