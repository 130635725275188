<template>
  <div
    :id="'balanceModal' + _uid"
    class="balance">
    <svgicon
      name="purse"
      width="19"
      height="18"
      class="icon-balance"/>
    {{ user.balance ? user.balance : 0 }} руб
    <svgicon
      name="chevron-down"
      width="12"
      height="20"
      class="chevron-down"/>
    <b-popover
      :id="'balancePopover' + _uid"
      :target="'balanceModal' + _uid"
      triggers="focus hover"
      placement="bottom"
      @shown="onShow()"
      @hidden="onHide()">
      <div class="d-block text-center">
        <div
          v-if="instances < 2"
          class="balance-content">
          <div class="balance-wrap">
            <template v-if="balanceTrigger && !applicationOk">
              <div class="balance-head">
                <span>Баланс</span>
                <a @click="withdrawOpen">Вывод средств</a>
              </div>
              <div class="balance-total">
                <svgicon
                  name="purse"
                  width="19"
                  height="18"
                  class="icon-balance"/>
                {{ user.balance ? user.balance : 0 }} руб
              </div>

              <div
                v-if="
                  user &&
                    parseInt(user.premium_expired_at) >= Math.floor(Date.now() / 1000)
                ">
                <div class="balance-head">
                  <span>Рекламный баланс</span>
                  <a @click="addAdsBalance">Пополнить</a>
                </div>
                <div class="balance-total ads-balance-total">
                  <svgicon
                    name="ads"
                    width="20"
                    height="20"
                    class="icon-balance"/>
                  {{ user.adsBalance ? user.adsBalance : 0 }} руб
                </div>
              </div>
              <div
                v-if="user.balance_freeze && user.balance_freeze > 0">
                <div class="balance-head">
                  <span>Заморожено</span>
                </div>
                <div class="balance-total ads-balance-total">
                  <svgicon
                    name="snow"
                    width="16"
                    height="16"
                    class="icon-snow"/>
                  {{ user.balance_freeze ? user.balance_freeze : 0 }} руб
                </div>
                <p class="freeze-label text-left">
                  Сумма замороженна так как покупатель еще может обратиться в поддержку, если у него возникнут проблемы с товаром или услугой
                </p>
              </div>
              <div class="history-link">
                <nuxt-link :to="$i18n.path('profile/transactions')">
                  История операций
                </nuxt-link>
              </div>
            </template>
            <template v-if="!balanceTrigger && !applicationOk">
              <div class="balance-withdraw-wrapper">
                <div class="balance-head">
                  <span>Вывод средств</span>
                  <a @click="withdrawOpen">Баланс</a>
                </div>
                <div class="balance-total">
                  <svgicon
                    name="purse"
                    width="19"
                    height="18"
                    class="icon-balance"/>
                  {{ user.balance ? user.balance : 0 }} руб
                  <nuxt-link :to="$i18n.path('profile/transactions')">
                    <svgicon
                      v-b-popover.hover.top="'История операций'"
                      name="history-link"
                      width="21"
                      height="18"
                      class="icon-history"/>
                  </nuxt-link>
                </div>
                <b-form class="balance-form">
                  <b-form-group class="balance-group">
                    <span class="text-left">
                      Сумма вывода:
                    </span>
                    <b-form-input
                      v-model="payoutAmount"
                      placeholder="Укажите сумму"
                      type="number"
                      :min="selectOptions[selected].min"
                      :max="
                        user.balance <= selectOptions[selected].max
                          ? user.balance
                          : selectOptions[selected].max
                      "
                      class="balance-input"/>
                    <span class="balance-note text-left">
                      Остаток после вывода: {{ user.balance - payoutAmount }} руб
                    </span>
                  </b-form-group>
                  <b-form-group class="balance-group">
                    <span class="text-left">
                      Вывести на:
                    </span>
                    <b-form-select
                      v-model="selected"
                      :options="selectOptions"/>
                    <span class="balance-note text-left">
                      К получению:
                      {{ toRecive }}
                      руб.
                    </span>
                  </b-form-group>
                  <b-form-group class="balance-group">
                    <span class="text-left">
                      {{ selectOptions[selected].inputPlaceholder }}:
                    </span>
                    <the-mask
                      v-model="payoutWallet"
                      :mask="selectOptions[selected].mask"
                      :placeholder="selectOptions[selected].inputPlaceholder"
                      :type="selectOptions[selected].type"
                      class="balance-input form-control"/>
                  </b-form-group>
                </b-form>

                <button
                  class="btn btn-success balance-up"
                  :disabled="
                    parseInt(user.is_withdraw_blocked) === 1 || blockButton
                  "
                  @click="withdrawSend">
                  Вывести средства
                </button>
                <div class="balance-footer">
                  <p
                    v-if="parseInt(user.is_withdraw_blocked) === 1"
                    class="text-danger text-left">
                    Операция вывода средств для Вашего кошелька GB заблокирована
                  </p>
                  <p
                    v-else
                    class="text-left">
                    {{ selectOptions[selected].commissionText }}
                  </p>
                </div>
              </div>
            </template>

            <template v-if="applicationOk">
              <div class="application-ok">
                <span class="application-ok-icon">
                  <svgicon
                    name="ok"
                    width="22"
                    height="15"
                    class="icon-ok"/>
                </span>
                <h3>Запрос на вывод отправлен</h3>
                В течение 1-3 дней средства поступят на ваш счет
              </div>
            </template>
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import "~/icons/purse";
import "~/icons/ads";
import "~/icons/snow";
import "~/icons/chevron-down";
import "~/icons/ok";
import { TheMask } from "vue-the-mask";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import { socket } from "~/plugins/socket-io.js";
import { errorResponse } from '~/services/errors-template';
export default {
  components: {
    TheMask
  },
  data() {
    return {
      balanceTrigger: true,
      applicationOk: false,
      selected: 0,
      blockButton: true,
      instances: 0,
      payoutAmount: 0,
      payoutWallet: "",
      selectOptions: [
        {
          value: 0,
          paymentValue: 1,
          min: 1000,
          max: 50000,
          comission: 3,
          comissionMin: 0,
          comissionPlus: 50,
          type: "text",
          mask: "#### #### #### ####",
          text: "Банковская карта РФ",
          inputPlaceholder: "Введите номер карты",
          commissionText:
            "Комиссия 3% + 50р. Минимальная сумма вывода 1000р, Максимальный 50000."
        },
        {
          value: 1,
          paymentValue: 1,
          min: 1000,
          max: 50000,
          comission: 3,
          comissionMin: 0,
          comissionPlus: 200,
          type: "text",
          mask: "#### #### #### ####",
          text: "Банковская карта не РФ",
          inputPlaceholder: "Введите номер карты",
          commissionText:
            "Комиссия 3.0% + 200р. Минимальная сумма вывода 1000р, максимальный 50000."
        },
        /*{
          value: 2,
          paymentValue: 2,
          min: 15,
          max: 14700,
          comission: 2,
          comissionMin: 0,
          type: "text",
          mask: "A############",
          text: "WebMoney R и P",
          inputPlaceholder: "Введите номер кошелька",
          commissionText:
            "Комиссия 2.0%. Минимум 15р. Максимум 140,000P в день и не более 2 мл. P в месяц. 14700р за операцию."
        },
        {
          value: 3,
          paymentValue: 2,
          min: 15,
          max: 14700,
          comission: 2.5,
          comissionMin: 0,
          type: "text",
          mask: "A############",
          text: "WebMoney Z",
          inputPlaceholder: "Введите номер кошелька",
          commissionText:
            "Комиссия 2.5%. Минимум 15р. Максимум 140,000P в день и не более 2 мл. P в месяц. 14700р за операцию."
        },*/
        {
          value: 2,
          paymentValue: 3,
          min: 10,
          max: 5000,
          comission: 2,
          comissionMin: 0,
          comissionPlus: 0,
          type: "tel",
          mask: "+############",
          text: "Qiwi",
          inputPlaceholder: "Введите номер телефона",
          commissionText:
            "Комиссия 2.0%. Минимум 10P и не более 5,000P, за одну операцию."
        },
        // {
        //   value: 5,
        //   paymentValue: 4,
        //   min: 50,
        //   max: 15000,
        //   comission: 3,
        //   comissionMin: 0,
        //   type: "text",
        //   mask: "################",
        //   text: "ЮMoney",
        //   inputPlaceholder: "Введите номер кошелька",
        //   commissionText:
        //     "Комиссия 3.0%. Минимум 50P и не более 15,000P за одну операцию."
        // },
        {
          value: 3,
          paymentValue: 5,
          min: 10,
          max: 5000,
          comission: 4,
          comissionMin: 0,
          type: "tel",
          mask: "+############",
          text: "Мобильный телефон РФ",
          inputPlaceholder: "Введите номер телефона",
          commissionText:
            "Комиссия 4.0%. Минимум 10P и не более 5,000P за одну операцию."
        },
        {
          value: 4,
          paymentValue: 6,
          min: 7700,
          max: 999999,
          comission: 5,
          comissionMin: 100,
          comissionPlus: 100,
          type: "text",
          mask: "TXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
          text: "USDT TRC-20",
          inputPlaceholder: "Введите адрес кошелька",
          commissionText:
            "Комиссия 5.0% + 100Р. Минимум 7700Р и не более 999,000Р за одну операцию."
        },
        {
          value: 5,
          paymentValue: 7,
          min: 77000,
          max: 999999,
          comission: 5,
          comissionMin: 1500,
          comissionPlus: 1500,
          type: "text",
          mask: "0xXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
          text: "USDT ERC-20",
          inputPlaceholder: "Введите адрес кошелька",
          commissionText:
            "Комиссия 5.0% + 1500Р. Минимум 77,000P и не более 999,000P за одну операцию."
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      isPhoneVerified: "auth/getIsPhoneVerified"
    }),
    toRecive: function() {
      let comission =
        (this.payoutAmount * this.selectOptions[this.selected].comission) / 100;
      comission += this.selectOptions[this.selected].comissionPlus;

      let recive = this.payoutAmount - comission;

      if (comission < this.selectOptions[this.selected].comissionMin) {
        recive =
          this.payoutAmount - this.selectOptions[this.selected].comissionMin;
      }

      if (recive < 0) return 0;

      return recive;
    }
  },
  watch: {
    payoutAmount() {
      if (parseInt(this.payoutAmount) > parseInt(this.user.balance)) {
        if (this.user.balance < this.selectOptions[this.selected].max) {
          this.payoutAmount = this.user.balance;
        } else {
          this.payoutAmount = this.selectOptions[this.selected].max;
        }
      }

      if (
        this.payoutAmount >= this.selectOptions[this.selected].min &&
        this.payoutWallet != "" &&
        this.payoutWallet.length > 0
      ) {
        this.blockButton = false;
      } else {
        this.blockButton = true;
      }
    },
    payoutWallet() {
      if (
        this.payoutAmount >= this.selectOptions[this.selected].min &&
        this.payoutWallet != "" &&
        this.payoutWallet.length > 0
      ) {
        this.blockButton = false;
      } else {
        this.blockButton = true;
      }
    },
    selected() {
      if (
        this.payoutAmount >= this.selectOptions[this.selected].min &&
        this.payoutWallet != "" &&
        this.payoutWallet.length > 0
      ) {
        this.blockButton = false;
      } else {
        this.blockButton = true;
      }
    },
    applicationOk() {
      if (this.applicationOk) {
        setTimeout(() => {
          this.applicationOk = false;
        }, 3000);
      }
    }
  },
  mounted() {
    let _this = this;
    socket.on("balance", data => {
      if (data.balance) {
        _this.setBalance({
          balance: parseFloat(data.balance).toFixed(2),
          balance_freeze: parseFloat(data.balance_freeze).toFixed(2),
          adsBalance: parseFloat(data.ads_balance).toFixed(2)
        });
        _this.$forceUpdate();
      }
    });
  },
  methods: {
    ...mapMutations({
      setBalance: "auth/setBalance"
    }),
    withdrawOpen: function() {
      if (this.isPhoneVerified) {
        this.balanceTrigger = !this.balanceTrigger;
      } else {
        this.$store
          .dispatch("users/GET_IS_SELLER")
          .then((response) => {
            if (response.success) {
              if (!this.isPhoneVerified && response.data) {
                this.$bvModal.show("modalConfirmPhone");
                return;
              }
              this.balanceTrigger = !this.balanceTrigger;
            } else {
              this.$noty.error(errorResponse(response.data));
            }
          }).catch(exception => {
            this.$noty.error(errorResponse(exception.response));
          });
      }
    },
    onShow() {
      this.instances++;
    },
    onHide() {
      this.instances--;
    },
    withdrawSend() {
      axios
        .post("users/" + this.user.jti + "/balance/withdraw", {
          withdraw_type: this.selectOptions[this.selected].paymentValue,
          withdraw_wallet: this.payoutWallet,
          amount: this.payoutAmount
        })
        .then(res => {
          if (res.data.success) {
            this.applicationOk = true;
            this.$noty.success("Запрос на вывод успешно создан!");
            this.applicationOk = true;
          } else {
            this.$noty.error(
              res.data ? res.data.data.message : "Произошла ошибка!"
            );
          }
        })
        .catch(err => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.data &&
            err.response.data.data.message
          ) {
            this.$noty.error(err.response.data.data.message);
          } else {
            this.$noty.error("Произошла ошибка!");
          }
        });
    },
    addAdsBalance(){
      this.$bvModal.show('modalPaymentAds');
    }
  }
};
</script>

<style lang="scss" scoped>
.balance {
  position: relative;
  display: inline-block;
  padding: 0 15px;
  line-height: 40px;
  font-size: rem(14px);
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  background: rgba($color: #d8d8d8, $alpha: 0.1);
  cursor: pointer;
  white-space: nowrap;
  transition: $link-transition;
  + .avatar {
    margin-left: 15px;
  }
}
.icon-balance {
  margin-right: 7px;
  fill: #6e7279;
}

.chevron-down {
  background: transparent;
  margin-left: 20px;
}

.balance-content {
  top: 100%;
  right: 0;
  padding-top: 5px;
  color: #6e7279;
  white-space: normal;
  z-index: 100;
}

.balance-wrap {
  width: 308px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: #6e7279;
}
.balance-head {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 19px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1;
  color: #6e7279;
  justify-content: space-between;

  a {
    color: $blue;
    cursor: pointer;
    text-transform: none;
    font-size: 12px;
    &:hover {
      color: $blue;
    }
  }
}
.balance-total {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 500;
  color: $body-color;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 12px;

}
.freeze-label{
  padding-left: 16px;
  padding-right: 16px;
  margin: 0;
}
.balance-withdraw-wrapper {
  padding-bottom: 16px;
  .balance-head{
    border-bottom: none;
    border-top: none;
  }
  .balance-total{
    border-bottom: 1px solid #eff1f4;
    padding-bottom: 28px;
    margin-bottom: 15px;
  }
}
.ads-balance-total{
  font-size: 16px;
}
.history-link{
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
}
.balance-blocked {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  color: #a8aaaf;
}
.icon-snow {
  margin-right: 8px;
  fill: $blue;
}
.balance-up {
  width: calc(100% - 32px);
  height: 40px;
  padding: 0;
}
.balance-form {
  font-size: 14px;
  color: $body-color;
  padding-left: 16px;
  padding-right: 16px;
}
.balance-group {
  margin-bottom: 14px;
  span {
    margin-bottom: 5px;
    display: block;
  }
}
.balance-input {
  height: 40px;
}
.balance-note {
  margin-top: 5px;
  font-size: 12px;
  color: #6e7279;
}
.balance-footer {
  margin-top: 6px;
  text-align: center;
  font-size: 12px;
  padding-left: 16px;
  padding-right: 16px;
  color: #6e7279;
  p {
    margin: 0;
    font-size: 12px;
    line-height: 17px;
  }
}
.application-ok {
  padding-top: 36px;
  padding-bottom: 36px;
  padding-left: 42px;
  padding-right: 42px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #6e7279;
  h3 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: $body-color;
  }
}
.application-ok-icon {
  margin-bottom: 16px;
  display: inline-block;
  width: 64px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  color: #ffffff;
  background: $green;
  border-radius: 50%;
}
.icon-ok {
  fill: #ffffff;
}
</style>
