import { socket } from "~/plugins/socket-io.js";
export default function ({ app, store }) {
  app.router.onReady(async function () {
    const accessToken = app.$cookiz.get('access-token');

    if (accessToken){
      await store.dispatch('auth/EMIT_LOGIN', accessToken);
      await store.dispatch('notifications/INIT_NOTIFICATIONS');
      await store.dispatch('notifications/GET_NOTIFICATIONS');
      await store.dispatch('chat/INIT_CHAT', accessToken);
      await store.dispatch('auth/GET_BALANCE'); 
      await store.dispatch('users/GET_NOTIFIERS', { userId: store.state['auth'].user.jti })
    }

    socket.on('connect', async () => {
      console.log('CONNECTED FROM ON_READY.JS');
      if (accessToken) {
        await store.dispatch('auth/EMIT_LOGIN', accessToken);
        await store.dispatch('notifications/INIT_NOTIFICATIONS');
        await store.dispatch('notifications/GET_NOTIFICATIONS');
        await store.dispatch('auth/GET_BALANCE');
        await store.dispatch('users/GET_NOTIFIERS', { userId: store.state['auth'].user.jti })
      }
    });
  })
}

