/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'snow': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" opacity=".5" d="M20 9h-4.17l3.24-3.24-1.41-1.42L13 9h-2V7l4.66-4.66L14.24.93 11 4.17V0H9v4.17L5.76.93 4.34 2.34 9 7v2H7L2.34 4.34.93 5.76 4.17 9H0v2h4.17L.93 14.24l1.41 1.42L7 11h2v2l-4.66 4.66 1.42 1.41L9 15.83V20h2v-4.17l3.24 3.24 1.42-1.41L11 13v-2h2l4.66 4.66 1.41-1.42L15.83 11H20V9z" _fill="#fff"/>'
  }
})
