<template>
  <div class="user">
    <client-only>
      <template v-if="user">
        <span
          v-if="!user.premium_expired_at || parseInt(user.premium_expired_at) < Math.floor(Date.now() / 1000)"
          class="user-start-sale"
          @click="goTo">
          Создать предложение
        </span>
        <headerNewOffer v-else/>
        <headerBalance/>
        <headerUserNav :user="user"/>
      </template>
      <template v-if="!user">
        <span
          v-b-modal.modalLoginSocial
          class="login">
          {{ $t('base.login') }}
        </span>
      </template>
    </client-only>
  </div>
</template>

<script>
  import headerUserNav from '~/components/header/headerUserNav';
  import headerBalance from './headerBalance';
  import headerNewOffer from './headerNewOffer';
  import '~/icons/purse';
  import '~/icons/profile';
  import '~/icons/settings';
  import '~/icons/portfolio';
  import '~/icons/basket';
  import '~/icons/graph';
  import '~/icons/check';
  import "~/icons/chevron-down";
  import '~/icons/exit';
  import {mapGetters} from 'vuex';

  export default {
    components: {
      headerBalance,
      headerUserNav,
      headerNewOffer
    },
    computed: {
      ...mapGetters({
        user: 'auth/getUser'
      })
    },
    methods: {
      goTo(){
        if(this.user.is_active){
          return this.$router.push({path: this.user.user_agreement === 1 ? '/profile/offers/new' : '/profile/offers/rules'});
        } else {
          this.$bvModal.show('modalRegistrationCode');
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .user{
    display: flex;
    align-items: center;
  }
  .login{
    display: inline-block;
    padding: 0 15px;
    line-height: 40px;
    margin-right: 16px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    background: rgba(216, 216, 216, 0.1);
    border-radius: 4px;
    cursor: pointer;
    transition: $link-transition;
    &:hover{
      background: rgba(#6E7279, .5);
    }
  }
  .logout{
    margin-left: 10px;
    font-size: rem(14px);
    color: #ffffff;
    cursor: pointer;
  }
  .user-start-sale{
    display: inline-block;
    padding: 0 15px;
    line-height: 40px;
    margin-right: 16px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
    background: rgba(216, 216, 216, 0.1);
    border-radius: 4px;
    cursor: pointer;
    &:hover{
      background: rgba(#6E7279, .5);
    }
  }


</style>
