<template>
  <b-modal
    id="modalRegistrationCode"
    v-model="show"
    class="modalAuth"
    centered
    @hide="resetForm">
    <div
      slot="modal-header"
      :header-border-variant="0"
      class="modalHeader">
      <svgicon
        name="close"
        width="14"
        height="14"
        class="closeModal"
        @click="show=false"/>
      <img
        src="/img/logoBlack.svg"
        alt="Gobuffy">
    </div>
    <div class="modalContent">
      <br>
      <h3>Подтверждение регистрации</h3>

      <div class="modalDescription">
        Введите код который Вы получили в письме для окончания регистрации
      </div>

      <baseAuthForm
        :on-submit="onSubmit"
        :inputs="inputs"
        :url="url"
        :submit-text="submitText"
        :active="show"/>
    </div>

    <div
      slot="modal-footer"
      class="modalFooter">
      <p>
        <a
          class="modalFooterLink"
          @click="show = false">
          Вернуться назад
        </a>
      </p>
    </div>
  </b-modal>
</template>

<script>
  import baseAuthForm from './_base-auth-form';
  export default {
    components: {
      baseAuthForm
    },
    props: {
      registerStatus: {
        type: Boolean,
        default: false
      },
      onRegisterActivation: {
        type: Function,
        default: () => {},
      },
      email: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        show: false,
        inputs: [
          {
            name: 'code',
            value: '',
            placeholder: '',
            type: 'text',
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          },
          {
            name: 'email',
            value: '',
            placeholder: '',
            type: 'email',
            hidden: true,
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          }
        ],
        formError: {
          value: false,
          text: 'Что-то пошло не так'
        },
        formSubmit: false,
        submitText: 'Отправить',
        url: 'auth/REGISTRATION_CODE',
      }
    },
    watch: {
      registerStatus: function () {
        console.log(this.registerStatus);
        this.show = this.registerStatus;
        console.log(this.show);
      },
      email: function () {
        this.inputs[1].value = this.email;
      }
    },
    created: function(){
      this.inputs[1].value = this.email;
    },
    methods: {
      resetForm: function(){
        for(let i = 0; i < this.inputs.length; i++){
          if(this.inputs[i].type === 'email') continue;
          this.inputs[i].value = '';
          this.inputs[i].errorFlag = false;
        }
      },
      onSubmit (data) {
        this.formSubmit = data;
        this.onRegisterActivation(data);
        this.show = false;
      }
    }
  }
</script>
<style lang="scss" scoped>
  .restoreCode{
    width: 192px;
    max-width: 100%;
    margin: 0 auto 10px;
  }
  .modalInput{
    text-align: center;
  }
</style>