import { socket } from "~/plugins/socket-io.js";
export default function ({ app, store }) {
    let events = ["touch", "mousemove", "keypress"];
    let display = 0;
    let timer = undefined;
    let start = 0;
    let counter = undefined;
    let diff = 0;
    let minutes = "";
    let seconds = "";
    let duration = 60;
    let wait = 0;
    let loop = true;

    document.addEventListener("visibilitychange", function () {
        if (document.visibilityState === 'visible') {
            if (store.state.global.isIdle) {
                afterIdle();
                store.dispatch("global/SET_IS_IDLE", false);
            }
        }
    });

    setTimeout(() => {
        start = Date.now();
        setDisplay();

        setTimer();
        for (let i = events.length - 1; i >= 0; i -= 1) {
            window.addEventListener(events[i], clearTimer);
        }

    }, wait * 1000);

    function setDisplay() {
        // seconds since start
        diff = duration - (((Date.now() - start) / 1000) | 0);
        if (diff < 0 && !loop) {
            return;
        }

        // bitwise OR to handle parseInt
        const minute = (diff / 60) | 0;
        const second = diff % 60 | 0;

        minutes = `${minute < 10 ? "0" + minute : minute}`;
        seconds = `${second < 10 ? "0" + second : second}`;

        display = `${minutes}:${seconds}`;
    }

    function countdown() {
        setDisplay();

        if (diff <= 0 && loop) {
            // add second to start at the full duration
            // for instance 05:00, not 04:59
            start = Date.now() + 1000;
        }
    }

    function idle() {
        store.dispatch("global/SET_IS_IDLE", true);
    }

    function afterIdle() {
        if (store.state.auth && store.state.auth.user) {
            store.dispatch('chat/SOCKET_RECONNECT');
            socket.close();
            socket.open();
        }
    }

    function setTimer() {
        timer = window.setInterval(idle, duration * 1000);
        counter = window.setInterval(countdown, 1000);
    }

    function clearTimer() {
        clearInterval(timer);
        clearInterval(counter);
        setDisplay();
        start = Date.now();
        diff = 0;
        setTimer();
    }
};

