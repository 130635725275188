
export const state = () => ({
  breadcrumbs: null
});

export const mutations = {
  setBreadcrumbs(state, data) {
    //data.splice(-1,1);
    console.log('setBreadcrumbs');
    state.breadcrumbs = data;
  },
  changeCurrentTitle(state, title){
    console.log('changeCurrentTitle');
    let breadcrumbsLength = state.breadcrumbs.length;
    state.breadcrumbs[breadcrumbsLength - 1].title = title;
  },
  //removeLastItem(state){
  //  console.log('removeLastItem');
  //  state.breadcrumbs.splice(-1,1)
  //},
  addBeforeLastItem(state, item){
    let breadcrumbsLength = state.breadcrumbs.length;
    let lastElement  = JSON.parse(JSON.stringify(state.breadcrumbs[breadcrumbsLength - 1]));
    state.breadcrumbs.splice(-1,1);
    state.breadcrumbs.push(item);
    state.breadcrumbs.push(lastElement);
  }
};
export const actions = {
  SET_BREADCRUMBS({commit}, data){
    commit('setBreadcrumbs', data);
  },
  CHANGE_CURRENT_TITLE({commit}, title){
    commit('changeCurrentTitle', title);
  },
  //REMOVE_LAST_ITEM({commit}){
  //  commit('removeLastItem');
  //},
  ADD_BEFORE_LAST_ITEM({commit}, item){
    commit('addBeforeLastItem', item);
  }
}