/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification-info': {
    width: 6,
    height: 16,
    viewBox: '0 0 6 16',
    data: '<path pid="0" d="M.91 6.835v-.69c.181-.082.423-.163.726-.245.303-.094.625-.176.964-.244.34-.067.673-.121 1-.162.34-.054.636-.081.891-.081l.327.243-1.636 8.759h1.273v.69a3.366 3.366 0 01-.564.346 4.55 4.55 0 01-.673.285 4.22 4.22 0 01-.709.182 3.17 3.17 0 01-.69.082c-.461 0-.783-.095-.964-.285a.998.998 0 01-.255-.65c0-.27.018-.535.055-.792.036-.258.084-.542.145-.854l1.236-6.3L.91 6.836zm1.508-4.572c0-.46.14-.82.418-1.077C3.116.929 3.466.8 3.891.8c.46 0 .824.129 1.09.386.28.257.42.616.42 1.077 0 .433-.14.779-.42 1.036-.266.258-.63.386-1.09.386-.424 0-.776-.128-1.055-.386-.278-.257-.418-.603-.418-1.036z" _fill="#fff"/>'
  }
})
