/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M.293 1.707L1.707.293l12 12-1.414 1.414-12-12zm12-1.414l1.414 1.414-12 12-1.414-1.414 12-12z" _fill="#A8AAAF" fill-rule="nonzero"/>'
  }
})
