import axios from "axios/index";
import { numToString } from "~/services/base";
export const state = () => ({
  games: [],
  gameCategories: [],
  submitStatus: false,
  validAttributes: true
});

export const mutations = {
  setGames(state, data) {
    state.games = data
  },
  setgameCategories(state, data) {
    state.gameCategories = data
  },
  changeSubmitStatus(state, status) {
    state.submitStatus = status;
  },
  validAttributes(state, status) {
    state.validAttributes = status;
  }
};
export const getters = {
  getLang: (state) => {
    return state.locale;
  },
  getGames: (state) => {
    return state.games;
  }
};
export const actions = {

  async GET_GAMES({ commit, rootState }) {

    let gamesRes = await axios.get(
      '/games?per-page=50' + ((rootState.auth.user && rootState.auth.user.role === 1) ? '' : '&status=1')
    );
    if (gamesRes && gamesRes.data && gamesRes.data.data) {
      while (gamesRes.data.data._links && gamesRes.data.data._links.next) {
        var next = gamesRes.data.data._links.next.href;
        if (next) {
          next = next.replace(process.env.backendUrl, "");
          next = next.replace("http:", "");
          next = next.replace("https:", "");
          next = next.replace("/v1/", "");
          var nextPage = await axios.get(next);
          gamesRes.data.data.items = gamesRes.data.data.items.concat(
            nextPage.data.data.items
          );
          gamesRes.data.data._links.next = nextPage.data.data._links.next;
        }
      }
    }
    let games = gamesRes.data.data.items;
    for (let i = 0; i < games.length; i++) {
      games[i].countText = numToString(games[i].offer_count, [
        "предложение",
        "предложения",
        "предложений"
      ]);
    }
    commit('setGames', games);
    return Promise.resolve(games);
  },
  SET_GAMES({ commit }, data) {
    commit('setGames', data);
  },
  GET_GAME_CATEGORIES({ commit }, id) {
    return axios.get('/categories/' + id + '/final')
      .then(response => {
        if (response.data.success) {
          commit('setgameCategories', response.data.data);
        }
        else {
          console.log(response.data);
        }
        return response.data;
      })
      .catch(exception => {
        return exception.response
      });
  },
  CHANGE_SUBMIT_STATUS({ commit }, status) {
    console.log('change status start');
    commit('changeSubmitStatus', status);
    console.log('change status end');
  },
  VALID_ATTRIBUTES({ commit }, status) {
    commit('validAttributes', status);
  },
  VALIDATION_ATTRIBUTES({ commit, state, dispatch }) {
    commit('validAttributes', true);
    dispatch('CHANGE_SUBMIT_STATUS', true).then(() => {
      if (state.validAttributes == true) {
        return true;
      }
      else {
        return false;
      }
    });
  }
};