/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'back': {
    width: 18,
    height: 12,
    viewBox: '0 0 18 12',
    data: '<path pid="0" d="M17 5a1 1 0 010 2H1a1 1 0 110-2h16zM1.684 6.73A1 1 0 01.316 5.27l5.333-5a1 1 0 011.368 1.46l-5.333 5zm-1.368 0a1 1 0 011.368-1.46l5.333 5a1 1 0 01-1.368 1.46l-5.333-5z" _fill="#A8AAAF" fill-rule="nonzero"/>'
  }
})
