<template>
  <b-form @submit.prevent="submit">
    <b-form-group
      v-for="(input, index) in inputs"
      :key="index"
      class="modalFormGroup">
      <span
        v-if="input.errorFlag"
        class="modalError">
        {{ input.errorText }}
      </span>
      <b-form-input
        v-model="input.value"
        :class="{'is-invalid': input.errorFlag}"
        :placeholder="input.placeholder"
        :type="input.type"
        :hidden="input.hidden"
        class="modalInput"
        @keyup.native="resetValidation"/>
    </b-form-group>
    <b-alert
      :show="formError.value"
      variant="danger">
      {{ formError.text }}
    </b-alert>
    <b-button
      :disabled="loading"
      type="submit"
      variant="success"
      class="modalSubmit">
      {{ submitText }}
    </b-button>
  </b-form>
</template>

<script>
  export default {
    props: {
      inputs: {
        type: Array,
        default: () => [],
      },
      url: {
        type: String,
        default: ''
      },
      submitText: {
        type: String,
        default: ''
      },
      onSubmit: {
        type: Function,
        default: () => {},
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        formError: {
          value: false,
          text: 'Что-то пошло не так'
        },
        formSubmit: false,
        loading: false
      }
    },
    computed: {
      errorFlags() {
        let count = 0;

        for (let i = 0; i < this.inputs.length; i++) {
          if (this.inputs[i].errorFlag) {
            count++;
          }
        }

        return count;
      }
    },
    watch: {
      active: function () {
        console.log('active change');
        this.formError.value = false;
      },
    },
    methods: {
      resetValidation(){
        this.inputs.forEach(input=>{
          input.errorFlag = false
        });
      },
      submit: function (event) {
        this.loading = true;
        for(let i = 0; i < this.inputs.length; i++){
          if (this.inputs[i].value === ''){
            this.inputs[i].errorFlag = true
          }
        }
        if (this.errorFlags !== 0){
          event.preventDefault();
          this.loading = false;
        }
        else{
          let data = {};
          for(let i = 0; i < this.inputs.length; i++){
            data[this.inputs[i].name] = this.inputs[i].value;
          }
          console.log(data);
          this.$store.dispatch(this.url, data).then(result => {
            console.log(result);
            this.loading = false;
            if(result && result.success){
              if(this.url.includes('auth/REGISTRATION_CODE')){
                this.$store.commit('auth/setIsActive', {isActive: true});
              }
              this.onSubmit(true);
              this.formSubmit = true;
              console.log('yes');
            }
            else{
              console.log(result);
              if(result && result.status == 422){
                let errors = result.data.data;
                for(let i = 0; i < errors.length; i++){
                  for(let n = 0; n < this.inputs.length; n++){
                    if(this.inputs[n].name == errors[i].field){
                      this.inputs[n].errorText = errors[i].message;
                      this.inputs[n].errorFlag = true;
                    }
                  }
                }
              }
              else{
                this.formError.value = true;
              }
            }
          }).catch(error => {
            this.loading = false;
            this.formError.value = true;
            console.log(error);
          });
        }
      }
    }
  }
</script>