var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user"},[(!_vm.premium || parseInt(_vm.premium) < Math.floor(Date.now() / 1000))?_c('div',{staticClass:"user-avatar"},[_c('i',{class:_vm.statusNow ? 'user-status online' : 'user-status'}),_vm._v(" "),(_vm.avatar && _vm.avatar != '' && _vm.avatar != null && _vm.isAvatarLoaded)?_c('img',{staticClass:"user-img",attrs:{"width":_vm.size,"height":_vm.size,"src":_vm.avatar,"alt":""},on:{"load":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.avatarLoadSuccess.apply(null, arguments)},"error":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.avatarLoadError.apply(null, arguments)}}}):_c('span',{staticClass:"no-avatar",style:('width:' +
          _vm.size +
          'px;height:' +
          _vm.size +
          'px;line-height:' +
          _vm.size +
          'px;' +
          'font-size:' +
          parseInt(_vm.size / 2) +
          'px')},[_vm._v("\n      "+_vm._s(_vm.name.charAt(0))+"\n    ")])]):_vm._e(),_vm._v(" "),(!_vm.premium || parseInt(_vm.premium) < Math.floor(Date.now() / 1000))?_c('div',{staticClass:"user-info"},[_vm._v("\n    "+_vm._s(_vm.name.length > 11 ? _vm.getElipsis(_vm.name) : _vm.name)+"\n    "),(_vm.role != '')?_c('span',{staticClass:"user-role"},[_vm._v("\n      "+_vm._s(_vm.role)+"\n    ")]):_vm._e()]):_vm._e(),_vm._v(" "),(parseInt(_vm.premium) >= Math.floor(Date.now() / 1000))?_c('div',{staticClass:"user-avatar user-avatar-pro"},[(parseInt(_vm.premium) >= Math.floor(Date.now() / 1000))?_c('nuxt-link',{staticClass:"user-info user-info-pro",attrs:{"to":'/profile/' + _vm.id + '/' + _vm.filter},nativeOn:{"click":function($event){return _vm.emitHitAd.apply(null, arguments)}}},[_c('i',{class:_vm.statusNow ? 'user-status online' : 'user-status'}),_vm._v(" "),_c('img',{staticClass:"pro-border",attrs:{"src":"/svg/pro-border.svg","width":_vm.size + 8,"height":_vm.size + 8}}),_vm._v(" "),(_vm.avatar && _vm.avatar != '' && _vm.avatar != null && _vm.isAvatarLoaded)?_c('img',{staticClass:"user-img",attrs:{"width":_vm.size,"height":_vm.size,"src":_vm.avatar,"alt":""},on:{"load":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.avatarLoadSuccess.apply(null, arguments)},"error":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.avatarLoadError.apply(null, arguments)}}}):_c('span',{staticClass:"no-avatar",style:('width:' +
            _vm.size +
            'px;height:' +
            _vm.size +
            'px;line-height:' +
            _vm.size +
            'px;' +
            'font-size:' +
            parseInt(_vm.size / 2) +
            'px')},[_vm._v("\n        "+_vm._s(_vm.name.charAt(0))+"\n      ")])]):_vm._e()],1):_vm._e(),_vm._v(" "),(parseInt(_vm.premium) >= Math.floor(Date.now() / 1000))?_c('nuxt-link',{staticClass:"user-info user-info-pro",attrs:{"to":'/profile/' + _vm.id + '/' + _vm.filter},nativeOn:{"click":function($event){return _vm.emitHitAd.apply(null, arguments)}}},[_vm._v("\n    "+_vm._s(_vm.name.length > 11 ? _vm.getElipsis(_vm.name) : _vm.name)+"\n    "),_c('span',{staticClass:"user-role-pro"},[_vm._v(" PRO "),_c('img',{attrs:{"src":"/img/pro-stars.png"}})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }