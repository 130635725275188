import axios from "axios/index";
import { categoryPath } from '~/services/category'
import { makeIndex } from '~/services/category'
import { categorySelect } from '~/services/category'
import { normalizeIndexes } from '~/services/category'
import { numToString } from "~/services/base";

export const state = () => ({
  gameId: '',
  game: null,
  games: [],
  categoryGroups: {
    children: [],
    onSort: 0
  },
  selectGroup: [],
  activeGroup: {},
  activeCategory: {},
  activeGroupIndex: [],
  errors: ''
});

export const getters = {
  getGames: (state) => {
    return state.games;
  },
  getGame: (state) => {
    return state.game;
  }
}

export const mutations = {
  setGameId(state, data) {
    state.gameId = data.id;
  },
  setGame(state, data) {
    state.game = data;
  },
  setGames(state, data) {
    state.games = data;
  },
  setGameStatus(state, data) {
    if (state.games && state.games.length > 0) {
      let game = state.games.filter(item => item.id == data.id)[0];
      if (game) {
        game.status = data.status;
      }
    }
  },
  setCategoryGroups(state, data) {
    state.categoryGroups.children = data;
  },
  setSelect(state, data) {
    state.selectGroup = data;
  },
  triggerGroup(state, data) {
    let group = categoryPath(state.categoryGroups.children, data.index);
    group.open = !group.open;
    group.seo = data.group.seo;
    state.activeCategory = {};
    state.activeGroup = group;
    state.activeGroupIndex = data.index.slice();
  },
  closeGroup(state, data) {
    if (data && data.index) {
      let group = categoryPath(state.categoryGroups.children, data.index);
      if (group) {
        if (group.open) {
          group.open = false;
          state.activeCategory = {};
          state.activeGroup = group;
          state.activeGroupIndex = data.index.slice();
        }
      }
    }
  },
  openCategory(state, data) {
    state.activeGroup = {};
    state.activeCategory = data;
    normalizeIndexes(state.categoryGroups.children, []);
  },
  addCategory(state, data) {
    data.category.open = false;
    if (!data.category.parent_id) {
      data.category.open = false;
      data.category.index = [];
      data.category.index.push(state.categoryGroups.children.length);
      state.categoryGroups.children.push(data.category);
    }
    else {
      let activePath = categoryPath(state.categoryGroups.children, data.index);
      data.category.index = data.index.slice();
      if (activePath.children) {
        data.category.index.push(activePath.children.length);
      }
      else {
        activePath.children = [];
        data.category.index.push(0);
      }
      activePath.children.push(data.category);
    }
    state.selectGroup.push(data.category);
    normalizeIndexes(state.categoryGroups.children, []);
  },
  changeGroupName(state, data) {
    let activePath = categoryPath(state.categoryGroups.children, data.index);
    state.errors = '';
    activePath.name = data.name;
  },
  changeCategory(state, data) {
    if (data.index.length == 1) {
      Object.assign(state.categoryGroups.children[data.index], data);
      state.activeCategory = data;
      if (!state.activeCategory.seo) {
        state.activeCategory.seo = {};
        state.activeCategory.seo.h1 = '';
        state.activeCategory.seo.title = '';
        state.activeCategory.seo.description = '';
        state.activeCategory.seo.keywords = '';
        state.activeCategory.seo.text = '';
      }

    }
    else {
      let activePath = categoryPath(state.categoryGroups.children, state.activeGroupIndex);
      state.errors = '';
      for (let i = 0; i < activePath.children.length; i++) {
        if (activePath.children[i].id == data.id) {
          Object.assign(activePath.children[i], data);
        }
      }
    }
  },
  setErrors(state, data) {
    state.errors = data;
  },
  clearState(state) {
    state.gameId = '';
    state.categoryGroups = {
      children: []
    };
    state.activeGroup = {};
    state.activeCategory = {};
    state.errors = ''
  },
  deleteGroup(state) {
    state.activeGroup = {};
    normalizeIndexes(state.categoryGroups.children, []);
  },
  deleteCategory(state) {
    state.errors = '';
    /*if (state.activeCategory.index.length == 1) {
      state.categoryGroups.children.splice(state.activeCategory.index[0], 1);
      state.activeCategory = {};
    }
    else {
      let indexParent = state.activeCategory.index.slice();
      indexParent.splice(-1, 1);
      let activePath = categoryPath(state.categoryGroups.children, indexParent);
      activePath.children.splice(state.activeCategory.index[state.activeCategory.index.length - 1], 1);
      state.categoryGroups.children = makeIndex(state.categoryGroups.children, undefined);
      state.activeCategory = {};
    }*/
    state.activeCategory.is_archived = 1;
    var child = categoryPath(state.categoryGroups.children, state.activeCategory.index);
    child.is_archived = 1;

  },
  changeStatusCategory(state, data) {
    if (state.activeCategory.index.length == 1) {
      state.categoryGroups.children[state.activeCategory.index[0]].status = data;
      state.activeCategory.status = data;
    }
    else {
      let activePath = categoryPath(state.categoryGroups.children, state.activeGroupIndex);
      for (let i = 0; i < activePath.children.length; i++) {
        if (activePath.children[i].id == state.activeCategory.id) {
          activePath.children[i].status = data;
          state.activeCategory.status = data;
        }
      }
    }
  },
  changeStatusGroup(state, data) {
    if (state.activeGroup.index.length == 1) {
      state.categoryGroups.children[state.activeGroup.index[0]].status = data;
      state.activeGroup.status = data;
    }
    else {
      let activePath = categoryPath(state.categoryGroups.children, state.activeGroupIndex);
      for (let i = 0; i < activePath.children.length; i++) {
        if (activePath.children[i].id == state.activeGroup.id) {
          activePath.children[i].status = data;
          state.activeGroup.status = data;
        }
      }
    }
  },

  sortCategory(state, data) {
    let group;
    let pathLength = data.newIndex.length;
    let oldIndex = data.oldIndex[pathLength - 1];
    let newIndex = data.newIndex[pathLength - 1];
    if (data.oldIndex.length == 1) {
      group = state.categoryGroups;
    }
    else {
      data.oldIndex.splice(-1, 1);
      group = categoryPath(state.categoryGroups.children, data.oldIndex);
    }

    if (oldIndex - newIndex < 0) {
      for (let i = oldIndex; i < newIndex - 1; i++) {
        [group.children[i], group.children[i + 1]] = [group.children[i + 1], group.children[i]];
        group.children[i].index[pathLength - 1] = i;
        group.children[i].sort = i + 1;
      }
      group.children[newIndex - 1].index[pathLength - 1] = newIndex - 1;
      group.children[newIndex - 1].sort = newIndex;
    }
    else {
      for (let i = oldIndex; i > newIndex; i--) {
        [group.children[i], group.children[i - 1]] = [group.children[i - 1], group.children[i]];
        group.children[i].index[pathLength - 1] = i;
        group.children[i].sort = i + 1;
      }
      group.children[newIndex].index[pathLength - 1] = newIndex;
      group.children[newIndex].sort = newIndex + 1;
    }
    normalizeIndexes(state.categoryGroups.children, []);
  },

  changeCategoryParent(state, data) {
    let dropItem = categoryPath(state.categoryGroups.children, data.dropIndex);
    if (dropItem) {
      let dropItemParent;
      let parentItem;
      if (data.dropIndex.length == 1) {
        dropItemParent = state.categoryGroups;
      }
      else {
        let dropItemParentIndex = data.dropIndex.slice();
        dropItemParentIndex.splice(-1, 1);
        dropItemParent = categoryPath(state.categoryGroups.children, dropItemParentIndex);
      }

      if (data.space) {
        if (data.parentIndex.length == 1) {
          parentItem = state.categoryGroups;
        }
        else {
          let parentIndex = data.parentIndex.slice();
          parentIndex.splice(-1, 1);
          parentItem = categoryPath(state.categoryGroups.children, parentIndex);
        }
      }
      else {
        parentItem = categoryPath(state.categoryGroups.children, data.parentIndex);
      }



      dropItemParent.children.splice(data.dropIndex[data.dropIndex.length - 1], 1);

      for (let i = data.dropIndex[data.dropIndex.length - 1]; i < dropItemParent.children.length; i++) {
        dropItemParent.children[i].sort = dropItemParent.children[i].sort - 1;
        dropItemParent.children[i].index[dropItemParent.children[i].index.length - 1] = dropItemParent.children[i].index[dropItemParent.children[i].index.length - 1] - 1;
      }



      if (!parentItem.children) {
        parentItem.children = [];
        dropItem.sort = 1;
      }
      else {
        dropItem.sort = parentItem.children.length + 1;
      }

      if (dropItem.parent_id == parentItem.id) {

        if (parentItem.parent_id == null) {
          dropItem.parent_id = null;
          dropItem.open = false;
          state.categoryGroups.children.push(dropItem);
        }
        else {
          let newIndex = parentItem.index.slice();
          newIndex.pop();
          let parentOfParent = categoryPath(state.categoryGroups.children, newIndex);
          dropItem.parent_id = parentOfParent.id;
          dropItem.open = false;
          parentOfParent.children.push(dropItem);
          parentOfParent.open = false;
        }

      }
      else {
        if (typeof parentItem.id == 'undefined') {
          parentItem.id = null;
        }
        dropItem.parent_id = parentItem.id;
        dropItem.open = false;
        parentItem.children.push(dropItem);
      }

      normalizeIndexes(state.categoryGroups.children, []);

    }
  },
};


export const actions = {
  GET_TREE({ state, commit, dispatch }, data) {
    return axios.get('/categories/tree?gameId=' + data.id + '&showEmpty=1')
      .then(async response => {
        if (response.data.success) {
          let newData = makeIndex(response.data.data, undefined);
          let copyData = JSON.parse(JSON.stringify(response.data.data));
          let categorySelectData = categorySelect(copyData);
          commit('setSelect', JSON.parse(JSON.stringify(categorySelectData)));
          if (state.games && state.games.length > 0) {
            commit('setGame', state.games.filter(item => item.id == data.id)[0]);
            commit('setCategoryGroups', newData);
            return response.data;
          }
          else {
            await dispatch("games/GET_GAMES", {
              query: '&expand=categories,children&with_groups&sort=sort',
            }, { root: true });
            commit('setGame', state.games.filter(item => item.id == data.id)[0]);
            commit('setCategoryGroups', newData);
            return response.data;
          }
        }
        else {
          return response.data;
        }
      })
      .catch(exception => {

        return exception.response
      }
      )
  },
  SET_TREE({ state, commit }, data) {
    commit('setCategoryGroups', data);
  },
  async GET_GAMES({ state, commit }, data) {

    let gamesRes = await axios.get(
      "/games?per-page=50" + data.query
    );
    if (gamesRes && gamesRes.data && gamesRes.data.data) {
      while (gamesRes.data.data._links && gamesRes.data.data._links.next) {
        var next = gamesRes.data.data._links.next.href;
        if (next) {
          next = next.replace(process.env.backendUrl, "");
          next = next.replace("http:", "");
          next = next.replace("https:", "");
          next = next.replace("/v1/", "");
          var nextPage = await axios.get(next);
          gamesRes.data.data.items = gamesRes.data.data.items.concat(
            nextPage.data.data.items
          );
          gamesRes.data.data._links.next = nextPage.data.data._links.next;
        }
      }
    }
    let games = gamesRes.data.data.items;
    for (let i = 0; i < games.length; i++) {
      games[i].countText = numToString(games[i].offer_count, [
        "предложение",
        "предложения",
        "предложений"
      ]);
    }
    commit('setGames', games)
  },
  GET_GAME({ state, commit }, data) {
    commit('setGameId', data);
    return axios.get('/games/' + state.gameId + (data.query ? '?' + data.query : ''))
      .then(response => {
        if (response.data.success) {
          commit('setGame', response.data.data)
        }
        return response.data;
      })
      .catch(exception => {
        return exception.response
      })
  },
  TRIGGER_GROUP({ state, commit }, data) {
    return axios.get('/categories/' + data.id + '?expand=attrs,seo')
      .then(response => {
        if (response.data.success) {
          commit('triggerGroup', { group: response.data.data, index: data.index });
        }
      })
      .catch(exception => {
        return exception.response
      }
      )
  },
  CLOSE_GROUP({ state, commit }, data) {
    commit('closeGroup', data);
  },
  OPEN_CATEGORY({ state, commit }, data) {
    return axios.get('/categories/' + data.id + '?expand=attrs,seo')
      .then(response => {
        if (response.data.success) {
          let newData = JSON.parse(JSON.stringify(data));
          newData.attrs = response.data.data.attrs;
          if (response.data.data.seo == null) {
            newData.seo = {};
            newData.seo.h1 = '';
            newData.seo.title = '';
            newData.seo.description = '';
            newData.seo.keywords = '';
            newData.seo.text = '';
          }
          else {
            newData.seo = response.data.data.seo;
          }
          commit('openCategory', newData);
        }
        return response.data;
      })
      .catch(exception => {
        return exception.response
      }
      );
  },
  ADD_CATEGORY({ state, commit, dispatch }, data) {
    data.game_id = state.gameId;
    if (!data.is_allow_bulk) {
      data.is_allow_bulk = 0;
    }

    return axios.post('/categories', data)
      .then(response => {
        if (response.data.success) {
          commit('addCategory', { category: response.data.data, index: data.index });
        }
        else {
          let errors = response.data.data;
          let message = '';
          for (let i = 0; i < errors.length; i++) {
            message += errors[i].message;
          }
        }
        return response.data;
      })
      .catch((exception) => {
        return exception.response;
      });
  },
  STATUS_CATEGORY({ state, commit, dispatch }, status) {
    let categoryId;
    if (state.activeCategory.id) {
      categoryId = state.activeCategory.id;
    }
    else {
      categoryId = state.activeGroup.id;
    }
    return axios.put('/categories/' + categoryId, { status: status })
      .then(response => {
        if (response.data.success) {
          if (state.activeCategory.id) {
            commit('changeStatusCategory', status);
          }
          else {
            commit('changeStatusGroup', status);
          }
        }
        else {
          commit('setErrors', response.data)
        }
        return response.data;
      })
      .catch(exception => {
        return exception.response
      }
      );
  },
  CHANGE_GROUP({ state, commit, dispatch }, data) {
    return axios.put('/categories/' + state.activeGroup.id, data)
      .then(response => {
        if (response.data.success) {
          commit('changeGroupName', { name: data.name, index: state.activeGroup.index, seo: data.seoData });
        }
        return response.data;
      })
      .catch(exception => {
        return exception.response
      }
      );
  },
  DELETE_GROUP({ state, commit, dispatch }) {
    return axios.delete('/categories/' + state.activeGroup.id)
      .then(response => {
        if (response.status == 204) {
          commit('deleteGroup');
        }
        else {
          commit('setErrors', response.data.data.message);
          return response;
        }
      }).catch(exception => {
        return exception.response
      }
      );
  },
  DELETE_CATEGORY({ state, commit }) {
    return axios.delete('/categories/' + state.activeCategory.id)
      .then(response => {
        if (response.status == 204) {
          commit('deleteCategory');
        }
        else {
          commit('setErrors', response.data.data.message);
        }
        return response;
      }).catch(exception => {
        return exception.response
      });
  },

  SAVE_CATEGORY({ state, commit, dispatch }, data) {
    let newData = JSON.parse(JSON.stringify(data));
    newData.attr = data.attrs;
    delete newData.attrs;
    delete newData.index;
    delete newData.open;
    delete newData.seo;
    for (let i = 0; i < newData.attr.length; i++) {
      if (newData.attr[i].activeAvailable) {
        delete newData.attr[i].activeAvailable;
      }
    }
    return axios.put('/categories/' + state.activeCategory.id, newData)
      .then(response => {
        if (response.data.success) {
          response.data.data.index = data.index;
          response.data.data.open = data.open;
          response.data.data.seo = newData.seoData;
          commit('changeCategory', response.data.data);
        }
        return response.data;
      })
      .catch(exception => {
        return exception.response
      }
      );
  },
  CHANGE_CATEGORY_PARENT({ state, commit, dispatch }, data) {
    if (typeof data.parentId == "undefined") {
      data.parentId = null;
    }

    let dropItem = categoryPath(state.categoryGroups.children, data.dropIndex);
    if (dropItem) {
      let dropItemParent;
      let parentItem;
      if (data.dropIndex.length == 1) {
        dropItemParent = state.categoryGroups;
      }
      else {
        let dropItemParentIndex = data.dropIndex.slice();
        dropItemParentIndex.splice(-1, 1);
        dropItemParent = categoryPath(state.categoryGroups.children, dropItemParentIndex);
      }

      if (data.space) {
        if (data.parentIndex.length == 1) {
          parentItem = state.categoryGroups;
        }
        else {
          let parentIndex = data.parentIndex.slice();
          parentIndex.splice(-1, 1);
          parentItem = categoryPath(state.categoryGroups.children, parentIndex);
        }
      }
      else {
        parentItem = categoryPath(state.categoryGroups.children, data.parentIndex);
      }

      if (dropItem.parent_id == parentItem.id) {

        if (parentItem.parent_id == null) {
          return axios.put('/categories/' + dropItem.id, { parent_id: null })
            .then(response => {
              if (response.data.success) {
                commit('changeCategoryParent', data);
              }
              return response.data;
            })
            .catch(exception => {
              return exception.response
            }
            );
        }
        else {
          let newIndex = parentItem.index.slice();
          newIndex.pop();
          let parentOfParent = categoryPath(state.categoryGroups.children, newIndex);
          return axios.put('/categories/' + dropItem.id, { parent_id: parentOfParent.id })
            .then(response => {
              if (response.data.success) {
                commit('changeCategoryParent', data);
              }
              return response.data;
            })
            .catch(exception => {
              return exception.response
            }
            );
        }
      }
      else {

        return axios.put('/categories/' + data.dropId, { parent_id: data.parentId })
          .then(response => {
            if (response.data.success) {
              commit('changeCategoryParent', data);
            }
            return response.data;
          })
          .catch(exception => {
            return exception.response
          }
          );
      }
    }
  },
  SORT_CATEGORY({ state, commit, dispatch }, data) {
    commit('sortCategory', data);
  },
  SORT_GAME({ state, commit, dispatch }, data) {
    if ((parseInt(data.targetSort) == parseInt(data.dropSort) + 1) || parseInt(data.targetSort) == parseInt(data.dropSort)) {
      if (data.dropParentId == data.targetParentId) {
        return Promise.resolve({ success: true });
      }
    }
    else {
      let step = 0;
      if (parseInt(data.targetSort) < parseInt(data.dropSort)) {
        step = (parseInt(data.targetSort) - parseInt(data.dropSort)) * -1;
      }
      else {
        step = (parseInt(data.targetSort) - 1 - parseInt(data.dropSort)) * -1;
      }

      return axios
        .post("/games/" + data.dropId + "/sort/" + step)
        .then(async (response) => {
          if (response.data.success) {
            await dispatch("games/GET_GAMES", {
              query: data.query
            }, { root: true });
            return Promise.resolve({ success: true });
          }

          return Promise.reject({ success: false, data: response.data });

        })
        .catch((exception) => {
          return Promise.reject({ success: false, data: exception.response });
        });
    }
  },
  CLEAR_STATE({ state, commit, dispatch }) {
    commit('clearState');
  },
  CHANGE_PARENT_AND_SORT({ state, commit, dispatch }, data) {
    if (!data.targetId) {
      if (data.targetParentId != data.dropParentId) {
        //changing parent

        let target = state.games.filter(game => game.id == data.targetId)[0];
        if (target && target.children && target.children.length > 0) {
          return Promise.reject({ success: false, response: { data: { message: "Нельзя перенести игру. У игр только один уровень вложенности." } } });
        }
        else {
          let drop = state.games.filter(game => game.id == data.dropId)[0];
          if (drop && drop.children && drop.children.length > 0) {
            return Promise.reject({ success: false, response: { data: { message: "Нельзя перенести игру. У игр только один уровень вложенности." } } });
          }
          else {
            let parent = state.games.filter(game => game.id == (data.dropParentId ? data.dropParentId : null))[0];
            if (parent) {
              drop = parent.children.filter(game => game.id == data.dropId)[0];
              if (drop && drop.children && drop.children.length > 0) {
                return Promise.reject({ success: false, response: { data: { message: "Нельзя перенести игру. У игр только один уровень вложенности." } } });
              }
            }
          }

          let parent = state.games.filter(game => game.id == (data.targetParentId ? data.targetParentId : null))[0];
          if (parent) {
            target = parent.children.filter(game => game.id == data.dropId)[0];
            if (target && target.children && target.children.length > 0) {
              return Promise.reject({ success: false, response: { data: { message: "Нельзя перенести игру. У игр только один уровень вложенности." } } });
            }
          }
        }


        return axios.put('/games/' + data.dropId, { parent_id: (data.targetParentId ? data.targetParentId : null) })
          .then(async response => {
            if (response.data.success) {
              await dispatch("games/GET_GAMES", {
                query: data.query
              }, { root: true });

              let newDrop = state.games.filter(game => game.id == data.dropId)[0];
              if (!newDrop) {
                let parent = state.games.filter(game => game.id == (data.targetParentId ? data.targetParentId : null))[0];
                if (!parent) {
                  return Promise.reject({ success: false, data: { message: "Не удалось перенести игру. Обновите страницу." } });
                }

                newDrop = parent.children.filter(game => game.id == data.dropId)[0];
                if (!newDrop) {
                  return Promise.reject({ success: false, data: { message: "Не удалось перенести игру. Обновите страницу." } });
                }
              }

              data.dropSort = newDrop.sort;
              return dispatch("games/SORT_GAME", data, { root: true });
            }
            return Promise.reject({ success: false, data: { message: "Не удалось перенести игру. Обновите страницу." } });

          })
          .catch(exception => {
            return Promise.reject({ success: false, data: exception });
          }
          );
      }
      else {
        return dispatch("games/SORT_GAME", data, { root: true });
      }
    }
    else {
      if (data.dropId == data.targetId) {
        return Promise.resolve({ success: true });
      }

      if (data.targetParentId) {
        return Promise.reject({ success: false, response: { data: { message: "Нельзя перенести игру. У игр только один уровень вложенности." } } });
      }

      let target = state.games.filter(game => game.id == data.targetId)[0];
      if (target && target.children && target.children.length > 0) {
        let drop = state.games.filter(game => game.id == data.dropId)[0];
        if (drop && drop.children && drop.children.length > 0) {
          return Promise.reject({ success: false, response: { data: { message: "Нельзя перенести игру. У игр только один уровень вложенности." } } });
        }
        else {
          let parent = state.games.filter(game => game.id == (data.dropParentId ? data.dropParentId : null))[0];
          if (parent) {
            drop = parent.children.filter(game => game.id == data.dropId)[0];
            if (drop && drop.children && drop.children.length > 0) {
              return Promise.reject({ success: false, response: { data: { message: "Нельзя перенести игру. У игр только один уровень вложенности." } } });
            }
          }
        }
      }
      else {
        let drop = state.games.filter(game => game.id == data.dropId)[0];
        if (drop && drop.children && drop.children.length > 0) {
          return Promise.reject({ success: false, response: { data: { message: "Нельзя перенести игру. У игр только один уровень вложенности." } } });
        }
        else {
          let parent = state.games.filter(game => game.id == (data.dropParentId ? data.dropParentId : null))[0];
          if (parent) {
            drop = parent.children.filter(game => game.id == data.dropId)[0];
            if (drop && drop.children && drop.children.length > 0) {
              return Promise.reject({ success: false, response: { data: { message: "Нельзя перенести игру. У игр только один уровень вложенности." } } });
            }
          }
        }
        let parent = state.games.filter(game => game.id == (data.targetParentId ? data.targetParentId : null))[0];
        if (parent) {
          target = parent.children.filter(game => game.id == data.dropId)[0];
          if (target && target.children && target.children.length > 0) {
            return Promise.reject({ success: false, response: { data: { message: "Нельзя перенести игру. У игр только один уровень вложенности." } } });
          }
        }
      }

      return axios.put('/games/' + data.dropId, { parent_id: data.targetId ? data.targetId : null })
        .then(async response => {
          if (response.data.success) {
            await dispatch("games/GET_GAMES", {
              query: data.query
            }, { root: true });
            return Promise.resolve({ success: true });
          }
          return Promise.reject({ success: false, data: response.data });
        })
        .catch(exception => {
          return Promise.reject({ success: false, response: exception.response });
        }
        );
    }

  },
  CHANGE_GAME_STATUS({ state, commit, dispatch }, data) {
    return axios
      .put("/games/" + data.id, { status: data.status })
      .then((response) => {
        if (response.data.success) {
          commit('setGameStatus', data);
        }
      })
  }
}
