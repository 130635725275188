<template>
  <b-modal
    id="modalRegistration"
    ref="modalRegistration"
    v-model="show"
    class="modalAuth"
    centered
    @hide="resetForm">
    <div
      slot="modal-header"
      :header-border-variant="0"
      class="modalHeader">
      <svgicon
        name="close"
        width="14"
        height="14"
        class="closeModal"
        @click="show=false"/>
      <img
        src="/img/logoBlack.svg"
        alt="Gobuffy">
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        Присоединяйтесь к крупнейшему игровому сообществу!
      </div>

      <h3>Регистрация</h3>
      <baseAuthForm
        :on-submit="onSubmit"
        :inputs="inputs"
        :url="url"
        :submit-text="submitText"
        :active="show"/>
    </div>
    <div
      slot="modal-footer"
      class="modalFooter">
      <p>
        <a
          v-b-modal.modalLogin
          class="modalFooterLink"
          @click="show = false">
          Уже есть аккаунт? Войдите!
        </a>
      </p>
      <p>
        <a
          v-b-modal.modalLoginSocial
          class="modalFooterLink"
          @click="show = false">
          Войти черрез соц. сети
        </a>
      </p>
    </div>
  </b-modal>
</template>

<script>
  import '../../../icons/close';
  import baseAuthForm from './_base-auth-form';
  export default {
    components: {
      baseAuthForm
    },
    props: {
      onRegister: {
        type: Function,
        default: () => {},
      }
    },
    data() {
      return {
        inputs: [
          {
            name: 'username',
            value: '',
            type: 'text',
            placeholder: 'Ваш логин',
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          },
          {
            name: 'password',
            value: '',
            type: 'password',
            placeholder: 'Пароль',
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          },
          {
            name: 'repeatPassword',
            value: '',
            type: 'password',
            placeholder: 'Повторите пароль',
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          },
          {
            name: 'email',
            value: '',
            type: 'email',
            placeholder: 'Email',
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          },
        ],
        submitText: 'Зарегистрироваться',
        url: 'auth/REGISTRATION',
        show: false,
        formSubmit: false
      }
    },
    methods: {
      resetForm: function(){
        for(let i = 0; i < this.inputs.length; i++){
          this.inputs[i].value = '';
          this.inputs[i].errorFlag = false;
        }
      },
      onSubmit (data) {
        this.formSubmit = data;
        this.onRegister({data: data, email: this.inputs[3].value});
        this.show = false
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>