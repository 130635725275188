<template>
  <b-modal
    id="modalLogin"
    ref="modalLogin"
    v-model="show"
    class="modalAuth"
    centered
    @hide="resetForm">
    <div
      slot="modal-header"
      :header-border-variant="0"
      class="modalHeader">
      <svgicon
        name="close"
        width="14"
        height="14"
        class="closeModal"
        @click="show=false"/>
      <img
        src="/img/logoBlack.svg"
        alt="Gobuffy">
    </div>
    <div class="modalContent">
      <h3>
        {{ $t('auth.login') }}
      </h3>
      <baseAuthForm
        :on-submit="onSubmit"
        :inputs="inputs"
        :url="url"
        :submit-text="submitText"
        :active="show"/>
    </div>
    <div
      slot="modal-footer"
      class="modalFooter">
      <p>
        <a
          v-b-modal.modalRestore
          class="modalFooterLink"
          @click="show = false">Я забыл пароль</a>
      </p>
      <p>
        <a
          v-b-modal.modalLoginSocial
          class="modalFooterLink"
          @click="show = false">
          Вернуться назад
        </a>
      </p>
    </div>
  </b-modal>
</template>

<script>
  import '../../../icons/close';
  import baseAuthForm from './_base-auth-form';
  export default {
    components: {
      baseAuthForm
    },
    props: {
      loginShow: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        inputs: [
          {
            name: 'username',
            value: '',
            placeholder: 'Имя пользователя',
            type: 'text',
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          },
          {
            name: 'password',
            value: '',
            placeholder: 'Пароль',
            type: 'password',
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          }
        ],
        submitText: 'Войти',
        url: 'auth/LOGIN',
        show: false,
        formSubmit: false
      }
    },
    watch: {
      loginShow: function () {
        this.show = this.loginShow;
      },
    },
    methods: {
      resetForm: function(){
        for(let i = 0; i < this.inputs.length; i++){
          this.inputs[i].value = '';
          this.inputs[i].errorFlag = false;
        }
      },
      onSubmit (data) {
        this.formSubmit = data;
        this.show = false
      }
    }
  }
</script>
<style lang="scss" scoped>
</style>
