<template>
  <div>
    <b-modal
      id="modalLoginSocial"
      ref="modalLoginSocial"
      v-model="show"
      centered
      class="modalAuth">
      <div
        slot="modal-header"
        :header-border-variant="0"
        class="modalHeader">
        <svgicon
          name="close"
          width="14"
          height="14"
          class="closeModal"
          @click="show = false"/>
        <img
          src="/img/logoBlack.svg"
          alt="Gobuffy">
      </div>
      <div class="modalContent">
        <h3>
          {{ $t("auth.login") }}
        </h3>

        <button
          :disabled="loading"
          class="button vk"
          @click="login(vkLink)">
          <span class="buttonIconVk">
            <svgicon
              name="vk"
              width="22"
              height="13"
              class="vkIcon"/>
          </span>
          {{ $t("auth.login-vk") }}
        </button>
        <button
          v-if="false"
          :disabled="loading"
          class="button facebook"
          @click="login(facebookLink)">
          <span class="buttonIconFacebook">
            <svgicon
              name="facebook"
              width="12"
              height="24"
              class="facebookIcon"/>
          </span>
          {{ $t("auth.login-facebook") }}
        </button>
      </div>
      <div
        slot="modal-footer"
        class="modalFooter">
        <p>
          <a
            v-b-modal.modalLogin
            class="modalFooterLink"
            @click="show = false">
            {{ $t("auth.login-email") }}
          </a>
        </p>
        <p>
          <a
            v-b-modal.modalRegistration
            class="modalFooterLink"
            @click="show = false">
            {{ $t("auth.no-account") }}
          </a>
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "../../../icons/close";
import "../../../icons/vk";
import "../../../icons/facebook";
import { mapGetters } from "vuex";
import { socket } from "~/plugins/socket-io.js";
import axios from "axios";

const uuidv4 = require("uuid/v4");
const VK_CLIENT_ID = process.env.VK_CLIENT_ID;
const FACEBOOK_CLIENT_ID = process.env.FACEBOOK_CLIENT_ID;
const REDIRECT_URI_VK =
  process.env.apiURL + "/v1/auth/social-callback?social=vk&no_rest";
const REDIRECT_URI_FACEBOOK =
  process.env.apiURL + "/v1/auth/social-callback?social=fb&no_rest";
const GUEST_TOKEN = uuidv4();

export default {
  components: {},
  data() {
    return {
      show: false,
      vkLink:
        "https://oauth.vk.com/authorize?client_id=" +
        VK_CLIENT_ID +
        "&scope=email&display=page&redirect_uri=" +
        REDIRECT_URI_VK +
        "&state=" +
        GUEST_TOKEN +
        "&response_type=code&v=5.80",
      facebookLink:
        "https://www.facebook.com/v3.0/dialog/oauth?client_id=" +
        FACEBOOK_CLIENT_ID +
        "&scope=email&redirect_uri=" +
        REDIRECT_URI_FACEBOOK +
        "&state=" +
        GUEST_TOKEN,
      discordJoinLink: "https://discord.gg/TdtgfjE",
      loading: false,
      socialWindow: null,
      discordWindow: null,
    };
  },
  mounted: function () {
    this.$root.$on("loginDiscord", (event) => {
      event.preventDefault();

      let accessToken = this.$cookiz.get('access-token') || null;
      if (accessToken && !this.$store.state.auth.user.discord_user_id) {
        this.openDiscordOauth(accessToken);
      } else if (accessToken && this.$store.state.auth.user.discord_user_id) {
        return axios.post(
          "/users/" + this.$store.state.auth.user.jti + "/join-to-discord"
        ).then(response => {
          this.$noty.success("Вы были успешно добавлены на наш сервер");
        }).catch(exception => {
          this.openDiscordOauth(accessToken);
        });
      } else {
        window.open(
          this.discordJoinLink,
          "",
          "width=900, height=600, top=" +
          (screen.height - 600) / 2 +
          ",left=" +
          (screen.width - 900) / 2 +
          ", resizable=yes, scrollbars=no, status=yes"
        );
      }
    });
    socket.on("social login", (data) => {
      console.log(data);
      //Привязка дискорда
      if (data.discord && this.discordWindow) {
        if (data.success == true) {
          this.$store.commit("auth/setDiscordUserId", data.discord_user_id);
          axios.post(
            "/users/" + this.$store.state.auth.user.jti + "/join-to-discord"
          ).then(() => {
            this.$noty.success("Вы были успешно добавлены на наш сервер");
          });
        } else {
          this.$noty.error("Discord аккаунт уже привязан");
        }
        setTimeout(() => {
          this.discordWindow.close();
        }, 100);
      } else {
        //Вход через соцсети
        if (!data.success && data.error_code === 1) {
          this.$root.$emit("bv::show::modal", "modalNoEmail");
        }
        this.$store.dispatch("auth/ON_LOGIN", data);
        this.show = false;
        setTimeout(() => {
          this.socialWindow.close();
        }, 100);
      }
    });
  },
  methods: {
    login: function (url) {
      this.loading = true;
      this.socialWindow = window.open(
        url,
        "",
        "width=900, height=600, top=" +
          (screen.height - 600) / 2 +
          ",left=" +
          (screen.width - 900) / 2 +
          ", resizable=yes, scrollbars=no, status=yes"
      );
      socket.emit("subscribe social", { guestToken: GUEST_TOKEN });

      let timer = setInterval(() => {
        if (this.socialWindow.closed) {
          clearInterval(timer);
          this.loading = false;
        }
      }, 100);
    },
    openDiscordOauth: function (accessToken) {
      this.loading = true;
      this.discordWindow = window.open(
        "https://discord.com/oauth2/authorize?client_id=720481962606723073&redirect_uri=https%3A%2F%2Fbackend.gobuffy.com%2Fv1%2Fauth%2Fsocial-callback%3Fsocial%3Ddiscord%26no_rest&response_type=code&scope=guilds%20guilds.join%20identify%20gdm.join&state=" + accessToken,
        "",
        "width=900, height=600, top=" +
        (screen.height - 600) / 2 +
        ",left=" +
        (screen.width - 900) / 2 +
        ", resizable=yes, scrollbars=no, status=yes"
      );

      let timer = setInterval(() => {
        if (this.discordWindow.closed) {
          clearInterval(timer);
          this.loading = false;
        }
      }, 100);
    },
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.vk {
  position: relative;
  width: 100%;
  height: 40px;
  padding-left: 55px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  background: #4c6c91;
  border-radius: 4px;
  border: none;
  overflow: hidden;
  font-size: 14px;
  color: #ffffff;
  text-align: left;
  cursor: pointer;
}

.buttonIconVk {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  display: block;
  line-height: 38px;
  background: #3f5f84;
  text-align: center;
}

.vkIcon {
  fill: #ffffff;
}

.facebook {
  position: relative;
  margin-top: 8px;
  width: 100%;
  height: 40px;
  padding-left: 55px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  background: #3b5998;
  border-radius: 4px;
  border: none;
  overflow: hidden;
  font-size: 14px;
  color: #ffffff;
  text-align: left;
  cursor: pointer;
}

.buttonIconFacebook {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  display: block;
  line-height: 38px;
  background: #314f8e;
  text-align: center;
}

.facebookIcon {
  fill: #ffffff;
}

.button:disabled {
  opacity: 0.5;
  cursor: auto;
}
</style>
