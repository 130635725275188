<template>
  <div>
    <!--
    <div style="position: absolute; top: 0; left: 50%; width: 1440px; height: 3014px; margin-left: -720px; opacity: .5; background: url('/scrin/category.jpg')"/>
    -->
    <div
      v-if="user && user.role == 1"
      :class="['wrapper wrapper-admin', sideBar ? 'sideBarActive' : '']">
      <headerBase/>
      <sideBar/>
      <sideBarAdmin/>
      <profileNav
        v-if="isUserPage"
        :links="userLinks"/>
      <ads-nav v-if="isAdsPage"/>
      <div class="page">
        <div class="main-container">
          <breadcrumbs/>
          <div class="page-content">
            <userProfileHead v-if="isUserPage"/>
            <nuxt/>
          </div>
        </div>
      </div>
      <footerBase/>
    </div>
    <audio
      id="newMessageSound"
      volume="1"
      src="/sounds/message.wav?v=GB-141"/>
    <confirmation
      :text="'Вы переходите на сторонний сайт, который никак не связан с GOBUFFY. Сделки проведенные вне нашего сайта никак не защищены.'"
      unique="OutLink"
      @callback="goToLink()"/>
  </div>
</template>

<script>

import confirmation from "~/components/modals/confirmation.vue";
import headerBase from "~/components/header/headerBase.vue";
import sideBar from "~/components/sidebar/sideBar.vue";
import sideBarAdmin from "~/components/sidebar/sideBarAdmin.vue";
import footerBase from "~/components/footer/footerBase.vue";
import breadcrumbs from "~/components/breadcrumbs/breadcrumbs";
import profileNav from "~/components/profile/profileNav.vue";
import adsNav from "~/components/administration/adsNav.vue";
import userProfileHead from "~/components/administration/userProfileHead.vue";
import axios from "axios";
import { mapState } from "vuex";
import Vue from "vue";
import globalMixin from "~/mixins/global";
Vue.mixin(globalMixin);
export default {
  middleware: ["isAdmin", "breadcrumbs"],
  components: {
    headerBase,
    sideBar,
    footerBase,
    sideBarAdmin,
    breadcrumbs,
    profileNav,
    adsNav,
    userProfileHead,
    confirmation
  },
  data() {
    return {
      userId: null,
      userLinks: [
        {
          id: 0,
          name: "Предложения",
          link: "/offers",
        },
        {
          id: 1,
          name: "Покупки",
          link: "/purchases",
        },
        {
          id: 2,
          name: "Продажи",
          link: "/sales",
        },
        {
          id: 3,
          name: "История операций",
          link: "/transactions",
        },
        {
          id: 4,
          name: "Отзывы",
          link: "/reviews",
        },
        {
          id: 5,
          name: "Споры",
          link: "/disputes",
        },
        {
          id: 6,
          name: "Реклама",
          link: "/adslist",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      sideBar: (state) => state.sidebar.sideBar,
    }),
    isUserPage() {
      return (
        this.$route.name.indexOf("administration-") > -1 &&
        this.$route.name.indexOf("-id") > -1 &&
        this.$route.name.indexOf("-users") > -1
      );
    },
    isAdsPage() {
      return (
        this.$route.name.indexOf("ads") !== -1 &&
        this.$route.name.indexOf("-id") == -1
      );
    },
  },
  created: async function () {
    if (!this.isUserPage) return;

    if (this.$route.name == "administration-orders-id") {
      let orderResponse = await axios.get(
        "/orders/" + this.$route.params.id + "?expand=user"
      );
      if (
        orderResponse &&
        orderResponse.data &&
        orderResponse.data.data &&
        orderResponse.data.data.user
      ) {
        this.userId = orderResponse.data.data.user.id;
      }
    } else {
      this.userId = parseInt(this.$route.params.id);
    }
    for (let i = 0; i < this.userLinks.length; i++) {
      this.userLinks[i].link =
        "administration/users/" + this.userId + this.userLinks[i].link;
    }
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  min-height: calc(100vh - 64px);
  flex-direction: column;
}
.profile-nav,
.ads-nav {
  left: 64px;
  .item {
    &:first-child {
      .link.nuxt-link-active {
        color: #a8aaaf;
        &:after {
          opacity: 0;
        }
      }
    }
  }
}
</style>
