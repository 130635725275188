/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat-back': {
    width: 14,
    height: 10,
    viewBox: '0 0 14 10',
    data: '<path pid="0" d="M13 4a1 1 0 010 2H1a1 1 0 110-2h12zM1.707 5.707A1 1 0 01.293 4.293l4-4a1 1 0 011.414 1.414l-4 4zm-1.414 0a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" _fill="#6E7279" fill-rule="nonzero"/>'
  }
})
