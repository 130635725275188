<template>
  <b-modal
    id="modalProfileActivation"
    v-model="show"
    centered
    class="modalAuth">
    <div
      slot="modal-header"
      :header-border-variant="0"
      class="modalHeader">
      <svgicon
        name="close"
        width="14"
        height="14"
        class="closeModal"
        @click="show = false"/>
      <img
        src="/img/logoBlack.svg"
        alt="Gobuffy">
    </div>
    <div class="modalContent">
      <div class="modalDescription">
        Этот профиль еще не привязан к аккаунту GoBuffy
      </div>
       
      <div class="modalUser">
        <img
          class="modalUserImg"
          src="/pictures/user-bar.png"
          alt="">
        <span class="modalUserName">
          Иван Константин
        </span>
      </div>

      <div class="activationContent">
        <b-button
          type="submit"
          variant="success"
          class="haveAccount">
          У меня есть аккаунт
        </b-button>

        <p>
          Просто введите логин и пароль этого аккаунта и Вы сможете всегда входить через эту соц. сеть
        </p>

        <br>

        <b-button
          type="submit"
          variant="outline-secondary"
          class="haventAccount">
          У меня нет аккаунта
        </b-button>

        <p>
          Вы можете зарегистрироваться используя соц. сеть
        </p>
      </div>
    </div>
    <div
      slot="modal-footer"
      class="modalFooter">
      <p>
        <a
          v-b-modal.modalLoginSocial
          class="modalFooterLink"
          @click="show = false">
          Вернуться назад
        </a>
      </p>
    </div>
  </b-modal>
</template>

<script>
  import '../../../icons/close';
  import '../../../icons/vk';
  import '../../../icons/facebook';
  export default {
    data() {
      return {
        show: false,
      }
    },
    methods: {
    }
  }
</script>
<style lang="scss" scoped>
  .modalUser{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modalUserName{
    font-size: 18px;
    font-weight: 500;
  }
  .modalUserImg{
    + .modalUserName{
      margin-left: 13px;
    }
  }
  .activationContent{
    margin-top: 32px;
    p{
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .haveAccount{
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .haventAccount{
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
  }
</style>