<template>
  <div class="items">
    <a
      href="https://vk.com/gobuffy"
      target="_blank"
      class="item">
      <svgicon
        name="vk"
        width="25"
        height="14"
        class="social-icon"/>
    </a>
    <a
      href="#"
      target="_blank"
      class="item"
      @click="discord($event)">
      <svgicon
        name="discord"
        width="25"
        height="28"
        class="social-icon"/>
    </a>
  </div>
</template>

<script>
  import '../../icons/discord';
  import '../../icons/facebook';
  import '../../icons/vk';
  import '../../icons/instagram';
  import '../../icons/twitter';
  import '../../icons/twitch';
  import '../../icons/steam';

  export default {
    methods: {
      discord: function (event) {
        this.$root.$emit("loginDiscord", event);
      }
    }
  }
</script>
<style lang="scss" scoped>
  .items{
    margin-top: 39px;
    text-align: right;
  }
  .item{
    display: inline-block;
    vertical-align: top;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #575C69;
    line-height: 46px;
    text-align: center;
    transition: $link-transition;
    + .item{
      margin-left: 12px;
    }
    &:hover{
      background: #ffffff;
    }
  }
  .social-icon{
    fill: $header-color;
  }
  .item-steam{
    text-align: left;
  }
</style>
