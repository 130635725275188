export function categoryPath(children, index) {

  let result = children[index[0]];
  for (let i = 1; i < index.length; i++) {
    result = result.children[index[i]];
  }

  return result;
}

let select = [];
export function categorySelect(data) {
  for (let i = 0; i < data.length; i++) {
    select.push(data[i]);
    if (data[i].children) {
      categorySelect(data[i].children);
    }
  }
  return select;
}

export function normalizeIndexes(data, parentIndex) {
  let count = 0;
  data.forEach(function (element) {
    element.index = [];
    parentIndex.forEach(function (parentElement) {
      element.index.push(parentElement);
    });
    element.index.push(count);
    if (element.children) {
      normalizeIndexes(element.children, element.index);
    }
    count++;
  });
}

export function isInChildren(dropItem, newParentId) {
  let result = false;
  if (typeof newParentId != 'undefined') {
    if (typeof dropItem.children != 'undefined') {
      dropItem.children.forEach(function (element) {
        if (typeof element.id != 'undefined') {
          if (element.id == newParentId) {
            result = true;
          }
          if (typeof element.children != 'undefined' && !result) {
            result = isInChildren(element, newParentId);
          }
        }
      });
    }
  }
  return result;
}

export function getIndexById(children, id) {
  let result = null;
  if (typeof id != 'undefined') {
    if (typeof children != 'undefined') {
      children.forEach(function (element) {
        if (typeof element.id != 'undefined') {
          if (element.id == id) {
            result = element.index;
          }
          if (typeof element.children != 'undefined' && !result) {
            result = getIndexById(element.children, id);
          }
        }
      });
    }
  }
  return result;
}

export function makeIndex(data, pathIndex) {
  for (let i = 0; i < data.length; i++) {
    if (!pathIndex) {
      data[i].index = [];
      data[i].index.push(i)
    }
    else {
      data[i].index = pathIndex.slice();
      data[i].index.push(i)
    }
    if (data[i].children) {
      makeIndex(data[i].children, data[i].index);
    }
    data[i].open = false;
  }
  return data;
}

export function findInTree(data, key, value) {
  if (data[key] == value) {
    return data;
  }
  else if (data.children) {
    let result = null;
    for (let i = 0; result == null && i < data.children.length; i++) {
      data.children[i].parent_name = data.name;
      result = findInTree(data.children[i], key, value);
    }
    return result;
  }
  return null;
}