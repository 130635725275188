/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arbitration': {
    width: 23,
    height: 22,
    viewBox: '0 0 23 22',
    data: '<path pid="0" d="M1 20h12v2H1v-2zM5.245 7.07l2.83-2.827 14.14 14.142-2.828 2.828L5.245 7.07zM12.317 0l5.657 5.656-2.83 2.83-5.654-5.66L12.317 0zM3.825 8.485l5.657 5.657-2.828 2.828-5.657-5.657 2.828-2.828z" _fill="#6E7279"/>'
  }
})
