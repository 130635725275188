<template>
  <div>
    <template v-if="user && (user.is_active == null || user.is_active == 0)">
      <div class="confirm-mail-banner">
        <i class="confirm-mail-banner-icon"/>
        Ваш аккаунт не активирован, нужно подтвердить почту
        <span @click="showRegistrationCode"> Подтвердить </span>
      </div>
    </template>
    <div :class="['wrapper', sideBar ? 'sideBarActive' : '']">
      <headerBase
        ref="headerBase"
        :class="showHeader ? 'headerMain active' : 'headerMain'"/>
      <sideBar/>

      <div class="page">
        <nuxt/>
      </div>

      <footerBase/>
    </div>
    <audio
      id="newMessageSound"
      volume="1"
      src="/sounds/message.wav?v=GB-141"/>
    <confirmation
      :text="'Вы переходите на сторонний сайт, который никак не связан с GOBUFFY. Сделки проведенные вне нашего сайта никак не защищены.'"
      unique="OutLink"
      @callback="goToLink()"/>
  </div>
</template>

<script>
import headerBase from "~/components/header/headerBase.vue";
import sideBar from "~/components/sidebar/sideBar.vue";
import footerBase from "~/components/footer/footerBase.vue";

import { mapState } from "vuex";
import Vue from "vue";
import globalMixin from "~/mixins/global";
import confirmation from "~/components/modals/confirmation.vue";
Vue.mixin(globalMixin);
export default {
  middleware: ["breadcrumbs"],
  components: {
    headerBase,
    sideBar,
    footerBase,
    confirmation,
  },
  data() {
    return {
      showHeader: false,
    };
  },
  computed: mapState({
    user: (state) => state.auth.user,
    sideBar: (state) => state.sidebar.sideBar,
  }),
  beforeMount() {
    if (process.browser) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 200) {
          this.showHeader = true;
        } else {
          this.showHeader = false;
        }
      });
    }
  },
  methods: {
    showRegistrationCode: function () {
      this.$root.$emit("bv::show::modal", "modalRegistrationCode");
      //this.$refs.modalRegistrationCode.show();
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  padding-top: 0;
}
.wrapper {
  margin-top: -64px;
  display: flex;
  min-height: calc(100vh - 64px);
  flex-direction: column;
}
</style>
