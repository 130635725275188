import axios from 'axios';

// Base url for API
axios.defaults.baseURL = process.env.apiURL + '/v1/';

//let isAlreadyFetchingAccessToken = false;
let requestsQueue = [];
let isSelfUpdate = false;

async function refreshTokens(app, store) {
  console.log('[AXIOS] Token is not in fetching state. Start fetching access token. From axios.init.js');

  isSelfUpdate = true;
  app.$cookiz.set('isAlreadyFetchingAccessToken', true, {
    maxAge: 5 * 60,
    path: '/',
    domain: '.' + (process.server ? req.headers.host : window.location.hostname),
  });

  let refreshTokenResponse = await axios.post('auth/refresh-token', { token: app.$cookiz.get('refresh-token') });

  if (!refreshTokenResponse.data) {
    console.log('[AXIOS] Error. Refresh token data is empty. Need login. From axios.init.js');
    return Promise.reject(error);
  }

  console.log('[AXIOS] Success token fetch. From axios.init.js');

  const accessToken = refreshTokenResponse.data.data.access_token;
  const newRefreshToken = refreshTokenResponse.data.data.refresh_token;

  app.$cookiz.set('access-token', accessToken, {
    maxAge: 7 * 24 * 60 * 60,
    path: '/',
    domain: '.' + (process.server ? req.headers.host : window.location.hostname),
  });
  app.$cookiz.set('refresh-token', newRefreshToken, {
    maxAge: 7 * 24 * 60 * 60,
    path: '/',
    domain: '.' + (process.server ? req.headers.host : window.location.hostname),
  });
  store.commit('auth/setTokens', {
    accessToken: accessToken,
    refreshToken: newRefreshToken
  });
  app.$cookiz.remove('isAlreadyFetchingAccessToken');
  isSelfUpdate = false;

  onAccessTokenFetched(accessToken);

  if (store.getters['auth/isNeedChatReconnect']) {
    console.log('[CHAT] Is need re-connect is: ' + store.getters['auth/isNeedChatReconnect'] + '. From axios.init.js');
    console.log('[CHAT] Calling CHAT_RECONNECT. From axios.init.js');
    store.dispatch('chat/CHAT_RECONNECT', {}, { root: true });
    store.commit('auth/isNeedChatReconnect', false);
  }
}

export default function ({ app, store, req, res }) {
  axios.interceptors.request.use(function (config) {
    if (config.url.includes('refresh-token')) {
      console.log('!!!!!!');
      return config;
    }
    let token = app.$cookiz.get('access-token');

    config.headers.Authorization = 'Bearer ' + token;
    config.headers['Accept-Language'] = 'ru-RU';
    return config;
  }, function (error) {
    // Do something with request error
    console.log('error from init axios headers');
    return Promise.reject(error);
  });

  axios.interceptors.response.use(
    response => {
      console.log('Response url: ' + response.config.url + ', status: ' + response.status);
      return response;
    },
    async error => {
      let refreshToken = app.$cookiz.get('refresh-token') || null;      

      if (error.response.status === 401 && refreshToken) {
        console.log('[AXIOS] Try to update JWT from interceptor. From axios.init.js');
        try {
          if (!app.$cookiz.get('isAlreadyFetchingAccessToken')) {
            await refreshTokens(app, store);
          } else if (!isSelfUpdate) {
            await refreshTokens(app, store);
            let timerId = setInterval(async () => {
              if (app.$cookiz.get('isAlreadyFetchingAccessToken')) {
                refreshTokens(app, store).then((response) => {
                  clearInterval(timerId);
                  onAccessTokenFetched(app.$cookiz.get('access-token'));
                });
              }
            }, 2000);
          }

          const { response: errorResponse } = error;
          const originalRequest = new Promise(resolve => {
            addSubscriber(access_token => {
              console.log('[AXIOS] Make queued request. From axios.init.js');
              errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
              resolve(axios(errorResponse.config));
            });
          });

          return originalRequest;
        } catch (error) {
          console.log('[AXIOS] Error', error.response);
          if (error.response.status == 422) {
            await refreshTokens(app, store);
            const { response: errorResponse } = error;
            const originalRequest = new Promise(resolve => {
              addSubscriber(access_token => {
                console.log('[AXIOS] Make queued request. From axios.init.js');
                errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
                resolve(axios(errorResponse.config));
              });
            });
          }
          else {
            console.log('[AXIOS] Error. Calling auth/LOGOUT. From axios.init.js');
            store.dispatch('auth/LOGOUT');
          }

          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    }
  );
}

function onAccessTokenFetched(access_token) {
  requestsQueue.forEach(callback => callback(access_token));
  requestsQueue = [];
}

function addSubscriber(callback) {
  console.log('[AXIOS] Request is queued. From axios.init.js');
  requestsQueue.push(callback);
}
