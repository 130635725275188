import axios from 'axios'
import articles from './articles'
import users from './users'
import notifiers from './notifiers'

export default function (context, inject) { 
  const apis = {
    articles: articles(axios),
    users: users(axios),
    notifiers: notifiers(axios),
  };

  inject("api", apis);
}