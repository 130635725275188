<template>
  <b-modal
    id="modalNoEmail"
    v-model="show"
    centered
    class="modalAuth">
    <div
      slot="modal-header"
      :header-border-variant="0"
      class="modalHeader">
      <svgicon
        name="close"
        width="14"
        height="14"
        class="closeModal"
        @click="show=false"/>
      <img
        src="/img/logoBlack.svg"
        alt="Gobuffy">
    </div>
    <div class="modalContent">
      <br>
      <h3>Не можем получить Ваш email</h3>
      <div class="modalDescription">
        Нам не удалось получить Ваш e-mail адрес,
        привязанный к социальной сети. Разрешите
        нам получить его или зарегистрируйтесь,
        используя e-mail
      </div>

      <b-button
        type="submit"
        variant="success"
        class="modalSubmit"
        @click="openModalRegistration">
        Регистрация через email
      </b-button>
      <b-button
        variant="outline-secondary"
        class="login-social"
        @click="openModalLoginSocial">
        Попробовать еще раз
      </b-button>
    </div>
    <div
      slot="modal-footer"
      class="modalFooter">
      <p>
        <a
          v-b-modal.modalLoginSocial
          class="modalFooterLink"
          @click="show = false">
          Вернуться назад
        </a>
      </p>
    </div>
  </b-modal>
</template>

<script>
  import '../../../icons/close';
  import '../../../icons/vk';
  import '../../../icons/facebook';
  export default {
    data() {
      return {
        show: false,
      }
    },
    methods: {
      openModalRegistration: function(){
        this.show = false;
        this.$root.$emit('bv::show::modal', 'modalRegistration')
      },
      openModalLoginSocial: function(){
        this.show = false;
        this.$root.$emit('bv::show::modal', 'modalLoginSocial')
      }
    }
  }
</script>
<style lang="scss" scoped>
  .login-social{
    margin-top: 8px;
    width: 100%;
  }
</style>