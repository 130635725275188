<template>
  <div>
    <div class="list">
      <div
        v-for="(item, index) in chatList"
        :key="index"
        class="item"
        @click="openChat(item.chatId)">
        <div class="avatar">
          <span
            v-if="item.unreadCounter > 0"
            class="counter">
            <template v-if="item.unreadCounter < 100">
              {{ item.unreadCounter }}
            </template>
            <template v-else>
              ...
            </template>
          </span>
          <span :class="item.online ? 'online active' : 'online'"/>
          <img
            v-if="item.picture != '' && item.picture != null && item.picture != undefined && item.picture != ', '"
            :src="item.picture"
            alt="">
          <div
            v-else
            class="no-avatar">
            <span>{{ makeTextAvatar(item.title) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import '../../icons/chat-full';
  import {mapGetters} from 'vuex';
  import {dateFormatChat} from '~/services/chat'
  export default {
    props: {
      onOpenChat: {
        type: Function,
        default: () => {},
      }
    },
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters({
        chatList: 'chat/getChatList'
      }),
    },
    methods: {
      openChat: function(chatId){
        this.$store.dispatch('chat/GET_CHAT', chatId);
        this.$store.dispatch('chat/OPEN_CHAT');
        this.onOpenChat();
      },
      dateFormat: function(data){
        return dateFormatChat(data);
      },
      makeTextAvatar: function(title){
        let textAvatar = '';
        title.split(' ').forEach(function (value, index, array) {
            textAvatar += value.charAt(0);
            if(array.length - 1 != index) {
                textAvatar += '/'
            }
        });

        return textAvatar;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .list{
    margin-top: 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .item{
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    transition: $link-transition;
    text-align: center;
    & + &{
      margin-top: 12px;
    }
  }
  .avatar{
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .counter{
    position: absolute;
    top: -6px;
    right: -4px;
    padding: 0 3px;
    min-width: 20px;
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    background: $red;
    line-height: 14px;
    border-radius: 24px;
    border: 2px solid $side-bar-color;
  }
  .no-avatar{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #ffffff;
    text-align: center;
    font-weight: 600;
    line-height: 34px;
    text-transform: uppercase;
    font-size: 12px;
  }
  .online{
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 10px;
    height: 10px;
    border: 2px solid $side-bar-color;
    background: $green;
    border-radius: 50%;
    opacity: 0;
    transition: $link-transition;
    &.active{
      opacity: 1;
    }
  }
</style>