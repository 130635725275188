export default ({ route, store }) => {
  // Blog has custom breadcrumbs
  if (route.name.includes('blog') && !route.name.includes('administration')) return;

  let breadcrumbs = [];
  let breadcrumb;
  route.matched.forEach((link, index) => {
    breadcrumb = {};
    if(route.meta[index].title && route.meta[index].title != ''){
      breadcrumb.title = route.meta[index].title;
    }
    else{
      breadcrumb.title = '';
    }
    breadcrumb.link = link.path;
    breadcrumbs.push(breadcrumb)
  });
  store.commit('breadcrumbs/setBreadcrumbs', breadcrumbs)
}