<template>
  <div>
    <header class="header">
      <template v-if="!chatBar || chatFull">
        Диалоги
        <!--
        <svgicon
          name="chat-full"
          width="18"
          height="15"
          class="icon-full"
          @click="onChatFull"
        />
        -->
      </template>
      <template v-if="chatBar && !chatFull">
        <svgicon
          name="chat-back"
          width="14"
          height="10"
          class="icon-back"
          @click="chatBack"/>
        <div class="user-info">
          <div
            v-for="user in chatUsersList"
            :key="user.id"
            class="user">
            <div 
              class="avatar"
              v-b-tooltip.hover.html
              :title="getPopoverTitle(user)">
              <img
                v-if="
                  user.avatar != '' &&
                    user.avatar != null &&
                    user.avatar != undefined
                "
                :key="user.id"
                :src="user.avatar"
                :alt="user.username + ' аватар'">
              <div
                v-else
                class="no-avatar">
                <span style="text-transform: uppercase">
                  {{ makeTextAvatar(user.username) }}
                </span>
              </div>
              <span :class="user.online ? 'online active' : 'online'"/>
            </div>
            <div
              v-show="shortUserVariant()"
              class="user-wrapper">
              <div
                class="username"
                :class="{admin: parseInt(user.role) === 1, 'allow-long-username': shortUserVariant()}">
                {{ user.username }}
              </div>
              <div class="online-text">
                <span v-if="user.online">
                  Сейчас онлайн
                </span>
                <span v-else>
                  Был в сети
                  <timeago 
                    v-if="user.disconnectedAt" 
                    :ref="'timeago-' + user.id"
                    :datetime="new Date(user.disconnectedAt * 1000).toISOString()"
                    :auto-update="60">
                  </timeago>
                  <template v-else>
                    недавно
                  </template>
                </span>
              </div>
            </div>
          </div>
          <span
            v-if="showLeaveButton"
            class="leave-button"
            @click="leaveChat(chatInfo.id)">
            Покинуть
          </span>
        </div>
        <!--
        <svgicon
          name="chat-full"
          width="18"
          height="15"
          class="icon-full"
          @click="onChatFull"
        />
        -->
      </template>
    </header>
    <template
      v-if="
        !chatBar &&
          !chatFull &&
          user &&
          !isUserHasNotifiers &&
          !isPushDismissed
      ">
      <div
        id="chatPushBanner"
        ref="chatPushBanner"
        class="push-banner">
        <div class="img-wrapper">
          <img src="/img/notification.png">
        </div>
        <div class="text-wrapper">
          <span>
            Подключите уведомления, чтобы не пропускать сообщения
          </span>
          <span
            class="action"
            @click="subscribeNotifications">
            Подключить
          </span>
          <span
            class="action action-second"
            @click="dissmissNotifications">
            Позже
          </span>
        </div>
      </div>
    </template>
    <template v-if="!chatBar || chatFull">
      <barDialogs
        :is-subscribed="isUserHasNotifiers"
        :push-banner-height="pushBannerHeight"/>
    </template>
    <template v-if="chatBar && !chatFull">
      <div
        id="chat-bar-wrapper"
        class="chat-bar-wrapper">
        <chat chat="chat"/>
      </div>
    </template>
    <template v-if="chatBar && !chatFull">
      <sendMessage/>
    </template>
    <template v-if="chatFull">
      <chatFull :chat-active="chatFull"/>
    </template>
  </div>
</template>

<script>
import "../../icons/chat-full";
import "../../icons/chat-back";
import { mapGetters, mapMutations } from "vuex";
import barDialogs from "./barDialogs";
import chat from "./chat";
import sendMessage from "./sendMessage";
import chatFull from "./chatFull";

export default {
  components: {
    barDialogs,
    chat,
    sendMessage,
    chatFull
  },
  data() {
    return {
      showLeaveButton: false,
      pushBannerHeight: 0
    };
  },
  computed: {
    ...mapGetters({
      chatFull: "chat/chatFull",
      chatBar: "chat/chatBar",
      chatInfo: "chat/getChatInfo",
      chatUsers: "chat/getChatUsers",
      user: "auth/getUser",
      isPushSubscribed: "auth/getIsPushSubscribed",
      isPushDismissed: "auth/getIsPushDismissed",
      isPushwooshAlreadyInited: "auth/getIsPushwooshAlreadyInited",
      isUserHasNotifiers: 'users/isHasNotifiers'
    }),
    ...mapMutations({
      setIsPushSubscribed: "auth/setIsPushSubscribed",
      setIsPushDismissed: "auth/setIsPushDismissed",
      setIsPushwooshAlreadyInited: "auth/setIsPushwooshAlreadyInited"
    }),
    chatUsersList(){
      let users = {};

      Object.keys(this.chatUsers).forEach( item => {
        if (this.chatUsers[item].id != this.user.jti && this.chatUsers[item].isActive) {
          users[item] = this.chatUsers[item];
        }
      });

      return users;
    }
  },
  watch: {
    chatUsers(newValue, oldValue) {
      if (!this.$store.state.auth.user || !this.chatUsers)
      {
        this.showLeaveButton = false;
        return;
      }

      if (newValue && newValue != oldValue) {
        
        let currentUser = this.chatUsers[parseInt(this.$store.state.auth.user.jti)];

        this.showLeaveButton =
          Object.keys(this.chatUsers).length > 2 && parseInt(this.$store.state.auth.user.role) == 1 && currentUser && currentUser.canLeaveChat;
      }
    }
  },
  mounted() {
    if (!this.isUserHasNotifiers && !this.isPushDismissed) {
      if (this.$refs) {
        if (this.$refs.chatPushBanner) {
          this.pushBannerHeight =
            this.$refs.chatPushBanner.clientHeight || 64;
          this.dialogsHeight =
            this.dialogsHeight - this.pushBannerHeight;
        }
      }
    }

    // this.$nextTick(() => {
    //   if (
    //     this.isPushSubscribed === false &&
    //     this.isPushwooshAlreadyInited === false &&
    //     typeof window.Pushwoosh !== "undefined"
    //   ) {
    //     window.Pushwoosh.push([
    //       "init",
    //       {
    //         logLevel: "debug", // possible values: error, info, debug
    //         applicationCode: "E5AFB-97701", // you application code from Pushwoosh Control Panel
    //         safariWebsitePushID: "https://gobuffy.com", //  unique reverse-domain string, obtained in you Apple Developer Portal. Only needed if you send push notifications to Safari browser
    //         defaultNotificationTitle: "PUSH уведомление", // sets a default title for push notifications
    //         defaultNotificationImage: "https://gobuffy.com/img/G-logo.png", // URL to custom custom notification image
    //         autoSubscribe: false,
    //         subscribeWidget: {
    //           enabled: false
    //         }
    //       }
    //     ]);
    //     window.Pushwoosh.push([
    //       "onReady",
    //       api => {
    //         this.$store.commit("auth/setIsPushwooshAlreadyInited", true);
    //         window.Pushwoosh.isSubscribed().then(isSubscribed => {
    //           this.$store.commit("auth/setIsPushSubscribed", isSubscribed);
    //           if (!isSubscribed) {
    //             this.$nextTick(() => {
    //               if (this.$refs) {
    //                 if (this.$refs.chatPushBanner) {
    //                   this.pushBannerHeight =
    //                     this.$refs.chatPushBanner.clientHeight || 64;
    //                   this.dialogsHeight =
    //                     this.dialogsHeight - this.pushBannerHeight;
    //                 }
    //               }
    //             });
    //           }
    //         });
    //       }
    //     ]);
    //     window.Pushwoosh.push([
    //       "onPermissionGranted",
    //       api => {
    //         this.$store.commit("auth/setIsPushSubscribed", true);
    //         this.pushBannerHeight = 0;
    //         api.registerUser(this.user.pushwooshId).then();
    //       }
    //     ]);
    //     window.Pushwoosh.push([
    //       "onPermissionDenied",
    //       api => {
    //         this.$store.commit("auth/setIsPushSubscribed", false);
    //         this.$store.commit("auth/setIsPushDismissed", true);
    //         this.pushBannerHeight = 0;
    //       }
    //     ]);
    //   }
    //   if (
    //     this.isPushwooshAlreadyInited &&
    //     !this.isPushDismissed &&
    //     !this.isPushSubscribed
    //   ) {
    //     if (this.$refs) {
    //       if (this.$refs.chatPushBanner) {
    //         this.pushBannerHeight = this.$refs.chatPushBanner.clientHeight;
    //       }
    //     }
    //   }
    this.$nextTick(() => {
      if (
        document.getElementById("dialogsBarScroll") &&
        document
          .getElementById("dialogsBarScroll")
          .getElementsByClassName("list")[0]
      ) {
        let scrollWrapper = document
          .getElementById("dialogsBarScroll")
          .getElementsByClassName("vb-content")[0];
        this.dialogsHeight = document
          .getElementById("dialogsBarScroll")
          .getElementsByClassName("list")[0].scrollHeight;
        scrollWrapper.addEventListener("scroll", () => {
          if (
            scrollWrapper.scrollTop + scrollWrapper.offsetHeight ==
            this.dialogsHeight
          ) {
            this.uploadMoreDialogs();
          }
        });
      }
    });
  },
  methods: {
    subscribeNotifications() {
      this.$router.push({ path: '/profile/settings/notifications' })
      // window.Pushwoosh.subscribe();
    },
    dissmissNotifications() {
      this.$store.commit("auth/setIsPushDismissed", true);
      this.pushBannerHeight = 0;
    },
    chatBack: function() {
      this.pushBannerHeight = 0;
      this.$store.dispatch("chat/CHAT_BACK");
      this.$store.dispatch("chat/RESET_CHAT");
      if (
        this.isPushwooshAlreadyInited &&
        !this.isPushDismissed &&
        !this.isPushSubscribed
      ) {
        this.pushBannerHeight = 95;
      }
    },
    onChatFull: function() {
      this.pushBannerHeight = 0;
      this.$store.dispatch("chat/TOGGLE_CHAT_FULL");
    },
    makeTextAvatar: function(title) {
      let textAvatar = "";
      title.split(",").forEach(function(value, index, array) {
        textAvatar += value.charAt(0);
        if (array.length - 1 != index) {
          textAvatar += "/";
        }
      });

      return textAvatar;
    },
    leaveChat: function(chatId) {
      this.$store.dispatch("chat/ADMIN_LEAVE_CHAT", {
        chatId: chatId
      });
    },
    isLastUserInList(userId) {
      let usersList = this.chatUsersList,
          usersId = Object.keys(usersList),
          lastIndex = usersId.length - 1;

      return userId == usersList[usersId[lastIndex]].id
    },
    getUsernamePopoverText(username, role) {
      let name = '';

      name += parseInt(role) === 1 ? 'Админ: ' : '';

      return name += username;
    },
    shortUserVariant(){
      console.log('ALLOW LONG USERNAME:', Object.keys(this.chatUsersList).length < 2);

      return Object.keys(this.chatUsersList).length < 2;
    },
    getPopoverTitle(user) {
      const timeago = (this.$refs['timeago-' + user.id] && this.$refs['timeago-' + user.id].length != 0) 
        ? this.$refs['timeago-' + user.id][0].timeago 
        : null;
      
      let html = `<div><b>${this.getUsernamePopoverText(user.username, user.role)}</b>` +
                 (user.online ? '<br> Сейчас онлайн' : `<br> Был в сети ${timeago ?? ' недавно'}`) +
                 `</div>`;

      return html;           
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  padding-top: 16px;
  padding-bottom: 13px;
  padding-right: 0;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid $sidebar-separator;
}
.icon-full {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -8px;
  fill: rgba(#ffffff, 0.3);
  cursor: pointer;
}
.icon-back {
  position: absolute;
  top: 50%;
  left: 20px;
  margin-top: -5px;
  fill: rgba(#ffffff, 0.3);
  cursor: pointer;
}
.user-info {
  display: flex;
  align-items: center;
  justify-content: center;

  .user {
    display: flex;
    align-items: center;

    .no-avatar {
      width: 24px;
      height: 24px;
      background: #ffffff;
      line-height: 24px;
      text-align: center;
      font-size: 14px;
      color: #000000;
      border-radius: 50%;
    }
    .avatar {
      position: relative;
      display: block;
      float:left;
      margin-right: 6px;
      width: 24px;
      height: 24px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .online {
      position: absolute;
      bottom: -2px;
      right: -2px;
      width: 10px;
      height: 10px;
      border: 2px solid $side-bar-color;
      background: $green;
      border-radius: 50%;
      opacity: 0;
      transition: $link-transition;
      &.active {
        opacity: 1;
      }
    }
    .user-wrapper {
      display: flex;
      flex-direction: column;
      .username {
        display:block;
        float:left;
        max-width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }
      .online-text {
        display: flex;

        span {
          color: $grey-light;
          font-size: $text-10;  
          line-height: 10px;
        }
      }
    }
  }

  .leave-button {
    position:absolute;
    top:5px;
    right:25px;
    cursor: pointer;
    font-size:12px
  }
  
  .allow-long-username {
    max-width: 120px !important;
  }
  .plus-sep {
    color: $grey-light;
    padding: 0 5px;
  }
  .admin {
    color: #FFB000;
  }
}

.chat-bar-wrapper {
  position: absolute !important;
  top: 64px;
  bottom: 64px;
  left: 0;
  right: 0;
}
.push-banner {
  position: relative;
  display: block;
  width: 100%;
  float: left;
  border-top: 1px solid $sidebar-separator;
  background: $blue;
  text-align: left;
  color: #fff;
  padding: 13px 26px 16px 26px;
  font-size: 14px;
  .img-wrapper {
    float: left;
    width: 20%;
    height: 100%;
    position: relative;
    padding-top: 15px;
    img {
      display: flex;
      margin: auto;
    }
  }
  .text-wrapper {
    float: left;
    width: 80%;
    position: relative;
    .action {
      cursor: pointer;
      color: #fff;
      font-size: 12px;
      line-height: 14px;
      text-decoration-line: underline;
      .action-second {
        margin-left: 20px;
      }
    }
    span {
      width: 100%;
    }
  }
}
.close-icon {
  display: inline-block;
  cursor: pointer;
  color: #fff;
  fill: #fff;
}
.action-second {
  margin-left: 20px;
}
</style>
