/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat-full': {
    width: 18,
    height: 15,
    viewBox: '0 0 18 15',
    data: '<path pid="0" d="M12 2a1 1 0 010-2h3a3 3 0 013 3v9a3 3 0 01-3 3h-3a1 1 0 010-2h3a1 1 0 001-1V3a1 1 0 00-1-1h-3zm0-2a1 1 0 010 2H3a1 1 0 00-1 1v9a1 1 0 001 1h9a1 1 0 010 2H3a3 3 0 01-3-3V3a3 3 0 013-3h9zm-1 1a1 1 0 012 0v13a1 1 0 01-2 0V1z" _fill="#000" fill-rule="nonzero"/>'
  }
})
