/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notifications': {
    width: 16,
    height: 20,
    viewBox: '0 0 16 20',
    data: '<path pid="0" d="M8 19.5c1.1 0 2-.9 2-2H6a2 2 0 002 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V1.5C9.5.67 8.83 0 8 0S6.5.67 6.5 1.5v.68C3.63 2.86 2 5.42 2 8.5v5l-2 2v1h16v-1l-2-2z" _fill="#A8AAAF"/>'
  }
})
