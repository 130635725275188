/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'payment_plus-icon': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" d="M0 7.5h15M7.5 0v15" _stroke="#098AFF"/>'
  }
})
