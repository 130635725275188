<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-6">
          <footerCopyright/>
        </div>
        <div class="col-sm-6">
          <footerNav/>
          <footerSocial/>
        </div>
      </div>
    </div> 
  </footer>
</template>

<script>
  import footerNav from '~/components/footer/footerNav.vue'
  import footerCopyright from '~/components/footer/footerCopyright.vue'
  import footerSocial from '~/components/footer/footerSocial.vue'

  export default {
    components: {
      footerNav,
      footerCopyright,
      footerSocial
    }
  }
</script>

<style lang="scss" scoped>
  .footer{
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 48px;
    background: $header-color;
  }
</style>