/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'discord': {
    width: 25,
    height: 29,
    viewBox: '0 0 25 29',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.8 14c0-.879.633-1.6 1.434-1.6.802 0 1.448.721 1.434 1.6 0 .88-.632 1.6-1.434 1.6-.787 0-1.434-.72-1.434-1.6zm5.132 0c0-.879.633-1.6 1.434-1.6.801 0 1.434.721 1.434 1.6 0 .88-.633 1.6-1.434 1.6-.787 0-1.434-.72-1.434-1.6z" _fill="#292E3B"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M2.905.4h18.99C23.496.4 24.8 1.688 24.8 3.284V28.4l-3.047-2.66-1.715-1.567-1.814-1.666.752 2.59H2.906C1.303 25.097 0 23.809 0 22.212V3.284C0 1.688 1.304.4 2.905.4zM15.49 17.55c.425.532.936 1.134.936 1.134 3.132-.098 4.336-2.128 4.336-2.128 0-4.508-2.04-8.162-2.04-8.162-2.041-1.512-3.983-1.47-3.983-1.47l-.198.223c2.409.729 3.529 1.779 3.529 1.779a11.66 11.66 0 00-4.266-1.344 12.104 12.104 0 00-2.863.028c-.085 0-.155.014-.24.028-.497.042-1.701.224-3.217.882-.525.238-.837.406-.837.406s1.177-1.106 3.728-1.834l-.142-.168S8.29 6.882 6.25 8.394c0 0-2.041 3.654-2.041 8.162 0 0 1.19 2.03 4.322 2.128l.327-.396c.182-.222.414-.506.623-.767-1.8-.53-2.48-1.65-2.48-1.65s.059.04.17.106c.06.038.136.082.226.131a.676.676 0 00.12.077 8.315 8.315 0 001.098.51 12.07 12.07 0 002.084.603c1.063.196 2.31.266 3.67.014a9.378 9.378 0 002.056-.602 8.138 8.138 0 001.63-.826s-.71 1.148-2.566 1.666z" _fill="#292E3B"/>'
  }
})
