/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'star': {
    width: 24,
    height: 22,
    viewBox: '0 0 24 22',
    data: '<path pid="0" d="M12.2 0l2.65 8.292h8.572l-6.936 5.124 2.65 8.292-6.936-5.124-6.936 5.124 2.65-8.292L.976 8.292h8.574L12.2 0z" _fill="#A8AAAF"/>'
  }
})
