<template>
  <div class="user">
    <div
      v-if="!premium || parseInt(premium) < Math.floor(Date.now() / 1000)"
      class="user-avatar">
      <i :class="statusNow ? 'user-status online' : 'user-status'"/>
      <img
        v-if="avatar && avatar != '' && avatar != null && isAvatarLoaded"
        :width="size"
        :height="size"
        :src="avatar"
        class="user-img"
        alt=""
        @load.self="avatarLoadSuccess"
        @error.self="avatarLoadError">
      <span
        v-else
        :style="
          'width:' +
            size +
            'px;height:' +
            size +
            'px;line-height:' +
            size +
            'px;' +
            'font-size:' +
            parseInt(size / 2) +
            'px'
        "
        class="no-avatar">
        {{ name.charAt(0) }}
      </span>
    </div>
    <div
      v-if="!premium || parseInt(premium) < Math.floor(Date.now() / 1000)"
      class="user-info">
      {{ name.length > 11 ? getElipsis(name) : name }}
      <span
        v-if="role != ''"
        class="user-role">
        {{ role }}
      </span>
    </div>
    <div
      v-if="parseInt(premium) >= Math.floor(Date.now() / 1000)"
      class="user-avatar user-avatar-pro">
      <nuxt-link
        v-if="parseInt(premium) >= Math.floor(Date.now() / 1000)"
        class="user-info user-info-pro"
        :to="'/profile/' + id + '/' + filter"
        @click.native="emitHitAd">
        <i :class="statusNow ? 'user-status online' : 'user-status'"/>
        <img
          src="/svg/pro-border.svg"
          class="pro-border"
          :width="size + 8"
          :height="size + 8">
        <img
          v-if="avatar && avatar != '' && avatar != null && isAvatarLoaded"
          :width="size"
          :height="size"
          :src="avatar"
          class="user-img"
          alt=""
          @load.self="avatarLoadSuccess"
          @error.self="avatarLoadError">
        <span
          v-else
          :style="
            'width:' +
              size +
              'px;height:' +
              size +
              'px;line-height:' +
              size +
              'px;' +
              'font-size:' +
              parseInt(size / 2) +
              'px'
          "
          class="no-avatar">
          {{ name.charAt(0) }}
        </span>
      </nuxt-link>
    </div>
    <nuxt-link
      v-if="parseInt(premium) >= Math.floor(Date.now() / 1000)"
      class="user-info user-info-pro"
      :to="'/profile/' + id + '/' + filter"
      @click.native="emitHitAd">
      {{ name.length > 11 ? getElipsis(name) : name }}
      <span class="user-role-pro"> PRO <img src="/img/pro-stars.png"> </span>
    </nuxt-link>
  </div>
</template>

<script>
import { socket } from "~/plugins/socket-io.js";
export default {
  props: {
    avatar: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: null,
    },
    size: {
      type: Number,
      default: 32,
    },
    name: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "",
    },
    status: {
      type: Boolean,
      default: false,
    },
    premium: {
      type: Number,
      default: 0,
    },
    filter: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      statusNow: false,
      isAvatarLoaded: true,
      disconnectedAt: null,
    };
  },
  watch: {
    status: {
      handler: function (status) {
        this.statusNow = status;
      },
    },
  },
  mounted: function () {
    this.statusNow = this.status;
    this.emitStatus();
  },
  methods: {
    emitStatus: function () {
      socket.on("user online", (data) => {
        if (this.id == data.id) {
          this.statusNow = true;
          this.$emit('change-online', {isOnline: true})
        }
      });
      socket.on("user offline", (data) => {
        if (this.id == data.id) {
          this.statusNow = false;
          this.$emit('change-online', {
            isOnline: false, 
            disconnectedAt: data.disconnectedAt ? data.disconnectedAt : null
          })
        }
      });
    },
    avatarLoadError() {
      this.isAvatarLoaded = false;
    },
    avatarLoadSuccess() {
      this.isAvatarLoaded = true;
    },
    getElipsis(text) {
      return text.substring(0, 11) + "...";
    },
    emitHitAd() {
      this.$emit("hitAd");
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $body-color;
}
.user:hover {
  .user-info-pro {
    color: #6e7279;
  }
}

.user-avatar {
  position: relative;
  margin-right: 8px;
  z-index: 1;
}

.user-avatar-pro {
  border-radius: 50%;
  span {
    margin: 1px;
    transition: margin 0.2s;
  }
  .user-img {
    margin: 1px;
    transition: margin 0.2s;
  }
  .pro-border {
    position: absolute;
    top: -3px;
    left: -3px;
  }
}
.user-info-pro {
  color: #2d303a;
}

.user-status {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  z-index: 2;
  background: transparent;
  border: 2px solid transparent;
  transition: $link-transition;
}
.user-status.online {
  background: $green;
  border-color: #ffffff;
}
.no-avatar {
  display: inline-block;
  vertical-align: middle;
  background: #5ac8fa;
  text-align: center;
  font-size: 14px;
  border-radius: 50%;
  text-transform: uppercase;
  color: #ffffff;
}
.no-avatar::before {
  box-sizing: initial !important;
}

.no-avatar::after {
  box-sizing: initial !important;
}

.user-img {
  border-radius: 50%;
}
.user-role {
  display: block;
  font-size: 12px;
  color: #a8aaaf;
}
.user-role-pro {
  display: block;
  font-size: 12px;
  color: #ff3330;
}
.user-info {
  line-height: 1.3;
}
</style>
