var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"header"},[(!_vm.chatBar || _vm.chatFull)?[_vm._v("\n      Диалоги\n      ")]:_vm._e(),_vm._v(" "),(_vm.chatBar && !_vm.chatFull)?[_c('svgicon',{staticClass:"icon-back",attrs:{"name":"chat-back","width":"14","height":"10"},on:{"click":_vm.chatBack}}),_vm._v(" "),_c('div',{staticClass:"user-info"},[_vm._l((_vm.chatUsersList),function(user){return _c('div',{key:user.id,staticClass:"user"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],staticClass:"avatar",attrs:{"title":_vm.getPopoverTitle(user)}},[(
                user.avatar != '' &&
                  user.avatar != null &&
                  user.avatar != undefined
              )?_c('img',{key:user.id,attrs:{"src":user.avatar,"alt":user.username + ' аватар'}}):_c('div',{staticClass:"no-avatar"},[_c('span',{staticStyle:{"text-transform":"uppercase"}},[_vm._v("\n                "+_vm._s(_vm.makeTextAvatar(user.username))+"\n              ")])]),_vm._v(" "),_c('span',{class:user.online ? 'online active' : 'online'})]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shortUserVariant()),expression:"shortUserVariant()"}],staticClass:"user-wrapper"},[_c('div',{staticClass:"username",class:{admin: parseInt(user.role) === 1, 'allow-long-username': _vm.shortUserVariant()}},[_vm._v("\n              "+_vm._s(user.username)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"online-text"},[(user.online)?_c('span',[_vm._v("\n                Сейчас онлайн\n              ")]):_c('span',[_vm._v("\n                Был в сети\n                "),(user.disconnectedAt)?_c('timeago',{ref:'timeago-' + user.id,refInFor:true,attrs:{"datetime":new Date(user.disconnectedAt * 1000).toISOString(),"auto-update":60}}):[_vm._v("\n                  недавно\n                ")]],2)])])])}),_vm._v(" "),(_vm.showLeaveButton)?_c('span',{staticClass:"leave-button",on:{"click":function($event){return _vm.leaveChat(_vm.chatInfo.id)}}},[_vm._v("\n          Покинуть\n        ")]):_vm._e()],2)]:_vm._e()],2),_vm._v(" "),(
      !_vm.chatBar &&
        !_vm.chatFull &&
        _vm.user &&
        !_vm.isUserHasNotifiers &&
        !_vm.isPushDismissed
    )?[_c('div',{ref:"chatPushBanner",staticClass:"push-banner",attrs:{"id":"chatPushBanner"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"text-wrapper"},[_c('span',[_vm._v("\n          Подключите уведомления, чтобы не пропускать сообщения\n        ")]),_vm._v(" "),_c('span',{staticClass:"action",on:{"click":_vm.subscribeNotifications}},[_vm._v("\n          Подключить\n        ")]),_vm._v(" "),_c('span',{staticClass:"action action-second",on:{"click":_vm.dissmissNotifications}},[_vm._v("\n          Позже\n        ")])])])]:_vm._e(),_vm._v(" "),(!_vm.chatBar || _vm.chatFull)?[_c('barDialogs',{attrs:{"is-subscribed":_vm.isUserHasNotifiers,"push-banner-height":_vm.pushBannerHeight}})]:_vm._e(),_vm._v(" "),(_vm.chatBar && !_vm.chatFull)?[_c('div',{staticClass:"chat-bar-wrapper",attrs:{"id":"chat-bar-wrapper"}},[_c('chat',{attrs:{"chat":"chat"}})],1)]:_vm._e(),_vm._v(" "),(_vm.chatBar && !_vm.chatFull)?[_c('sendMessage')]:_vm._e(),_vm._v(" "),(_vm.chatFull)?[_c('chatFull',{attrs:{"chat-active":_vm.chatFull}})]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img-wrapper"},[_c('img',{attrs:{"src":"/img/notification.png"}})])}]

export { render, staticRenderFns }