import axios from "axios/index";
export const state = () => ({
  ads: null,
  games: null,
  clicks: null,
  allGames: null,
  allUserGames: null,
  userAds: null,
  game: null,
  activeCategory: null,
  adsList: null,
  filterId: null
});

export const mutations = {
  setGames(state, data) {
    state.games = data;
  },
  setClicks(state, payload) {
    state.clicks = payload.clicks;
  },
  setAllGames(state, data) {
    state.allGames = data;
  },
  setAllUserGames(state, data) {
    state.allUserGames = data;
  },
  setUserAds(state, data) {
    state.userAds = data;
  },
  updateAd(state, data) {
    let ad = state.userAds.items.filter(a => a.id == data.id)[0];
    if (ad) {

      Object.keys(data).forEach(item => {
        ad[item] = data[item];
      });
      if (data.restore && ad.baseCategories) {
        Object.keys(ad.stats.categories).forEach(item => {
          ad.stats.categories[item].is_show = ad.baseCategories.includes(item);
        });
      }
      ad.baseCategories = null;
    }

  },
  addAd(state, data) {
    if (state.userAds && state.userAds.items) {
      state.userAds && state.userAds.items.unshift(data);
    }

  },
  setGame(state, data) {
    state.game = data;
  },
  setActiveCategory(state, data) {
    state.activeCategory = data;
  },
  deleteAd(state, id) {
    let ad = state.userAds.items.filter(a => a.id == id)[0];
    if (ad) {
      const index = state.userAds.items.indexOf(ad);
      if (index > -1) {
        state.userAds.items.splice(index, 1);
      }
    }
  },
  showCategory(state, data) {
    let ad = state.userAds.items.filter(a => a.id == data.id)[0];
    if (ad && ad.stats && ad.stats.categories && ad.stats.categories[data.categoryId]) {
      if (!ad.baseCategories) {
        ad.baseCategories = [];
        Object.keys(ad.stats.categories).forEach(item => {
          if (ad.stats.categories[item].is_show) {
            ad.baseCategories.push(item);
          }
        })
      }
      ad.stats.categories[data.categoryId].is_show = !ad.stats.categories[data.categoryId].is_show;
    }
  },
  setAdsList(state, data) {
    state.adsList = data;
  },
  setFilterId(state, data){
    state.filterId = data;
  }
};
export const getters = {
  getAds: (state) => {
    return state.ads;
  },
  getGames: (state) => {
    return state.games;
  },
  getClicks: (state) => {
    return state.clicks;
  },
  getAllGames: (state) => {
    return state.allGames;
  },
  getAllUserGames: (state) => {
    return state.allUserGames;
  },
  getUserAds: (state) => {
    return state.userAds;
  },
  getGame: (state) => {
    return state.game;
  },
  getActiveCategory: (state) => {
    return state.activeCategory;
  },
  getAdsList: (state) => {
    return state.adsList;
  },
  getFilterId: (state) => {
    return state.filterId
  }

};
export const actions = {
  GET_ADS_LIST({ commit, rootState }, data) {
    return axios
      .get("direct-buff/list?game_id=" + data.gameId + "&category_id=" + data.categoryId + "&expand=user,game,sign,time")
      .then(response => {
        if (response.data.success) {
          commit("setAdsList", response.data.data);
        }
        return response.data;
      })
      .catch(exception => {
        return exception;
      });
  },
  GET_ALL_USER_ADS_GAMES({ commit, rootState }, id) {
    return axios
      .get("/direct-buff/games-categories?user_id=" + id)
      .then(response => {
        if (response.data.success) {
          commit("setAllUserGames", response.data.data);
        }
        return response.data;
      })
      .catch(exception => {
        return exception;
      });

  },
  async GET_ALL_GAMES({ commit, rootState }) {
    let gamesRes = await axios.get(
      "/games?status=1?expand=directBuffStats,directBuffSettings,categories&per-page=50"
    );
    if (gamesRes && gamesRes.data && gamesRes.data.data) {
      while (gamesRes.data.data._links && gamesRes.data.data._links.next) {
        var next = gamesRes.data.data._links.next.href;
        if (next) {
          next = next.replace(process.env.backendUrl, "");
          next = next.replace("http:", "");
          next = next.replace("https:", "");
          next = next.replace("/v1/", "");
          var nextPage = await axios.get(next);
          gamesRes.data.data.items = gamesRes.data.data.items.concat(
            nextPage.data.data.items
          );
          gamesRes.data.data._links.next = nextPage.data.data._links.next;
        }
      }
    }
    commit("setAllGames", gamesRes.data.data.items);
  },
  GET_USER_ADS({ commit, rootState }, data) {
    let page = "";
    if (data.query && data.query.page) {
      page = "&page=" + data.query.page;
    }

    let game = "";
    if (data.query && data.query.gameId) {
      game = "&game_id=" + data.query.gameId;
    }

    let category = "";
    if (data.query && data.query.categoryId) {
      category = "&show_in_category=" + data.query.categoryId;
    }

    let user = ""
    if (data.id)
    {
      user = "&user_id=" + data.id;
    }

    let filterId = ""
    if (data.filterId)
    {
      filterId = "&id=" + data.filterId;
    }

    let gamesRequest = "/direct-buff?status=1&sort=-id&expand=user,game,views,clicks" + page + game + category + user + filterId;

    return axios
      .get(gamesRequest)
      .then(response => {
        if (response.data.success) {
          commit("setUserAds", response.data.data);
        }

        return response.data;
      })
      .catch(exception => {

        return exception;
      });
  },
  GET_GAMES({ commit, rootState }, data) {
    let page = "";
    if (data.query && data.query.gamesPage) {
      page = "&page=" + data.query.gamesPage;
    }
    let gamesRequest = "/games?per-page=20&expand=categories,directBuffStats,directBuffSettings" + page;
    return axios
      .get(gamesRequest)
      .then(response => {
        if (response.data.success) {
          commit("setGames", response.data.data);
        }

        return response.data;
      })
      .catch(exception => {

        return exception;
      });
  },
  GET_CLICKS({ commit, rootState }, apiQuery) {
    let query = '';

    if (apiQuery) {
      query = '?';
      query += apiQuery.page ? 'page=' + apiQuery.page : 'page=1';
      query += apiQuery.sort ? '&sort=' + apiQuery.sort : '';
    }

    return axios.get('/direct-buff/clicks' + query).then(res => {
      if (res.data.success) {
        commit('setClicks', { clicks: res.data.data });
      }
    }).catch(err => {
      console.log(err);
    });
  },
  SET_DIRECT_BUFF({ commit, rootState }, data) {
    return axios.post('/direct-buff', data)
      .then(response => {
        if (data.user)
        {
          response.data.data.user = data.user;
        }
        if (data.game)
        {
          response.data.data.game = data.game;
        }
        commit("addAd", response.data.data);
        return response.data;
      })
      .catch(exception => {
        return exception.response;
      })
  },
  LOAD_AD({ commit, rootState }, id) {
    return axios.get('/direct-buff/' + id + "?expand=stats")
      .then(response => {
        if (response.data.success) {
          commit("updateAd", response.data.data);
          commit("updateAd", { id: id, isLoaded: true });
        }
        return response.data;
      })
      .catch(exception => {
        return exception.response;
      })
  },
  SET_GAME({ commit }, game) {
    commit("setGame", game);
  },
  SET_ACTIVE_CATEGORY({ commit }, activeCategory) {
    commit("setActiveCategory", activeCategory);
  },
  UPDATE_AD({ commit }, data) {
    let toSend = { title: data.title, game_id: data.game_id };
    if (data.categoriesIds) {
      toSend = { title: data.title, game_id: data.game_id, categoriesIds: data.categoriesIds };
    }

    return axios.put('/direct-buff/' + data.id, toSend)
      .then(response => {
        if (response.data.success) {
          commit("updateAd", data);
        }
        return response.data;
      })
      .catch(exception => {
        return exception.response
      }
      );
  },
  SHOW_AD_CATEGORY({ commit }, data) {
    commit("showCategory", { id: data.id, categoryId: data.categoryId });
  },
  RESTORE_AD({ commit }, id) {
    commit("updateAd", { id: id, restore: true });
  },
  DELETE_AD({ commit }, id) {
    return axios.delete('/direct-buff/' + id)
      .then(response => {
        commit("deleteAd", id);
        return response.data;
      })
      .catch(exception => {
        return exception.response
      }
      );
  },
  HIT_AD({ commit }, data) {
    return axios.post('/direct-buff/' + data.id + '/hit', { sign: data.sign, time: data.time, category_id: data.categoryId })
      .catch(exception => {
        return exception.response
      }
      );
  },
  SET_FILTER_ID({ commit }, id) {
    commit("setFilterId", id);
  }
};
