var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"bar",rawName:"v-bar"}],staticClass:"scroll-wrap",attrs:{"id":"chatBarScroll"}},[_c('div',[_c('div',{staticClass:"list"},[_c('div',{class:_vm.chatFull ? 'list-full' : ''},_vm._l((_vm.chat.messages),function(item,index){return _c('div',{key:index},[(item.role != 'bot')?[(
                index > 0 &&
                  _vm.chat.messages[index - 1].userId == item.userId &&
                  _vm.compareTime(_vm.chat.messages[index - 1].time, item.time)
              )?[_c('div',{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.cleanMessage(item.message))}})]:[_c('div',{staticClass:"head"},[_c('div',{staticClass:"avatar"},[(
                      _vm.chat.users[item.userId].avatar != '' &&
                        _vm.chat.users[item.userId].avatar != null
                    )?_c('img',{attrs:{"src":_vm.chat.users[item.userId].avatar,"width":"24","height":"24","alt":""}}):_c('div',{staticClass:"no-avatar"},[_c('span',[_vm._v(_vm._s(_vm.chat.users[item.userId].username.charAt(0)))])])]),_vm._v(" "),_c('div',{staticClass:"name",class:{
                    admin: parseInt(_vm.chat.users[item.userId].role) == 1,
                  }},[_vm._v("\n                  "+_vm._s(_vm.chat.users[item.userId].username)+"\n                ")]),_vm._v(" "),(parseInt(_vm.chat.users[item.userId].role) == 1)?_c('div',{staticClass:"badge"},[_vm._v("\n                  ADMIN\n                ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"time"},[_vm._v("\n                  "+_vm._s(_vm.dateFormat(item.time))+"\n                ")])]),_vm._v(" "),_c('div',{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.cleanMessage(item.message))}})]]:[_c('div',{staticClass:"bot-message"},[_vm._m(0,true),_vm._v(" "),_c('div',{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.cleanMessage(item.message))}})])]],2)}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head"},[_c('div',{staticClass:"bot-avatar avatar"},[_c('img',{attrs:{"src":"/svg/chat-warning.svg","width":"32","height":"32","alt":""}})]),_vm._v(" "),_c('div',{staticClass:"header"},[_vm._v("\n                  Сделки вне сайта небезопасны и несут риски\n                ")])])}]

export { render, staticRenderFns }