
// Собираем атрибуты которые не должен заполнять покупатель
// CONTENT_ATTRIBUTES
export function contentAttributes(offerToAttrs){
  let attributeId;
  let contentAttrs = [];

  for (let i = 0; i < offerToAttrs.length; i++){
    if(offerToAttrs[i].attr && offerToAttrs[i].attr.hidden == 0 && offerToAttrs[i].attr.filled_by_buyer == 0){

      if(offerToAttrs[i].attr.type == 3 || offerToAttrs[i].attr.type == 4){
        let content = '';
        for(let n = 0; n < offerToAttrs[i].attr.availableValues.length; n++){
          let availableValues = offerToAttrs[i].available_value_id.replace('[', '').replace(']', '').split(',');
          for(let aIndex = 0; aIndex < availableValues.length; aIndex++){
            if(availableValues[aIndex] == offerToAttrs[i].attr.availableValues[n].id){
              if(content != ''){
                content += ', '
              }
              content += offerToAttrs[i].attr.availableValues[n].value;
            }
          }
        }
        if(offerToAttrs[i].attribute_id != attributeId){
          contentAttrs.push({
            name: offerToAttrs[i].attr.name,
            type: offerToAttrs[i].attr.type,
            content: content,
            after_buy: offerToAttrs[i].attr.after_buy
          });
        }
        else{
          contentAttrs[contentAttrs.length - 1].content += ', ' + content;
        }
        attributeId = offerToAttrs[i].attribute_id;
      }
      else{
        contentAttrs.push({
          name: offerToAttrs[i].attr.name,
          type: offerToAttrs[i].attr.type,
          content: offerToAttrs[i].value,
          after_buy: offerToAttrs[i].attr.after_buy
        });
      }
    }

  }

  console.log(contentAttrs);
  return contentAttrs;
}

// CALCULATE_ATTRIBUTES
// Собираем атрибуты которые может заполнить покупатель (заполнил продавец)
export function calculateAttributes(data){
  let offerToAttrs = data.offerToAttrs;
  let attrs = data.attrs;

  for(let i = 0; i < attrs.length; i++){
    if(attrs[i].type == 3 && (attrs[i].view == 4 || attrs[i].view == 6) && attrs[i].filled_by_buyer == 1){
      let availableObj = {};
      for(let n = 0; n < offerToAttrs.length; n++){
        if(offerToAttrs[n].attribute_id == attrs[i].id){
          let availableArray = JSON.parse(offerToAttrs[n].available_value_id);
          for(let k = 0; k < availableArray.length; k++){
            availableObj[availableArray[k]] = availableArray[k];
          }
        }
      }
      attrs[i].availableValues = attrs[i].availableValues.filter(function(item) {
        return availableObj[item.id];
      });
    }
  }
  return attrs;
}