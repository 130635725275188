/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'profile': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" opacity=".7" d="M8 8c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" _fill="#A8AAAF"/>'
  }
})
