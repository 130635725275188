import axios from "axios/index";
import {getDate, getPhoto} from "../services/base";
import {contentAttributes, calculateAttributes} from "../services/offerAttributes";

export const state = () => ({
  offer: {},
  calculateTrigger: false,
  calculateStatus: false,
  reviews: {}
});

export const mutations = {
  setOffer(state, data) {
    state.offer = data;
  },
  resetOffer(state){
    state.offer = {};
  },
  changeCalculateTrigger(state){
    state.calculateTrigger = !state.calculateTrigger;
  },
  changeCalculateStatus(state, status){
    state.calculateStatus = status;
  },
  setReviews(state, data){
    state.reviews = data;
  },
  changeActiveCategoryCalculateAttr(state, data){
    
  }
};

export const actions = {
  GET_OFFER({commit, rootState}, id){
    commit('resetOffer');
    return axios.get('/offers/' + id +'?expand=offerToAttrs,user,attrs,category,customAttrs')
      .then(response => {
        if(response.data.success) {
          let offer = response.data.data;
          let monthNames = ["января", "февраля", "марта", "апреля", "мая", "июня",
            "июля", "августа", "сентября", "октября", "ноября", "декабря"
          ];
          let date = new Date(offer.user.created_at * 1000);
          offer.user.created_at = monthNames[date.getMonth()] + ' ' + date.getFullYear();
          offer.user.photo = getPhoto(offer.user.id, offer.user.photo_uploaded_at);
          let attributeId;

          offer.totalTime = null;
          offer.totalPrice = null;
          for(let i = 0; i < rootState.offerListMain.offers.length; i++){
            if(rootState.offerListMain.offers[i].id == id){
              if(rootState.offerListMain.calculateCategory[offer.category_id]){
                offer.attrs = rootState.offerListMain.calculateCategory[offer.category_id]
              }
              else if(rootState.offerListMain.offers[i].calculateAttrs){
                offer.attrs = rootState.offerListMain.offers[i].calculateAttrs.slice();
              }
              offer.totalTime = rootState.offerListMain.offers[i].calculatedTime;
              offer.totalPrice = rootState.offerListMain.offers[i].calculatedPrice;
            }
          }

          // Добавляем свойства для вычисляемых атрибутов
          for(let i = 0; i < offer.attrs.length; i++){
            if (offer.attrs[i].filled_by_buyer != 1) continue;
            if(offer.attrs[i].type == 0){
              offer.attrs[i].value = 0;
            }
            if(offer.attrs[i].type == 1){
              offer.attrs[i].value = '';
              offer.attrs[i].time = '';
              if(offer.attrs[i].view == 3){
                offer.attrs[i].from = '';
                offer.attrs[i].to = '';
              }
            }
            if(offer.attrs[i].type == 2){
              offer.attrs[i].value = '';
            }
            if(offer.attrs[i].type == 3){
              offer.attrs[i].value = '';
              offer.attrs[i].time = '';
              if(offer.attrs[i].view == 4) {
                for (let n = 0; n < offer.attrs[i].availableValues.length; n++) {
                  offer.attrs[i].availableValues[n].check = 0;
                }
              }
            }
            if(offer.attrs[i].type == 4){
              offer.attrs[i].value = '';
              offer.attrs[i].time = '';
            }
            if(offer.attrs[i].required_by_buyer == 1){
              offer.attrs[i].valid = false;
            }
          }

          // Собираем атрибуты которые не должен заполнять покупатель
          offer.contentAttrs = contentAttributes(offer.offerToAttrs);
          offer.attrs = calculateAttributes({attrs: offer.attrs, offerToAttrs: offer.offerToAttrs});
          console.log(offer);

          commit('setOffer', offer)
        }
        else{
          return response.data.data;
        }
      })
      .catch(exception => {
          return exception.response
        }
      )
  },
  CALCULATE_TRIGGER({commit}){
    setTimeout(()=>{commit('changeCalculateTrigger')}, 5); // ? проверить как правильно
  },
  CHANGE_CALCULATE_STATUS({commit}, status){
    commit('changeCalculateStatus', status);
  },
  CHANGE_ACTIVE_CATEGORY_CALCULATE_ATTR({commit}, data)
  {
    commit('changeActiveCategoryCalculateAttr', data);
  },
  GET_REVIEWS({commit}, userId){
    return axios.get('/reviews?to_user_id=' + userId +'&type=2&sort=-id&expand=order,buyer')
      .then(response => {
        console.log(response.data);
        if (response.data.success) {
          let reviews = response.data.data;
          for(let i = 0; i < reviews.items.length; i++){
            reviews.items[i].created_at = getDate(reviews.items[i].created_at);
            reviews.items[i].buyer.photo = getPhoto(reviews.items[i].buyer.id, reviews.items[i].buyer.photo_uploaded_at);
            if(reviews.items[i].external)
            {
              reviews.items[i].external.created_at = getDate(reviews.items[i].external.created_at);
            }
          }
          commit('setReviews', response.data.data);
        }
      });
  }
};