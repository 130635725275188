/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'steam': {
    width: 41,
    height: 32,
    viewBox: '0 0 41 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.642 9.958c0 .04 0 .08.002.119l-5.85 8.556a6.514 6.514 0 00-2.8.498c-.397.163-.77.364-1.119.595L.031 14.402s-.297 4.923.942 8.593l9.08 3.773c.455 2.052 1.853 3.851 3.915 4.717 3.374 1.42 7.264-.198 8.667-3.597.366-.889.536-1.82.511-2.75l8.37-6.023c.067.002.137.004.205.004 5.008 0 9.079-4.112 9.079-9.161C40.8 4.908 36.73.8 31.72.8c-5.006 0-9.078 4.108-9.078 9.158zm-1.404 17.34c-1.086 2.625-4.083 3.871-6.688 2.778a5.1 5.1 0 01-2.632-2.529l2.955 1.234a3.756 3.756 0 004.924-2.046c.801-1.936-.108-4.16-2.028-4.965l-3.055-1.275a5.05 5.05 0 013.771.058 5.095 5.095 0 012.761 2.798 5.167 5.167 0 01-.008 3.946zM31.721 16.06c-3.334 0-6.049-2.738-6.049-6.103 0-3.363 2.715-6.102 6.049-6.102 3.336 0 6.05 2.739 6.05 6.102 0 3.365-2.714 6.103-6.05 6.103zm-4.533-6.113c0-2.531 2.036-4.585 4.543-4.585 2.51 0 4.545 2.054 4.545 4.585 0 2.532-2.036 4.584-4.545 4.584-2.507 0-4.543-2.052-4.543-4.584z" _fill="#292E3B"/>'
  }
})
