/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chat-send': {
    width: 13,
    height: 11,
    viewBox: '0 0 13 11',
    data: '<path pid="0" d="M0 11l13-5.5L0 0v4.278L9.286 5.5 0 6.722V11z" _fill="#fff"/>'
  }
})
