<template>
  <div>
    <b-modal
      id="modalRegistrationSocial"
      v-model="show"
      centered
      class="modalAuth">
      <div
        slot="modal-header"
        :header-border-variant="0"
        class="modalHeader">
        <svgicon
          name="close"
          width="14"
          height="14"
          class="closeModal"
          @click="show=false"/>
        <img
          src="/img/logoBlack.svg"
          alt="Gobuffy">
      </div>
      <div class="modalContent">
        <div class="modalDescription">
          Присоединяйтесь к крупнейшему игровому сообществу. Призыв к действию тут
        </div>

        <h3>Регистрация</h3>

        <button class="vk">
          <span class="buttonIconVk">
            <svgicon
              name="vk"
              width="22"
              height="13"
              class="vkIcon"/>
          </span>
          Регистрация через VK.com
        </button>
        <button class="facebook">
          <span class="buttonIconFacebook">
            <svgicon
              name="facebook"
              width="12"
              height="24"
              class="facebookIcon"/>
          </span>
          Регистрация через Facebook
        </button>
      </div>
      <div
        slot="modal-footer"
        class="modalFooter">
        <p>
          <a
            v-b-modal.modalRegistration
            class="modalFooterLink"
            @click="show = false">
            Зарегистрироваться используя email и пароль
          </a>
        </p>
        <p>
          <a
            v-b-modal.modalLogin
            class="modalFooterLink"
            @click="show = false">
            Войти
          </a>
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
    import '../../../icons/close';
    import '../../../icons/vk';
    import '../../../icons/facebook';

    export default {
        components: {
        },
        data() {
            return {
                show: false,
            }
        }
    }
</script>
<style lang="scss" scoped>
  .vk {
    position: relative;
    width: 100%;
    height: 40px;
    padding-left: 55px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    background: #4C6C91;
    border-radius: 4px;
    border: none;
    overflow: hidden;
    font-size: 14px;
    color: #ffffff;
    text-align: left;
  }

  .buttonIconVk {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    display: block;
    line-height: 38px;
    background: #3F5F84;
    text-align: center;
  }

  .vkIcon {
    fill: #ffffff;
  }

  .facebook {
    position: relative;
    margin-top: 8px;
    width: 100%;
    height: 40px;
    padding-left: 55px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    background: #3B5998;
    border-radius: 4px;
    border: none;
    overflow: hidden;
    font-size: 14px;
    color: #ffffff;
    text-align: left;
  }

  .buttonIconFacebook {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    display: block;
    line-height: 38px;
    background: #314F8E;
    text-align: center;
  }

  .facebookIcon {
    fill: #ffffff;
  }
</style>