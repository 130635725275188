import axios from "axios/index";
export const state = () => ({
  order: {}
});

export const mutations = {
  setOrder(state, data) {
    state.order = data;
  },
  setOrderStatus(state, data){
    state.order.status = data;
  }
};
export const getters = {
  getOrder: (state) => {
    return state.order;
  }
};
export const actions = {
};
