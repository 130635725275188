<template>
  <ol class="breadcrumbs">
    <li class="breadcrumbs__item">
      <nuxt-link
        to="/"
        class="breadcrumbs__link">
        Главная
      </nuxt-link>
    </li>
    <li
      v-for="(item, index) in breadcrumbs"
      :key="index"
      class="breadcrumbs__item">
      <nuxt-link
        v-if="index !== breadcrumbs.length - 1"
        :to="item.link"
        class="breadcrumbs__link"
        active-class="active">
        {{ item.title }}
      </nuxt-link>
      <span v-else>
        {{ item.title }}
      </span>
    </li>
  </ol>
</template>

<script>
  import {mapState} from 'vuex';
  export default {
    computed: mapState({
      breadcrumbs: state => state.breadcrumbs.breadcrumbs,
    }),
    created: function () {
      //console.log(this.breadcrumbs);
    }
  }
</script>

<style lang="scss" scoped>

  .breadcrumbs{
    margin-top: 3px;
    margin-bottom: 11px;
    padding: 0 16px;
    list-style: none;
    font-size: 12px;
    font-weight: 500;
  }
  .breadcrumbs__item{
    position: relative;
    display: inline-block;
    padding-right: 23px;
    &:after{
      position: absolute;
      content: "";
      right: 8px;
      top: 50%;
      margin-top: -4px;
      width: 6px;
      height: 9px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNSA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSA3TDQgNEwxIDEiIHN0cm9rZT0iIzA5OEFGRiIvPgo8L3N2Zz4K');
      background-repeat: no-repeat;
      background-size: 100%;
    }
    &:last-child{
      padding-right: 0;
      &:after{
        display: none;
      }
    }
  }
  .breadcrumbs__link{
    color: #6E7279;
  }
</style>