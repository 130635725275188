<template>
  <div
    id="dialogsBarScroll"
    v-bar
    class="scroll-wrap noScrollDragger"
    :style="{top: isSubscribed ? &quot;64px&quot; : 62 + pushBannerHeight + &quot;px&quot;}">
    <div
      v-if="chatList.length == 0"
      class="no-dialogs">
      <svgicon
        name="chat-full-inside"
        width="120"
        height="96"
        class="icon-no-dialogs"/>
      <p><strong>Диалогов нет</strong></p>
      <p><span>Вы еще ни с кем не начали <br>переписку</span></p>
    </div>
    <div
      v-else
      class="list">
      <div
        v-for="(item, index) in chatList"
        :key="index"
        class="item"
        @click="openChat(item.chatId)">
        <div class="avatar">
          <span
            v-if="item.unreadCounter > 0"
            class="counter">
            <template v-if="item.unreadCounter < 100">
              {{ item.unreadCounter }}
            </template>
            <template v-else>
              ...{{ item.unreadCounter }}
            </template>
          </span>
          <span :class="item.online ? 'online active' : 'online'"/>
          <img
            v-if="item.picture != '' && item.picture != ', '"
            :src="item.picture"
            alt="">
          <div
            v-else
            class="no-avatar">
            <span>{{ makeTextAvatar(item.title) }}</span>
          </div>
        </div>
        <div class="content">
          <div class="head">
            <div
              v-if="item.length > 16"
              v-b-tooltip.hover
              class="name"
              :title="item.title">
              {{ item.title }}
            </div>
            <div
              v-else
              class="name">
              {{ item.title }}
            </div>
            <div class="time">
              {{ dateFormat(item.lastMessageTime) }}
            </div>
          </div>
          <div class="message">
            {{ item.lastMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import '../../icons/chat-full';
  import {mapGetters} from 'vuex';
  import {dateFormatChat} from '~/services/chat'
  import '../../icons/chat-full-inside';
  export default {
    props: {
      isSubscribed: {
        type: Boolean,
        default: false
      },
      pushBannerHeight: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        dialogsHeight: 0
      }
    },
    computed: {
      ...mapGetters({
        chatList: 'chat/getChatList',
        scrollDialogsTrigger: 'chat/scrollDialogsTrigger',
        user: 'auth/getUser'
      })
    },
    watch: {
      scrollDialogsTrigger: function () {
        setTimeout(()=>{
          if(document.getElementById('dialogsBarScroll').getElementsByClassName('list')[0]){
            this.dialogsHeight = document.getElementById('dialogsBarScroll').getElementsByClassName('list')[0].scrollHeight;
          }
        }, 0);
      },
    },
    mounted: function () {
      if(this.chatFull){
        this.loading = false;
        this.loadingFull = false;
      }
      this.$nextTick(()=>{
        if(document.getElementById('dialogsBarScroll') && document.getElementById('dialogsBarScroll').getElementsByClassName('list')[0]){
          let scrollWrapper = document.getElementById('dialogsBarScroll').getElementsByClassName('vb-content')[0];
          this.dialogsHeight = document.getElementById('dialogsBarScroll').getElementsByClassName('list')[0].scrollHeight;
          scrollWrapper.addEventListener('scroll',() => {
            if((scrollWrapper.scrollTop + scrollWrapper.offsetHeight) == this.dialogsHeight){
              this.uploadMoreDialogs();
            }
          });
        }
      })
    },
    methods: {
      subscribeNotifications(){
        window.Pushwoosh.subscribe();
      },
      openChat: function(chatId){
        this.$store.dispatch('chat/GET_CHAT', chatId)
          .then((response) =>{
            this.$store.dispatch('chat/OPEN_CHAT');
          });
      },
      dateFormat: function(data){
        return dateFormatChat(data);
      },
      uploadMoreDialogs(){
        this.$store.dispatch('chat/GET_MORE_DIALOGS');
      },
      makeTextAvatar: function(title){
          let textAvatar = '';
          title.split(' ').forEach(function (value, index, array) {
              textAvatar += value.charAt(0);
              if(array.length - 1 != index) {
                  textAvatar += '/'
              }
          });
          return textAvatar;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .scroll-wrap{
    position: absolute !important;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .header{
    position: relative;
    padding-top: 20px;
    padding-bottom: 19px;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid $sidebar-separator;
  }
  .header-icon{
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -8px;
    fill: rgba(#ffffff, .3)
  }

  .item{
    position: relative;
    min-height: 80px;
    padding-top: 18px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 14px;
    border-bottom: 1px solid $sidebar-separator;
    cursor: pointer;
    transition: $link-transition;
    &:hover{
      background: rgba(#181A28, .5);
    }
  }
  .avatar{
    position: absolute;
    top: 16px;
    left: 16px;
    width: 48px;
    height: 48px;
    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    + .content{
      padding-left: 64px;
    }
  }

  .head{
    position: relative;
    padding-right: 60px;
  }
  .time{
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 12px;
    color: #717A89;
  }

  .name{
    color: $side-bar-user-color;
    font-size: 14px;
    font-weight: 500;
    max-width:130px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }

  .message{
    margin-top: 2px;
    font-size: 14px;
    font-weight: 500;
    color: #D0D4E0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .counter{
    position: absolute;
    top: -6px;
    right: -4px;
    padding: 1px 5px 0;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    background: $red;
    line-height: 15px;
    border-radius: 24px;
    border: 4px solid $side-bar-color;
  }
  .no-avatar{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #ffffff;
    text-align: center;
    font-weight: 600;
    line-height: 48px;
    text-transform: uppercase;
    font-size: 20px;
  }
  .online{
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 16px;
    height: 16px;
    border: 4px solid $side-bar-color;
    background: $green;
    border-radius: 50%;
    opacity: 0;
    transition: $link-transition;
    &.active{
      opacity: 1;
    }
  }
  .no-dialogs{
    padding-top: 20%;
    color: #8A8B92;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    p{
      margin-bottom: 6px;
    }
    strong{
      font-size: 14px;
      font-weight: 500;
      color: #FDFDFD;
    }
  }
  .icon-no-dialogs{
    margin-bottom: 20px;
    fill: #181A28;
  }
</style>