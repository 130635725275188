<template>
  <div
    :key="componentId"
    :class="edit ? 'rating-stars rating-stars-edit' : 'rating-stars'">
    <input
      :id="componentId + '-5-stars'"
      v-model="ratingValue"
      :disabled="!edit"
      :name="'ratingStar' + componentId"
      value="5"
      type="radio">
    <label
      :for="componentId + '-5-stars'"
      class="star">
      <svgicon
        :width="width"
        :height="width"
        name="star"
        class="rating-star"/>
    </label>

    <input
      :id="componentId + '-4-stars'"
      v-model="ratingValue"
      :disabled="!edit"
      :name="'ratingStar' + componentId"
      value="4"
      type="radio">
    <label
      :for="componentId + '-4-stars'"
      class="star">
      <svgicon
        :width="width"
        :height="width"
        name="star"
        class="rating-star"/>
    </label>

    <input
      :id="componentId + '-3-stars'"
      v-model="ratingValue"
      :disabled="!edit"
      :name="'ratingStar' + componentId"
      value="3"
      type="radio">
    <label
      :for="componentId + '-3-stars'"
      class="star">
      <svgicon
        :width="width"
        :height="width"
        name="star"
        class="rating-star"/>
    </label>

    <input
      :id="componentId + '-2-stars'"
      v-model="ratingValue"
      :disabled="!edit"
      :name="'ratingStar' + componentId"
      value="2"
      type="radio">
    <label
      :for="componentId + '-2-stars'"
      class="star">
      <svgicon
        :width="width"
        :height="width"
        name="star"
        class="rating-star"/>
    </label>

    <input
      :id="componentId + '-1-stars'"
      v-model="ratingValue"
      :disabled="!edit"
      :name="'ratingStar' + componentId"
      value="1"
      type="radio">
    <label
      :for="componentId + '-1-stars'"
      class="star">
      <svgicon
        :width="width"
        :height="width"
        name="star"
        class="rating-star"/>
    </label>
  </div>
</template>

<script>
  import '~/icons/star';

  export default {
    props: {
      rating: {
        type: Number,
        default: null
      },
      changeRating: {
        type: Function,
        default: () => {},
      },
      edit: {
        type: Boolean,
        default: true
      },
      width: {
        type: String,
        default: '24'
      }
    },
    data(){
      return {
        ratingValue: this.rating,
        componentId: null,
      }
    },
    watch: {
      ratingValue: function () {
        this.changeRating(this.ratingValue);
      }
    },
    mounted () {
      this.componentId = this._uid
    },
  }
</script>

<style lang="scss" scoped>
  .rating-stars{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    input{
      display: none;
    }
    :checked ~ label .rating-star{
      fill: $yellow;
    }
  }
  .rating-stars-edit{
    :hover ~ label .rating-star{
      fill: $yellow;
    }
    .rating-star{
      cursor: pointer;
    }
  }
  .rating-star{
    fill: #A8AAAF;
    transition: $link-transition;
  }

</style>