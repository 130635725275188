/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" opacity=".7" d="M13.95 8.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L10 .34A.4.4 0 009.6 0H6.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18L.28 5.39c-.1.18-.06.39.1.51l1.69 1.32C2.03 7.47 2 7.74 2 8s.02.53.06.78L.37 10.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM8 11c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z" _fill="#A8AAAF"/>'
  }
})
