<template>
  <nav class="nav">
    <ul class="nav-list">
      <li class="nav-item">
        <a
          class="nav-link"
          href="#"
          @click="discord($event)">
          Поддержка
        </a>
      </li>
      <li class="nav-item">
        <nuxt-link
          :to="$i18n.path('seller-landing')"
          class="nav-link">
          Стать продавцом
        </nuxt-link>
      </li>
      <li class="nav-item">
        <nuxt-link
          :to="$i18n.path('seller-rules')"
          class="nav-link">
          Правила для продавцов
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<script>
  export default {
    methods: {
      discord: function (event) {
        this.$root.$emit("loginDiscord", event);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .nav{
    text-align: right;
  }
  .nav-list{
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
  }
  .nav-item{
    display: inline-block;
    + .nav-item{
      margin-left: 33px;
    }
  }
  .nav-link{
    padding: 0;
    font-size: 14px;
    color: #ffffff;
    &:hover{
      color: $green
    }
  }
</style>
