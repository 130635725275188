/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ok': {
    width: 24,
    height: 18,
    viewBox: '0 0 24 18',
    data: '<path pid="0" d="M8 14.586L22.293.293l1.414 1.414L8 17.414.293 9.707l1.414-1.414z" _fill="#000" fill-rule="nonzero"/>'
  }
})
