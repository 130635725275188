export const state = () => ({
  searchEntryPoint: null
});
export const getters = {
  getSearchEntryPoint: (state) => {
    return state.searchEntryPoint
  }
};
export const mutations = {
  setSearchEntryPoint(state, entryPoint){
    state.searchEntryPoint = entryPoint
  }
};