<template class="confirmation-container">
  <b-modal
    :id="'modalConfirmation' + unique"
    :ref="'modalConfirmation' + unique"
    centered
    class="modalConfirmation">
    <div
      slot="modal-header"
      class="confirmation-header">
      <span>Подтвердить действие?</span>
      <svgicon
        name="close"
        width="14"
        height="14"
        class="closeModal"
        @click="hideModal"/>
    </div>
    <template>
      <div class="confirmation-body">
        <div class="row">
          <div class="col-md-2">
            <svgicon
              width="46"
              height="54"
              name="warning"/>
          </div>
          <div class="col-md-10">
            <p>
              Вы уверены что хотите подтвердить это действие?
            </p>
            <p>
              {{ text }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <div
      slot="modal-footer"
      class="confirmation-footer">
      <div class="row">
        <div class="col-md-2"/>
        <div class="col-md-4">
          <a
            class="btn btn-success"
            @click="$emit('callback'); hideModal();">
            Да, подтверждаю
          </a>
        </div>
        <div class="col-md-4">
          <a
            class="btn"
            @click="hideModal()">
            Нет, я передумал
          </a>
        </div>
        <div class="col-md-2"/>
      </div>
    </div>
  </b-modal>
</template>
<script>
import "~/icons/close";
import "~/icons/warning";

export default {
  props: {
    text: {
      type: String,
      default: ""
    },
    unique: {
      type: String,
      default: ""
    },
  },
  methods: {
    hideModal() {
      this.$refs['modalConfirmation'+this.unique].hide();
    }
  }
};
</script>
<style lang="scss" scoped>
.confirmation-body {
  margin: 25px 32px 25px 32px;
  text-align: left;
  color: #2d303a;
  &.hide {
    display: none;
  }
}

.confirmation-header {
  width: 100%;
  height: 100%;
  margin: 0;
  span {
    font-size: 20px;
    line-height: 26px;
  }
}

.confirmation-footer {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;

  a {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #6e7279;
  }
  .btn-success {
    color: white;
  }
  .row {
      width: 100%;
  }
}
</style>
