<template>
  <b-modal
    id="modalConfirmPhone"
    ref="modalConfirmPhone"
    v-model="show"
    centered
    class="modalConfirmPhone">
    <div
      slot="modal-header"
      :header-border-variant="0"
      class="modalHeader">
      <svgicon
        name="close"
        width="14"
        height="14"
        class="closeModal"
        @click="hideModal"/>
      <img
        src="/img/logoBlack.svg"
        alt="">
    </div>

    <template
      v-if="codeComplete == false">
      <div
        :class="phoneSubmit == false ? 'content' : 'content hide'">
        <div class="icon-phone"/>
        <h2>Подтвердите Ваш номер телефона</h2>
        <p>
          Мы отправим звонок на ваш номер, 
          вам нужно будет отправить нам последние 4 цирфы номера, 
          с которого поступит звонок
        </p>

        <b-form
          class="form-confirm"
          @submit.prevent="submitPhone">
          <b-form-group
            class="modalFormGroup">
            <div class="modalErrors">
              <div
                v-for="(error, index) in errors"
                :key="index">
                {{ error.message }}
              </div>
            </div>
            <div class="form-confirm-phone">
              <the-mask
                v-model="phone"
                :placeholder="'Введите номер телефона'"
                :mask="mask"
                class="form-input form-control"/>
            </div>
          </b-form-group>

          <div
            id="captcha"/>

          <button
            :disabled="phoneSubmitLoading"
            type="submit"
            class="btn btn-success btn-submit">
            Позвонить
          </button>
        </b-form>
      </div>

      <div
        :class="phoneSubmit != false ? 'content' : 'content hide'">
        <h2>Код подтверждения</h2>
        <p>Введите последние 4 цифры номера, с которого вам звонили</p>

        <b-form
          class="form-code"
          @submit.prevent="submitCode">
          <b-form-group
            class="modalFormGroup">
            <div class="modalErrors">
              <div
                v-for="(error, index) in errors"
                :key="index">
                {{ error.message }}
              </div>
            </div>
            <b-form-input
              v-model="code"
              class="form-input"/>
          </b-form-group>

          <button
            :disabled="submitCodeLoading"
            type="submit"
            class="btn btn-success btn-submit">
            Проверить код
          </button>
        </b-form>

        <p v-if="timeBlock != 0">
          Выслать код повторно можно через {{ timeBlock }} сек.
        </p>
        <p v-else>
          <span
            class="repeat-send-code"
            @click="repeatSendCode">
            Повторить звонок
          </span>
        </p>
      </div>
    </template>
    <template v-else>
      <div class="content">
        <div class="modalIconOk"/>

        <h2>Номер подтвержден</h2>

        <p>
          Ваш номер успешно подтвержден. Спасибо!
        </p>
      </div>
    </template>

    <div
      slot="modal-footer"
      class="modalFooter modalFooterConfirmPhone">
      <div
        v-if="phoneSubmit == false">
        <div class="steps">
          <div class="step">
            <strong>Шаг 1</strong>
            Вводите свой номер
          </div>
          <div class="step">
            <strong>Шаг 2</strong>
            Вам звонит наш бот
          </div>
          <div class="step">
            <strong>Шаг 3</strong>
            Вы вводите код - последние 4 цифры номера
          </div>
        </div>
      </div>
      <div v-else>
        <span
          class="link-back"
          @click="hideModal">
          Вернуться назад
        </span>
      </div>
    </div>
  </b-modal>
</template>
<script>

  import {TheMask} from 'vue-the-mask';
  import '~/icons/close';
  import '~/icons/dialogs';
  import axios from 'axios';
  import { errorResponse } from '~/services/errors-template';

  const reCAPTCHA_site_key = process.env.reCAPTCHA_site_key;
  export default {
    components: {
      TheMask
    },
    props: {
      redirectToRules: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        phone: '',
        phoneSubmit: false,
        phoneSubmitLoading: false,
        code: '',
        codeComplete: false,
        submitCodeLoading: false,
        errors: [],
        timeBlock: 60,
        show: false,
        mask: "+############"
      }
    },
    watch: {
      show: function () {
        if(this.show){
          grecaptcha.render("captcha", {sitekey: reCAPTCHA_site_key});
        }
      }
    },
    methods: {
      hideModal () {
        this.$refs.modalConfirmPhone.hide()
      },
      submitPhone: function(){
        this.phoneSubmitLoading = true;
        return axios.post('/auth/set-phone', {phone: '+' + this.phone})
          .then(response => {
            this.submitCodeLoading = false;
            if(response.data.success){
              this.submitCaptcha(grecaptcha.getResponse());
              this.errors = [];
            }
            else{
              this.errors = response.data.data;
              grecaptcha.reset();
            }
          })
          .catch((exception) => {
            this.phoneSubmitLoading = false;
            console.log(exception.response);
            this.errors = exception.response.data.data;
          });
      },
      submitCaptcha: function(data){
        this.countDown();
        return axios.post('/auth/request-validate-phone', {'g-recaptcha-response': data})
          .then(response => {
            if(response.data.success){
              this.phoneSubmit = true;
              this.errors = [];
              this.$yandexMetrika.reachGoal('seller_request-phone-code');
            }
            else{
              this.errors = response.data.data;
              grecaptcha.reset();
            }
            this.phoneSubmitLoading = false;
          })
          .catch((exception) => {
            this.errors = exception.response.data.data;
            this.phoneSubmitLoading = false;
          });
      },
      submitCode: function(){
        this.submitCodeLoading = true;
        return axios.post('/auth/validate-phone', {'code': this.code})
          .then(response => {
            if(response.data.success){
              this.$store.dispatch('auth/SET_PHONE', {
                phone: this.phone,
                isPhoneVerified: 1
              });
              this.$yandexMetrika.reachGoal('seller_phone-verified');
              this.errors = [];
              this.codeComplete = true;
              if (this.redirectToRules) {
                setTimeout(() => {
                  this.$router.push({
                    path: `/profile/offers/rules`
                  });
                }, 1000)
              }
            }
            else{
              this.errors = response.data.data;
              this.submitCodeLoading = false;
            }
          })
          .catch((exception) => {
            this.errors = exception.response.data.data;
            this.submitCodeLoading = false;
          });
      },
      countDown: function(){
        let timer = setInterval(() => {
          this.timeBlock--;
          if(this.timeBlock == 0) clearInterval(timer);
        }, 1000);
      },
      repeatSendCode: function(){
        this.phoneSubmit = false;
        this.timeBlock = 60;
        grecaptcha.reset();
      }
    }
  }
</script>
<style lang="scss" scoped>
  .g-recaptcha{
    opacity: 1;
    transition: $link-transition;
    &.hidden{
      opacity: 0;
    }
  }
  .icon-phone{
    display: inline-block;
    margin-bottom: 20px;
    width: 64px;
    height: 64px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAyMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjMzMzcgMC4zMzMwMDhINC42NjY5OUMyLjQ1MzY2IDAuMzMzMDA4IDAuNjY2OTkyIDIuMTE5NjcgMC42NjY5OTIgNC4zMzMwMVYyNS42NjYzQzAuNjY2OTkyIDI3Ljg3OTcgMi40NTM2NiAyOS42NjYzIDQuNjY2OTkgMjkuNjY2M0gxNS4zMzM3QzE3LjU0NyAyOS42NjYzIDE5LjMzMzcgMjcuODc5NyAxOS4zMzM3IDI1LjY2NjNWNC4zMzMwMUMxOS4zMzM3IDIuMTE5NjcgMTcuNTQ3IDAuMzMzMDA4IDE1LjMzMzcgMC4zMzMwMDhaTTEyLjY2NyAyNi45OTk3SDcuMzMzNjZWMjUuNjY2M0gxMi42NjdWMjYuOTk5N1pNMTcuMDAwMyAyMi45OTk3SDMuMDAwMzNWNC4zMzMwMUgxNy4wMDAzVjIyLjk5OTdaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
    background-color: $green;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
  }
  .content{
    padding: 15px 32px 32px;
    text-align: center;
    color: #6E7279;
    h2{
      font-size: 18px;
      font-weight: 400;
      color: $body-color;
    }
    &.hide{
      display: none;
    }
  }

  .form-confirm{
    margin-top: 30px;
    width: 302px;
    max-width: 100%;
  }

  .btn-submit{
    margin-top: 20px;
    width: 100%;
  }

  .steps{
    display: flex;
    padding: 0 18px;
    font-size: 12px;
    color: #6E7279;
    text-align: left;
    strong{
      display: block;
      font-weight: 600;
      color: #A8AAAF;
    }
  }
  .modalFooterConfirmPhone{
    margin-top: 0;
  }

  .modalErrors{
    margin-bottom: 3px;
    color: $red;
    font-size: 12px;
  }
  .form-code{
    margin-top: 25px;
    margin-bottom: 20px;
    width: 192px;
    display: inline-block;
    .btn-submit{
      margin-top: 0;
    }
  }
  .repeat-send-code, .link-back{
    color: $blue;
    cursor: pointer;
  }
  .modalIconOk{
    margin: 15px auto 25px;
    width: 64px;
    height: 64px;
    text-align: center;
    line-height: 64px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzMiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAzMyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNSAxMUwxMS41IDIxTDMxLjUgMSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=');
    background-position: center;
    background-repeat: no-repeat;
    background-color: $green;
    border-radius: 50%;
  }
  .form-confirm-phone{
    position: relative;
  }
  .form-confirm-select{
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
  }
</style>
