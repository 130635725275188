
export const state = () => ({
  locales: ['ru', 'en'],
  locale: 'ru'
})

export const mutations = {
  setLang(state, locale) {
    if (state.locales.indexOf(locale) !== -1) {
      state.locale = locale
    }
  }
};
export const getters = {
  getLang: (state) => {
    return state.locale;
  }
};
export const actions = {
  SET_LANG({commit}, lang){
    commit('setLang', lang);
    this.app.i18n.locale = lang;
    this.$router.push(321)
  }
}