/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'purse': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<path pid="0" d="M18 15v1c0 1.1-.9 2-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h14c1.1 0 2 .9 2 2v1H9a2 2 0 00-2 2v8a2 2 0 002 2h9zm-9-2h10V5H9v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" _fill="#6E7279"/>'
  }
})
