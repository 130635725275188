<template>
  <div>
    <div>
      <div :class="showHeader ? 'headerMain active' : 'headerMain'">
        <div class="container">
          <div class="row">
            <div class="col-md-7">
              <img
                class="landing-logo"
                src="/img/logo-main.svg">
            </div>
            <div
              class="col-md-5 d-flex justify-content-space-between align-items-center">
              <nuxt-link
                class="discord-link"
                to="/">
                Присоединится в Discord
              </nuxt-link>
              <b-button
                v-scroll-to="{ el: '.slide-5' }"
                class="btn btn-success">
                Стать продавцом
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="page">
        <nuxt/>
      </div>
    </div>
    <audio
      id="newMessageSound"
      volume="1"
      src="/sounds/message.wav?v=GB-141"/>
    <confirmation
      :text="'Вы переходите на сторонний сайт, который никак не связан с GOBUFFY. Сделки проведенные вне нашего сайта никак не защищены.'"
      unique="OutLink"
      @callback="goToLink()"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import confirmation from "~/components/modals/confirmation.vue";
export default {
  middleware: ["breadcrumbs"],
  components: {
    confirmation,
  },
  data() {
    return {
      showHeader: false,
    };
  },
  computed: mapState({
    user: (state) => state.auth.user,
    sideBar: (state) => state.sidebar.sideBar,
  }),
  beforeMount() {
    if (process.browser) {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 200) {
          this.showHeader = true;
        } else {
          this.showHeader = false;
        }
      });
    }
  },
  methods: {
    showRegistrationCode: function () {
      console.log(32131);
      this.$root.$emit("bv::show::modal", "modalRegistrationCode");
      //this.$refs.modalRegistrationCode.show();
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  padding-top: 0;
  z-index: 1;
}

.headerMain {
  display: none;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: transparent;
  z-index: 0;
  &.active {
    display: block;
    position: fixed;
    top: 0;
  }
  .btn-success {
    width: 153px;
    height: 37px;
    background: #27bc00;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    z-index: 0;
    background-color: transparent;
  }

  .discord-link {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */

    color: #718aae;
  }
}
</style>
