/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitch': {
    width: 28,
    height: 29,
    viewBox: '0 0 28 29',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.331.2L.453 5.21v20.033h6.884V29h3.758l3.754-3.758h5.634l7.512-7.51V.2H2.331zm2.502 2.503h20.659v13.774l-4.383 4.384h-6.885l-3.753 3.753V20.86H4.833V2.703z" _fill="#292E3B"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M11.72 15.226h2.504V7.714H11.72v7.512zm6.885 0h2.504V7.714h-2.504v7.512z" _fill="#292E3B"/>'
  }
})
