import { findInTree } from "~/services/category";
import { parseBool } from "~/services/booleanParser";
export const state = () => ({
  categoriesTree: {
    children: null
  },
  activeCategory: null,
  activeCategoryId: null,
  calculateCategoryAttrs: [],
  calculateCategoryOffers: {},
  calculateCategoryId: null,
  calculateCategoryName: null,
  offers: null
});
export const getters = {
  getCategoriesTree(state) {
    return state.categoriesTree
  },
  getCategoryId(state) {
    return state.activeCategoryId
  },
  getActiveCategory(state) {
    return state.activeCategory
  },
  getCalculateCategoryAttrs(state) {
    return state.calculateCategoryAttrs
  },
  getCalculateCategoryOffers(state) {
    return state.calculateCategoryOffers
  },
  getCalculateCategoryId(state) {
    return state.calculateCategoryId
  },
  getCalculateCategoryName(state) {
    return state.calculateCategoryName
  },
  getOffers(state) {
    return state.offers;
  },
};
export const mutations = {
  setCalculateCategoryAttrs(state, data) {
    state.calculateCategoryAttrs = data.attrs;
    state.calculateCategoryId = data.categoryId;
    state.calculateCategoryName = data.categoryName;
    state.calculateCategoryOffers = data.offers;
  },
  clearCalculateCategoryAttrs(state) {
    state.calculateCategoryAttrs = [];
    state.calculateCategoryId = null;
    state.calculateCategoryName = null;
    state.calculateCategoryOffers = {};
  },
  setActiveCategoryAttrs(state, data) {
    if (!data.id) {
      return;
    }
    if (!state.activeCategory) {
      return;
    }
    if (state.categoriesTree.children) {
      state.categoriesTree.children.forEach(function (item, index) {
        if (data.attrs) {
          item.attrs = null;
        }
        if (data.seo) {
          item.seo = null;
        }

      });
    }

    let activeCategory = findInTree(
      state.categoriesTree,
      "id",
      data.id
    );

    if (data.attrs) {
      if (activeCategory) {
        activeCategory.attrs = data.attrs;
      }

      state.activeCategory.attrs = data.attrs;
    }

    if (data.seo) {
      if (activeCategory) {
        activeCategory.seo = data.seo;
      }

      state.activeCategory.seo = data.seo;
    }
  },
  setActiveCategoryCalculateAttrs(state, data) {
    if (!state.activeCategory) {
      return;
    }
    if (state.categoriesTree.children) {
      state.categoriesTree.children.forEach(function (item, index) {
        item.calculateAttrs = null;
      });

      let activeCategory = findInTree(
        state.categoriesTree,
        "id",
        data.id
      );
      activeCategory.calculateAttrs = data.attrs;
      state.activeCategory.calculateAttrs = data.attrs;
    }

  },
  setActiveCategoryAttributeFilters(state, data) {
    if (!state.activeCategory || !state.activeCategory.attrs) {
      return;
    }

    let attributesValues = {};
    let attribute = state.activeCategory.attrs.filter(item => item.id == data.id)[0];
    if (!attribute)
    {
      return;
    }
    if (
      (attribute.affects_price == 1 ||
        attribute.affects_time == 1) &&
      !data.filters.nothing
    ) {
      if (data.filters.rangeMultiple.from && data.filters.rangeMultiple.to) {
        let value = [
          parseInt(data.filters.rangeMultiple.from),
          parseInt(data.filters.rangeMultiple.to),
        ];
        attributesValues[attribute.id] = value;
      }

      if (data.filters.rangeSingle) {
        let value = [parseInt(data.filters.rangeSingle)];
        attributesValues[attribute.id] = value;
      }

      if (data.filters.multiselect && Object.keys(data.filters.multiselect).length !== 0) {
        let multiSelectValues = [];
        Object.keys(data.filters.multiselect).forEach((item) => {
          if (parseBool(data.filters.multiselect[item]) == true)
          {
            multiSelectValues.push(item);
          }
          
        });
        attributesValues[attribute.id] = multiSelectValues;
      }

      if (data.filters.select) {
        let value = [parseInt(data.filters.select)];
        attributesValues[attribute.id] = value;
      }
      data.filters.attributesValues = attributesValues;
    }

    if (state.activeCategory.attrs.filter(item => item.id == data.id)[0]) {
      state.activeCategory.attrs.filter(item => item.id == data.id)[0].filters = data.filters;
    }
  },
  setCategoriesTree(state, data) {
    state.categoriesTree.children = data;
  },
  resetCategoriesTree(state) {
    state.categoriesTree.children = null;
  },
  setActiveCategoryId(state, data) {
    state.activeCategoryId = data;
  },
  setActiveCategory(state, data) {
    state.activeCategory = data;
  },
  setOffers(state, data) {
    state.offers = data;
  },
  setOffersItems(state, data) {
    state.offers.items = data;
  },
  setOffersMeta(state, data) {
    state.offers.meta = data;
  },
  setCalculateReady(state, data) {
    if (state.offers.items && state.offers.items[data.index]) {
      state.offers.items[data.index].calculatedTime = data.time;
      state.offers.items[data.index].calculatedPrice = data.price;
      state.offers.items[data.index].calculateStatus = true;
      state.offers.items[data.index].attrs = data.attrs;
    }
  },
  setOffersItemAttr(state, data) {
    if (state.offers.items && state.offers.items[data.index]) {
      state.offers.items[data.index].attrs = data.attrs;
    }
  }
};
export const actions = {
  SET_CATEGORIES_TREE({ commit }, data) {
    commit('setCategoriesTree', data);
  },
  RESET_CATEGORIES_TREE({ commit }, data) {
    commit('resetCategoriesTree', data);
  },
  RESET_CALCULATE_ATTRIBUTES({ commit }) {
    commit('clearCalculateCategoryAttrs');
  },
  SET_ACTIVECATEGORY_ATTRIBUTES({ commit }, data) {
    commit('setActiveCategoryAttrs', data);
  },
  SET_ACTIVECATEGORY_CALCULATE_ATTRIBUTES({ commit }, data) {
    commit('setActiveCategoryCalculateAttrs', data);
  },
  SET_CALCULATE_CATEGORY_ATTRS({ commit }, data) {
    commit("setCalculateCategoryAttrs", data);
  },
  SET_OFFERS({ commit }, data) {
    commit('setOffers', data);
  },
  SET_OFFERS_ITEMS({ commit }, data) {
    commit('setOffersItems', data);
  },
  SET_OFFERS_META({ commit }, data) {
    commit('setOffersMeta', data);
  },
  CALCULATE_READY({ commit }, data) {
    commit('setCalculateReady', data);
  },
  SET_OFFERS_ITEM_ATTR({ commit }, data) {
    commit('setOffersItemAttr', data);
  },
  SET_ACTIVE_CATEGORY({ commit }, data) {
    commit('setActiveCategory', data);
  },
  SET_ACTIVE_CATEGORY_ATTRIBUTE_FILTERS({ commit }, data) {
    commit('setActiveCategoryAttributeFilters', data);
  }
}