import md5 from 'js-md5';
import slugify from 'slugify';

export function changeFavicon(trigger) {
  console.log('change favicon');
  let favicon16 = document.getElementById('favicon16');
  let favicon32 = document.getElementById('favicon32');
  let favicon64 = document.getElementById('favicon64');
  let favicon128 = document.getElementById('favicon128');
  if (trigger) {
    favicon16.setAttribute('href', '/img/favicon-16-active.png');
    favicon32.setAttribute('href', '/img/favicon-32-active.png');
    favicon64.setAttribute('href', '/img/favicon-64-active.png');
    favicon128.setAttribute('href', '/img/favicon-128-active.png');
  }
  else {
    favicon16.setAttribute('href', '/img/favicon-16.png');
    favicon32.setAttribute('href', '/img/favicon-32.png');
    favicon64.setAttribute('href', '/img/favicon-64.png');
    favicon128.setAttribute('href', '/img/favicon-128.png');
  }

}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function getPhoto(userId, photo_uploaded_at) {
  if (photo_uploaded_at == null) {
    return '';
  }
  let folder = Math.floor(userId / 1000) > 0 ? Math.floor(userId / 1000) * 1000 : 1;
  let file = '/img/user-photo/' + folder + '/' + md5(userId.toString()) + '.jpg';
  return file + '?v=' + photo_uploaded_at;
}

export function getDate(created_at) {
  if (created_at == null || created_at == undefined || created_at == '') return '???';
  let newDate;
  let date = new Date(created_at * 1000);
  newDate
    = (date.getDate() > 9 ? date.getDate() : '0' + date.getDate())
    + '.'
    + (date.getMonth() + 1 > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1))
    + '.'
    + date.getFullYear();
  return newDate;
}

export function getDateTime(created_at, showSeconds = true) {
  if (created_at == null || created_at == undefined || created_at == '') return '???';
  let newDate;
  let date = new Date(created_at * 1000);
  newDate
    = (date.getDate() > 9 ? date.getDate() : '0' + date.getDate())
    + '.'
    + (date.getMonth() + 1 > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1))
    + '.'
    + date.getFullYear()
    + ' '
    + (date.getHours() < 10 ? ('0' + date.getHours().toString()) : date.getHours().toString())
    + ':'
    + (date.getMinutes() < 10 ? ('0' + date.getMinutes().toString()) : date.getMinutes().toString())
    + ':'
    + (showSeconds ? (date.getSeconds() < 10 ? ('0' + date.getSeconds().toString()) : date.getSeconds().toString()) : '');
  return newDate;
}


export function numToString(n, text) {
  n = Math.abs(n) % 100;
  let n1 = n % 10;
  if (n > 10 && n < 20) { return text[2]; }
  if (n1 > 1 && n1 < 5) { return text[1]; }
  if (n1 == 1) { return text[0]; }
  return text[2];
}

export function convertHex(color, opacity) {
  color = color.replace('#', '');
  let r = parseInt(color.substring(0, 2), 16);
  let g = parseInt(color.substring(2, 4), 16);
  let b = parseInt(color.substring(4, 6), 16)
  
  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
}

export function deserializeQueryParams(query) {

  if (typeof query == "string" && !query.includes('?')) {
    return {};
  }

  query = query.substring(query.indexOf('?') + 1);

  var re = /([^&=]+)=?([^&]*)/g;
  var decodeRE = /\+/g;

  var decode = function (str) {
    return decodeURIComponent(str.replace(decodeRE, " "));
  };

  var params = {},
    e;
  while ((e = re.exec(query))) {
    var k = decode(e[1]),
      v = decode(e[2]);
    if (k.substring(k.length - 2) === "[]") {
      k = k.substring(0, k.length - 2);
      (params[k] || (params[k] = [])).push(v);
    } else params[k] = v;
  }

  var assign = function (obj, keyPath, value) {
    var lastKeyIndex = keyPath.length - 1;
    for (var i = 0; i < lastKeyIndex; ++i) {
      var key = keyPath[i];
      if (!(key in obj)) obj[key] = {};
      obj = obj[key];
    }
    obj[keyPath[lastKeyIndex]] = value;
  };

  for (var prop in params) {
    var structure = prop.split("[");
    if (structure.length > 1) {
      var levels = [];
      structure.forEach(function (item, i) {
        var key = item.replace(/[?[\]\\ ]/g, "");
        levels.push(key);
      });
      assign(params, levels, params[prop]);
      delete params[prop];
    }
  }

  return params;
};