import serializeQueryParams from './serializeQueryParams';

export default ($axios) => ({
  async save(bodyParams) {
    if (bodyParams && bodyParams.id) {
      return await $axios.put(`/articles/${bodyParams.id}`, bodyParams);
    } else {
      return await $axios.post('/articles', bodyParams);
    }
  },
  async findAll(queryParams) {
    let params = queryParams ? '?' + serializeQueryParams(queryParams) : '';

    return await $axios.get('/articles' + params);
  },
  async findOne(articleId, queryParams = null) {
    let params = queryParams ? '?' + serializeQueryParams(queryParams) : '';

    return await $axios.get(`/articles/${articleId}${params}`);
  },
  async games(queryParams) {
    let params = queryParams ? '?' + serializeQueryParams(queryParams) : '';

    return await $axios.get(`/articles/games`, + params);
  },
  async updateStatus(articleId, bodyParams) {
    
    return await $axios.put(`/articles/${articleId}`, bodyParams);
  }
})