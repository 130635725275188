import axios from 'axios';
import {socket} from '~/plugins/socket-io.js'
import {changeFavicon} from '~/services/base.js'

export const state = () => ({
  notifications: {},
  notificationsCount: 0,
  notificationsPage: 1,
  notificationsScrollTrigger: false
});
export const mutations = {
  setNotifications(state, data) {
    if (state.notificationsPage == 1) {
      state.notifications = data;
    }
    else{
      for (let i = 0; i < data.length; i++){
        state.notifications.push(data[i]);
      }
      state.notificationsScrollTrigger = !state.notificationsScrollTrigger
    }
    state.notificationsPage++;
  },
  countNotifications(state, data){
    state.notificationsCount+=data;
  },
  pastNotification(state, data) {
    state.notificationsCount++;
    state.notifications.unshift(data);
  },
  removeNotification(state, id) {
    state.notificationsCount--;
    if(state.notificationsCount < 0){
      state.notificationsCount = 0;
    }
    for(let i = 0; i < state.notifications.length; i++) {
      if(state.notifications[i].id == id) {
        state.notifications[i].is_read = true;
        state.notifications.splice(i, 1);
        break;
      }
    }

  },
  resetNotifications(state){
    state.notifications = {};
    state.notificationsCount = 0;
    state.notificationsPage = 1;
  }
};
export const getters = {
  getNotifications: (state) => {
    return state.notifications;
  },
  getNotificationsCount: (state) =>{
    return state.notificationsCount;
  },
  notificationsScrollTrigger: (state) =>{
    return state.notificationsScrollTrigger;
  }
};
export const actions = {
  INIT_NOTIFICATIONS({commit}){
    socket.on('notification', function (data) {
      console.log(data);
      commit('pastNotification',JSON.parse(data));
      changeFavicon(true);
    });
  },
  GET_NOTIFICATIONS({commit, dispatch, state, rootState}) {
    return axios.get('/users/'+rootState.auth.user.jti+'/notifications', {params: {page: state.notificationsPage}})
      .then(response => {
        if (response.data.success) {
          commit('setNotifications', response.data.data.items);
          commit('countNotifications', response.data.data.items.length);
          if(response.data.data.items.length > 0){
            changeFavicon(true);
          }
        }
        return response.data
      })
      .catch(exception => {
          return exception.response
        }
      )
  },
  READ_NOTIFICATION({commit, dispatch,state, rootState}, id) {
    commit('removeNotification', id);
    if(rootState.chat.allUnreadMessages == 0 && state.notificationsCount == 0){
      changeFavicon(false);
    }
    return axios.post('/notifications/'+id+'/read')
      .then(response => {
        if (response.data.success) {
        }
        return response.data
      })
      .catch(exception => {
          return exception.response
        }
      )
  },
  RESET_NOTIFICATIONS({commit}){
    commit('resetNotifications');
  }
}