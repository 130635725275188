/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'twitter': {
    width: 24,
    height: 20,
    viewBox: '0 0 24 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.281 5.508l.05.83-.839-.101c-3.055-.39-5.724-1.712-7.99-3.932L1.392 1.203l-.285.814C.504 3.83.89 5.745 2.148 7.033c.672.712.521.814-.637.39-.403-.136-.756-.237-.79-.186-.117.118.286 1.66.605 2.27.437.848 1.326 1.678 2.3 2.17l.823.39-.974.017c-.94 0-.974.017-.873.373.336 1.101 1.662 2.27 3.14 2.78l1.04.355-.906.542a9.452 9.452 0 01-4.5 1.255c-.755.016-1.376.084-1.376.135 0 .17 2.048 1.119 3.24 1.491 3.576 1.102 7.823.628 11.013-1.254 2.266-1.339 4.532-4 5.59-6.575.57-1.373 1.141-3.882 1.141-5.085 0-.78.05-.881.99-1.813.555-.543 1.075-1.136 1.176-1.305.168-.322.151-.322-.705-.034-1.427.508-1.628.44-.923-.322.52-.543 1.141-1.526 1.141-1.814 0-.05-.252.034-.537.187-.302.17-.974.423-1.477.576l-.907.288-.823-.56C18.466 1 17.83.662 17.494.56c-.857-.237-2.166-.203-2.938.068-2.099.763-3.425 2.728-3.274 4.88z" _fill="#292E3B"/>'
  }
})
