import axios from "axios";

export const state = () => ({
  allArticlesResponse: null,
  articleResponse: null,
  gameName: '',
  gamesResponse: null,
  topArticlesResponse: null,
  latestArticlesResponse: null,
  isVibrantLoaded: false
});

export const mutations = {
  setAllArticlesResponse(state, payload) {
    state.allArticlesResponse = payload;
  },
  setArticleResponse(state, payload) {
    state.articleResponse = payload;
  },
  setGameName(state, payload) {
    state.gameName = payload;
  },
  setGamesResponse(state, payload) {
    state.gamesResponse = payload;
  },
  setTopArticlesResponse(state, payload) {
    state.topArticlesResponse = payload;
  },
  setLatestArticlesResponse(state, payload) {
    state.latestArticlesResponse = payload;
  },
  setIsVibrantLoaded(state, isLoaded) {
    state.isVibrantLoaded = isLoaded;
  },
  updateArticleStatus(state, article) {
    state.allArticlesResponse.data.items.forEach(element => {
      if (element.id != article.id) return;

      element.status = article.status  
    });
  }
}

export const getters = {
  allArticlesResponse: (state) => {
    return state.allArticlesResponse;
  },
  articleResponse: (state) => {
    return state.articleResponse;
  },
  gameName: (state) => {
    return state.gameName;
  },
  gamesResponse: (state) => {
    return state.gamesResponse;
  },
  articleGames: (state) => {
    if (!state.gamesResponse || !Array.isArray(state.gamesResponse.data)) return [];

    return state.gamesResponse.data.map( item => {
      item.icon_url = 
        process.env.apiURL + '/img/game/' + item.id + '.png?v=' + item.updated_at;

      return item;  
    });
  },
  topArticlesResponse: (state) => {
    return state.topArticlesResponse;
  },
  topArticles: (state) => {
    return state.topArticlesResponse.data.items.map( item => {
      item.coverFile.fullUrl = item.coverFile.is_upload ?
        process.env.apiURL + item.coverFile.url :
        item.coverFile.url;

      return item;
    });
  },
  latestArticlesResponse: (state) => {
    return state.latestArticlesResponse;
  },
  latestArticles: (state) => {
    return state.latestArticlesResponse.data.items.map( item => {
      item.coverFile.fullUrl = item.coverFile.is_upload ?
        process.env.apiURL + item.coverFile.url :
        item.coverFile.url;

      return item;
    })
  },
  isVibrantLoaded: (state) => {
    return state.isVibrantLoaded;
  }
}

export const actions = {
  async GET_TOP_ARTICLES({ commit, dispatch }, payload) {
    let topArticlesResponse = await dispatch('GET_ARTICLES', payload);

    payload.queryData ?
      payload.queryData.sort = '-rank' :
      payload = {...{queryData: {sort: '-rank'}}};

    commit('setTopArticlesResponse', topArticlesResponse);
  },
  async GET_LATEST_ARTICLES({ commit, dispatch }, payload) {
    let latestArticlesResponse = await dispatch('GET_ARTICLES', payload);

    payload.queryData ? 
      payload.queryData.sort = '-created_at' :
      payload = {...{queryData: {sort: '-created_at'}}};

    commit('setLatestArticlesResponse', latestArticlesResponse);
  },
  async GET_ARTICLES({ commit, rootState }, payload) {
    try {
      let articlesResponse = await this.$api.articles.findAll(payload.queryData);
      commit('setAllArticlesResponse', articlesResponse.data);

      return articlesResponse.data;
    } catch (err) {
      if (err.response && err.response.data.data) {
        console.log(err.response);

        return err.response.data;
      } else {
        this.$noty.error('Произошла ошибка');
        this.$sentry.captureException(err);
      }
    }
  },
  async GET_ARTICLE({ commit }, payload) {
    try {
      let articleResponse = await this.$api.articles.findOne(payload.articleId, payload.queryData);

      return articleResponse.data;
    } catch (err) {
      if (err.response && err.response.data.data) {
        console.log(err.response);
        return err.response.data;
      } else {
        this.$noty.error('Произошла ошибка');
        this.$sentry.captureException(err);
      }
    }
  },
  async SAVE_ARTICLE({commit}, payload) {
    let saveResponse;

    try {
      saveResponse = await this.$api.articles.save(payload.article);

      return saveResponse;
    } catch (err) {
      if (err.response && err.response.data) {
        return err.response;
      } else {
        this.$noty.error('Произошла ошибка');
        this.$sentry.captureException(err);
      }
    }
  },
  async GET_GAMES({ commit }, payload) {
    let gamesResponse;

    try {
      gamesResponse = await this.$api.articles.games(payload.queryData);
      
      commit('setGamesResponse', gamesResponse.data);

      return gamesResponse;
    } catch (err) {
      if (err.response && err.response.data) {
        commit('setGamesResponse', err.response);

        return err.response;
      } else {
        this.$noty.error('Произошла ошибка');
        this.$sentry.captureException(err);
      }
    }
  },
  async UPDATE_STATUS( {commit}, payload) {
    let updateResponse;
    
    try {
      updateResponse = await this.$api.articles.updateStatus(payload.articleId, payload.bodyParams);
      
      commit('updateArticleStatus', updateResponse.data.data);

      return updateResponse;
    } catch (err) {
      if (err.response && err.response.data) {

        return err.response;
      }
      console.log(err);
    }
  }
}