/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 13,
    height: 27,
    viewBox: '0 0 13 27',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.502 26.111V13.054h3.604l.478-4.5H8.502l.006-2.251c0-1.174.111-1.803 1.797-1.803h2.253V0H8.953C4.623 0 3.1 2.183 3.1 5.854v2.701H.4v4.5h2.7V26.11h5.402z" _fill="#292E3B"/>'
  }
})
