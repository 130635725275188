export const state = () => ({
  sideBar: false,
  sideBarContent: ''
});

export const mutations = {
  sideBarTrigger(state, data) {
    state.sideBar = data;
  },
  setSideBarContent(state, data){
    state.sideBarContent = data;
  }
};
export const getters = {
  sideBarTrigger: (state) => {
    return state.sideBar;
  }
};
export const actions = {
  SIDEBAR_TRIGGER({commit}, data){
    commit('sideBarTrigger', data);
  },
  SIDEBAR_CONTENT({commit}, data){
    commit('setSideBarContent', data);
  },
}