<template>
  <b-container fluid>
    <b-modal
      id="modalPaymentResult"
      centered>
      <b-row slot="modal-header"/>
      <b-row slot="default">
        <b-col class="payment-wrapper">
          <template v-if="$route.query.paymentResult == 'success'">
            <img
              class="result-img"
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODQiIGhlaWdodD0iODQiIHZpZXdCb3g9IjAgMCA4NCA4NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDIiIGN5PSI0MiIgcj0iNDIiIGZpbGw9IiM0NkJGNEEiLz4KPHBhdGggZD0iTTI4IDQyLjhMMzguNSA1M0w1NiAzNiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg=="><br>
            <span class="result-heading"> Оплата прошла успешно </span>
            <b-button
              v-if="transaction && transaction.type == 6"
              v-show="!loadingTransaction"
              :to="{ path: '/profile/direct-buff' }"
              variant="outline"
              class="btn btn-outline-secondary to-orders">
              К списку рекламы
            </b-button>
            <b-button
              v-else
              v-show="!loadingTransaction"
              :to="{ path: '/profile/purchases' }"
              variant="outline"
              class="btn btn-outline-secondary to-orders">
              К списку покупок
            </b-button>
          </template>
          <template v-if="$route.query.paymentResult == 'fail'">
            <img
              class="result-img"
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODQiIGhlaWdodD0iODQiIHZpZXdCb3g9IjAgMCA4NCA4NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDIiIGN5PSI0MiIgcj0iNDIiIGZpbGw9IiNGRjMzMzAiLz4KPHBhdGggZD0iTTMwIDU0TDU0IDMxTTMwIDMxTDU0IDU0IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K"><br>
            <span class="result-heading"> Оплата не прошла </span>
            <p>
              Попробуйте еще раз через некоторое время<br>
              Или свяжиесь с поддержкой
            </p>
          </template>
        </b-col>
      </b-row>
      <b-row slot="modal-footer"/>
    </b-modal>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  name: "PaymentResult",
  data() {
    return {
      transaction: null,
      loadingTransaction: true
    };
  },
  watch: {
    "$route.query.account": {
      handler: function (id) {
        if (id == undefined || !id) {
          this.transaction = null;
          this.loadingTransaction = false;
          return;
        }
        axios
          .get("/transactions/" + id)
          .then((response) => {
            if (response.data.success) {
              this.transaction = response.data.data;
              this.loadingTransaction = false;
            }
          })
          .catch((exception) => {
            return exception.response;
            this.loadingTransaction = false;
          });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.payment-wrapper {
  text-align: center;
  padding: 45px 0 77px 0;
}
.result-img {
  display: inline-block;
}
.to-orders {
  margin-top: 7px;
  color: $grey-light;
}
.result-heading {
  display: block;
  margin: 30px 0 0 0;
  font-size: 20px;
  line-height: 24px;
}
</style>