/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 17,
    height: 17,
    viewBox: '0 0 17 17',
    data: '<g _fill="#000" fill-rule="nonzero"><path pid="0" d="M3 13A7.071 7.071 0 1113 3 7.071 7.071 0 013 13zm1.414-1.414a5.071 5.071 0 107.172-7.172 5.071 5.071 0 00-7.172 7.172z"/><path pid="1" d="M11.668 13.082l1.415-1.414 3.125 3.125-1.415 1.414z"/></g>'
  }
})
