/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning': {
    width: 54,
    height: 47,
    viewBox: '0 0 54 47',
    data: '<path fill-rule="evenodd" clip-rule="evenodd" d="M31.948 3.99084L32.0105 4.08775L53.8096 40.9504C53.934 41.1614 54 41.3381 54 41.5835C54 44.2607 51.8458 46.3084 49.1967 46.3084H4.8033C2.15489 46.3084 0 44.2614 0 41.5835C0 41.3391 0.0654525 41.2283 0.189567 41.0183L0.191087 41.0157L21.9585 4.13106C21.9647 4.12075 22.0032 4.05889 22.0094 4.04926C23.2535 2.05797 24.5403 0 26.9997 0C29.3583 0 30.6738 2.02722 31.9462 3.9881L31.948 3.99084ZM4.8033 43.559H49.1967C50.2009 43.559 51.0643 42.897 51.2237 41.9058L29.6412 5.47073L29.6028 5.41159C28.6664 3.96818 27.8548 2.71715 26.9997 2.71715C26.1088 2.71715 25.4091 3.7317 24.3409 5.44117L3.32781 40.9794C2.97097 41.5829 2.76634 42.3578 3.23279 42.8813C3.61308 43.3081 4.18115 43.559 4.8033 43.559ZM26.6638 29.3865C27.4226 29.3865 28.0385 28.7706 28.0385 28.0118V14.9519C28.0385 14.193 27.4226 13.5771 26.6638 13.5771C25.9049 13.5771 25.2891 14.193 25.2891 14.9519V28.0118C25.2891 28.7706 25.9049 29.3865 26.6638 29.3865ZM26.9994 38.3612C28.3782 38.3612 29.4959 37.2435 29.4959 35.8647C29.4959 34.4859 28.3782 33.3682 26.9994 33.3682C25.6207 33.3682 24.5029 34.4859 24.5029 35.8647C24.5029 37.2435 25.6207 38.3612 26.9994 38.3612Z" fill="#FFB000"/>'
  }
})
