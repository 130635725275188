import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../src/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_bootstrapvue_1641ca92 from 'nuxt_plugin_bootstrapvue_1641ca92' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_libplugin6605367c_2e127cd8 from 'nuxt_plugin_libplugin6605367c_2e127cd8' // Source: ./lib.plugin.6605367c.js (mode: 'client')
import nuxt_plugin_webfontloader_52878288 from 'nuxt_plugin_webfontloader_52878288' // Source: ./webfontloader.js (mode: 'client')
import nuxt_plugin_vuescrollto_239f1881 from 'nuxt_plugin_vuescrollto_239f1881' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_nuxtvueselect_b842a69e from 'nuxt_plugin_nuxtvueselect_b842a69e' // Source: ./nuxt-vue-select.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_6a9ed430 from 'nuxt_plugin_cookieuniversalnuxt_6a9ed430' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_sentryserver_80900768 from 'nuxt_plugin_sentryserver_80900768' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_59fe8ac4 from 'nuxt_plugin_sentryclient_59fe8ac4' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_vuesvgicon_2ff8cb5f from 'nuxt_plugin_vuesvgicon_2ff8cb5f' // Source: ../src/plugins/vue-svgicon (mode: 'all')
import nuxt_plugin_i18n_1fba523a from 'nuxt_plugin_i18n_1fba523a' // Source: ../src/plugins/i18n.js (mode: 'all')
import nuxt_plugin_vuebar_663c5158 from 'nuxt_plugin_vuebar_663c5158' // Source: ../src/plugins/vuebar.js (mode: 'all')
import nuxt_plugin_init_6270b1a6 from 'nuxt_plugin_init_6270b1a6' // Source: ../src/api/init (mode: 'all')
import nuxt_plugin_apiFactory_ea95d426 from 'nuxt_plugin_apiFactory_ea95d426' // Source: ../src/api/apiFactory (mode: 'all')
import nuxt_plugin_closable_10f4507a from 'nuxt_plugin_closable_10f4507a' // Source: ../src/plugins/closable.js (mode: 'client')
import nuxt_plugin_vuejsnoty_bef5ac06 from 'nuxt_plugin_vuejsnoty_bef5ac06' // Source: ../src/plugins/vuejs-noty.js (mode: 'client')
import nuxt_plugin_vueLoading_a605acaa from 'nuxt_plugin_vueLoading_a605acaa' // Source: ../src/plugins/vueLoading.js (mode: 'client')
import nuxt_plugin_vueEditor_64e5ccfa from 'nuxt_plugin_vueEditor_64e5ccfa' // Source: ../src/plugins/vueEditor.js (mode: 'client')
import nuxt_plugin_onready_2436386c from 'nuxt_plugin_onready_2436386c' // Source: ../src/plugins/on-ready.js (mode: 'client')
import nuxt_plugin_vuecoollightbox_66548a3b from 'nuxt_plugin_vuecoollightbox_66548a3b' // Source: ../src/plugins/vue-cool-lightbox.js (mode: 'client')
import nuxt_plugin_vhotkey_4e8f77f8 from 'nuxt_plugin_vhotkey_4e8f77f8' // Source: ../src/plugins/v-hotkey.js (mode: 'client')
import nuxt_plugin_vueavatarcropper_b4fe25fe from 'nuxt_plugin_vueavatarcropper_b4fe25fe' // Source: ../src/plugins/vue-avatar-cropper.js (mode: 'client')
import nuxt_plugin_webworker_04ce16ca from 'nuxt_plugin_webworker_04ce16ca' // Source: ../src/plugins/web-worker.js (mode: 'client')
import nuxt_plugin_vueslickcarousel_d8b69d56 from 'nuxt_plugin_vueslickcarousel_d8b69d56' // Source: ../src/plugins/vue-slick-carousel.js (mode: 'all')
import nuxt_plugin_idle_4a02ad6d from 'nuxt_plugin_idle_4a02ad6d' // Source: ../src/plugins/idle.js (mode: 'client')
import nuxt_plugin_vuetimeago_0fc864f8 from 'nuxt_plugin_vuetimeago_0fc864f8' // Source: ../src/plugins/vue-timeago.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"GOBUFFY - Твой маркетплейс в мире онлайн игр","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=1060"},{"hid":"description","name":"description","content":"маркетплейс в мире онлайн игр"},{"name":"verification","content":"9bf16288d39fe158b77c0dafc21b96"},{"hid":"og:title","name":"og:title","content":"GOBUFFY - Твой маркетплейс в мире онлайн игр"},{"hid":"og:description","name":"og:description","content":"GOBUFFY - Твой маркетплейс в мире онлайн игр"},{"hid":"og:image","name":"og:image","content":"https:\u002F\u002Fgobuffy.com\u002Fimg\u002Ffavicon-128.png"},{"hid":"og:type","name":"og:type","content":"website"}],"script":[{"src":"https:\u002F\u002Fwww.google.com\u002Frecaptcha\u002Fapi.js"},{"src":"https:\u002F\u002Fcdn.pushwoosh.com\u002Fwebpush\u002Fv3\u002Fpushwoosh-web-notifications.js"},{"src":"\u002F\u002Fcode.jivosite.com\u002Fwidget\u002FCEd9h0CyBQ"},{"src":"\u002F\u002Fcdn.pushwoosh.com\u002Fwebpush\u002Fv3\u002Fpushwoosh-web-notifications.js"}],"link":[{"rel":"icon","id":"favicon16","sizes":"16x16","type":"image\u002Fx-icon","href":"\u002Fimg\u002Ffavicon-16.png"},{"rel":"icon","id":"favicon32","sizes":"32x32","type":"image\u002Fx-icon","href":"\u002Fimg\u002Ffavicon-32.png"},{"rel":"icon","id":"favicon64","sizes":"64x64","type":"image\u002Fx-icon","href":"\u002Fimg\u002Ffavicon-64.png"},{"rel":"apple-touch-icon","id":"favicon128","sizes":"128x128","type":"image\u002Fx-icon","href":"\u002Fimg\u002Ffavicon-128.png"},{"rel":"manifest","id":"pushmanifest","href":"\u002Fmanifest.json"},{"rel":"canonical","id":"canonical","href":"https:\u002F\u002Fgobuffy.com"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_bootstrapvue_1641ca92 === 'function') {
    await nuxt_plugin_bootstrapvue_1641ca92(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_libplugin6605367c_2e127cd8 === 'function') {
    await nuxt_plugin_libplugin6605367c_2e127cd8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_webfontloader_52878288 === 'function') {
    await nuxt_plugin_webfontloader_52878288(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_239f1881 === 'function') {
    await nuxt_plugin_vuescrollto_239f1881(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtvueselect_b842a69e === 'function') {
    await nuxt_plugin_nuxtvueselect_b842a69e(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_6a9ed430 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_6a9ed430(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_80900768 === 'function') {
    await nuxt_plugin_sentryserver_80900768(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_59fe8ac4 === 'function') {
    await nuxt_plugin_sentryclient_59fe8ac4(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesvgicon_2ff8cb5f === 'function') {
    await nuxt_plugin_vuesvgicon_2ff8cb5f(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_1fba523a === 'function') {
    await nuxt_plugin_i18n_1fba523a(app.context, inject)
  }

  if (typeof nuxt_plugin_vuebar_663c5158 === 'function') {
    await nuxt_plugin_vuebar_663c5158(app.context, inject)
  }

  if (typeof nuxt_plugin_init_6270b1a6 === 'function') {
    await nuxt_plugin_init_6270b1a6(app.context, inject)
  }

  if (typeof nuxt_plugin_apiFactory_ea95d426 === 'function') {
    await nuxt_plugin_apiFactory_ea95d426(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_closable_10f4507a === 'function') {
    await nuxt_plugin_closable_10f4507a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuejsnoty_bef5ac06 === 'function') {
    await nuxt_plugin_vuejsnoty_bef5ac06(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueLoading_a605acaa === 'function') {
    await nuxt_plugin_vueLoading_a605acaa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueEditor_64e5ccfa === 'function') {
    await nuxt_plugin_vueEditor_64e5ccfa(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_onready_2436386c === 'function') {
    await nuxt_plugin_onready_2436386c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecoollightbox_66548a3b === 'function') {
    await nuxt_plugin_vuecoollightbox_66548a3b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vhotkey_4e8f77f8 === 'function') {
    await nuxt_plugin_vhotkey_4e8f77f8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueavatarcropper_b4fe25fe === 'function') {
    await nuxt_plugin_vueavatarcropper_b4fe25fe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_webworker_04ce16ca === 'function') {
    await nuxt_plugin_webworker_04ce16ca(app.context, inject)
  }

  if (typeof nuxt_plugin_vueslickcarousel_d8b69d56 === 'function') {
    await nuxt_plugin_vueslickcarousel_d8b69d56(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_idle_4a02ad6d === 'function') {
    await nuxt_plugin_idle_4a02ad6d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetimeago_0fc864f8 === 'function') {
    await nuxt_plugin_vuetimeago_0fc864f8(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
