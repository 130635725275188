/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vk': {
    width: 27,
    height: 15,
    viewBox: '0 0 27 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.655 14.677s.463-.05.7-.3c.216-.229.208-.66.208-.66s-.029-2.015.924-2.312c.94-.293 2.145 1.948 3.425 2.81.966.65 1.7.508 1.7.508l3.42-.046s1.788-.109.94-1.489c-.07-.113-.495-1.021-2.542-2.887-2.145-1.953-1.857-1.637.725-5.016 1.573-2.057 2.201-3.313 2.005-3.85-.187-.514-1.344-.378-1.344-.378l-3.849.024s-.285-.038-.497.086c-.206.121-.34.405-.34.405s-.609 1.592-1.421 2.946c-1.715 2.857-2.4 3.008-2.68 2.83-.652-.413-.49-1.66-.49-2.547 0-2.769.429-3.923-.832-4.222-.418-.1-.726-.164-1.797-.176-1.373-.013-2.536.005-3.194.321-.438.21-.776.68-.57.707.254.033.83.152 1.136.56.394.528.38 1.71.38 1.71s.227 3.26-.53 3.664c-.518.278-1.229-.289-2.758-2.881-.782-1.327-1.373-2.795-1.373-2.795s-.114-.274-.318-.422a1.593 1.593 0 00-.591-.233l-3.657.023s-.55.015-.752.25c-.179.208-.013.639-.013.639s2.863 6.577 6.106 9.891c2.974 3.039 6.35 2.84 6.35 2.84h1.53z" _fill="#292E3B"/>'
  }
})
