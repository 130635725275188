function errorResponse(data){
  if (!data) return;

  if(data.message){
    return data.message;
  }
  if (typeof data == 'string') {
    return data;
  }
  //local errors
  if (typeof data == 'object' && data.type && data.type.contains('yii')) {
    return data.message;
  }
  if(typeof data == 'object'){
    let result = '';
    for(let key in data){
      result += data[key].message + ' '
    }
    return result;
  }
  if(typeof data == 'array'){
    let result = '';
    for(let key in data){
      result += data[key].message + ' '
    }
    return result;
  }
};

export { errorResponse }