<template>
  <div class="copyright">
    <div class="copyright-head">
      <div class="copyright-logo">
        <img
          src="/img/logo.svg"
          alt="">
      </div>
    </div>
    <div class="copyright-content">
      <p>
        GOBUFFY - это маркетплейс игровых ценностей. Лучшие продавцы были собраны, чтобы удовлетворить любую Вашу
        игровую потребность. Современные технологии обеспечивают высокую безопасность сделок.
      </p>
      <p>
        ©{{ getCurrentYear() }} GoBuffy.com
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {

      }
    },
    methods: {
      getCurrentYear(){
        return new Date().getFullYear()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .copyright{
    font-size: rem(14px);
    line-height: 1.8;
    color: rgba(#ffffff, .5)
  }
  .copyright-head{
    margin-bottom: rem(9px);
    font-size: rem(14px);
    font-weight: 400;
    color: rgba(#ffffff, .3)
  }
  .copyright-logo{
    display: inline-block;
    width: 162px;
    font-weight: 500;
    color: #ffffff;
    img{
      width: 100%;
    }
  }
</style>