<template>
  <div
    id="chatBarScroll"
    v-bar
    class="scroll-wrap">
    <div>
      <div class="list">
        <div :class="chatFull ? 'list-full' : ''">
          <div
            v-for="(item, index) in chat.messages"
            :key="index">
            <template v-if="item.role != 'bot'">
              <template
                v-if="
                  index > 0 &&
                    chat.messages[index - 1].userId == item.userId &&
                    compareTime(chat.messages[index - 1].time, item.time)
                ">
                <div
                  v-linkified
                  class="content"
                  v-html="cleanMessage(item.message)"/>
              </template>
              <template v-else>
                <div class="head">
                  <div class="avatar">
                    <img
                      v-if="
                        chat.users[item.userId].avatar != '' &&
                          chat.users[item.userId].avatar != null
                      "
                      :src="chat.users[item.userId].avatar"
                      width="24"
                      height="24"
                      alt="">
                    <div
                      v-else
                      class="no-avatar">
                      <span>{{
                        chat.users[item.userId].username.charAt(0)
                      }}</span>
                    </div>
                  </div>
                  <div
                    class="name"
                    :class="{
                      admin: parseInt(chat.users[item.userId].role) == 1,
                    }">
                    {{ chat.users[item.userId].username }}
                  </div>
                  <!-- TODO: поправить вёрстку, чтобы текст был в линию -->
                  <div
                    v-if="parseInt(chat.users[item.userId].role) == 1"
                    class="badge">
                    ADMIN
                  </div>
                  <div class="time">
                    {{ dateFormat(item.time) }}
                  </div>
                </div>
                <div
                  v-linkified
                  class="content"
                  v-html="cleanMessage(item.message)"/>
              </template>
            </template>

            <template v-else>
              <div class="bot-message">
                <div class="head">
                  <div class="bot-avatar avatar">
                    <img
                      src="/svg/chat-warning.svg"
                      width="32"
                      height="32"
                      alt="">
                  </div>
                  <div class="header">
                    Сделки вне сайта небезопасны и несут риски
                  </div>
                </div>
                <div
                  v-linkified
                  class="content"
                  v-html="cleanMessage(item.message)"/>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import "../../icons/chat-full";
import { mapGetters } from "vuex";
import { dateFormatChat } from "~/services/chat";
import { compareDate } from "~/services/chat";
import { scrollToBottomChat } from "~/services/chat";
import linkify from "vue-linkify";
import Vue from "vue";
Vue.directive("linkified", linkify);
import VueSanitize from "vue-sanitize";

let defaults = VueSanitize.defaults;

defaults.allowedTags = defaults.allowedTags.filter((t) => {
  return t == "a";
});

Vue.use(VueSanitize, defaults);

Vue.config.productionTip = false;
export default {
  data() {
    return {
      loading: true,
      oldHeight: 0,
      newHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      chat: "chat/getChat",
      chatTrigger: "chat/getChatId",
      chatFull: "chat/chatFull",
      newMessageTrigger: "chat/newMessageTrigger",
      scrollMessagesTrigger: "chat/scrollMessagesTrigger",
    }),
  },
  watch: {
    newMessageTrigger: function () {
      scrollToBottomChat("chatBarScroll");
    },
    chatTrigger: function () {
      setTimeout(() => {
        this.oldHeight = document
          .getElementById("chatBarScroll")
          .getElementsByClassName("list")[0].offsetHeight;
        this.loading = true;
      }, 0);
    },
    scrollMessagesTrigger: function () {
      let scrollWrapper = document
        .getElementById("chatBarScroll")
        .getElementsByClassName("vb-content")[0];
      this.newHeight = document
        .getElementById("chatBarScroll")
        .getElementsByClassName("list")[0].offsetHeight;
      scrollWrapper.scrollTop = this.newHeight - this.oldHeight;
      this.oldHeight = this.newHeight;
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      let scrollWrapper = document
        .getElementById("chatBarScroll")
        .getElementsByClassName("vb-content")[0];
      this.oldHeight = document
        .getElementById("chatBarScroll")
        .getElementsByClassName("list")[0].offsetHeight;
      scrollWrapper.addEventListener("scroll", () => {
        if (scrollWrapper.scrollTop == 0) {
          this.uploadMoreMessages();
        }
      });
    });
  },
  updated() {
    if (this.loading) {
      scrollToBottomChat("chatBarScroll");
      this.loading = false;
    }
  },
  methods: {
    dateFormat: function (data) {
      return dateFormatChat(data);
    },
    compareTime: function (time1, time2) {
      return compareDate(time1, time2);
    },
    uploadMoreMessages: function () {
      let chatId = this.$store.state.chat.chatId;
      this.$store.dispatch("chat/GET_MORE_MESSAGES", chatId);
    },
    cleanMessage(message) {
      return this.$sanitize(message);
    }
  },
};
</script>

<style lang="scss" scoped>
.scroll-wrap {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.scroll {
  position: absolute;
  top: 64px;
  bottom: 64px;
  left: 0;
  right: 0;
  padding-right: 4px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #3e4356;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
}
.list {
  > div {
    &:last-child {
      margin-bottom: 10px;
    }
  }
}
.list-full {
  position: relative;
  width: 736px;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
}
.avatar {
  margin-right: 8px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  text-transform: uppercase;
  overflow: hidden;
  img {
    display: block;
  }
}
.bot-avatar {
  min-width: 32px;
  min-height: 32px;
}
.no-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
}

.head {
  padding-top: 21px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;
}
.time {
  font-size: 12px;
  color: #a8aaaf;
}

.name {
  margin-right: 5px;
  color: $side-bar-user-color;
  font-size: 14px;
  font-weight: 500;
  max-width: 130px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content {
  margin-top: 6px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
  line-height: calc(20 / 14);
  font-weight: 500;
  color: #a8aaaf;
  word-wrap: break-word;
}
.loading {
  padding-top: 10px;
  margin-bottom: -10px;
  text-align: center;
}
.admin {
  color: #ffb000;
}
.badge {
  margin-right: 5px;
  color: #ffb000;
  opacity: 0.6;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}
.header {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #fdfdfd;
}
.bot-message {
  padding-bottom: 16px;
  background: #181a28;
  border-radius: 4px;
  margin: 16px;
}
</style>