<template>
  <div class="container">
    <h1 v-if="error.statusCode === 404">
      Страница не найдена
    </h1>
    <h1 v-else>
      {{ error.message }}
    </h1>
    <nuxt-link to="/">
      На главную
    </nuxt-link>
  </div>
</template>

<script>
  export default {
    props: {
      error: {
        type: Object,
        default: () => ({}),
      }
    },
    layout: 'default'
  }
</script>