const middleware = {}

middleware['breadcrumbs'] = require('../src/middleware/breadcrumbs.js')
middleware['breadcrumbs'] = middleware['breadcrumbs'].default || middleware['breadcrumbs']

middleware['i18n'] = require('../src/middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['isAdmin'] = require('../src/middleware/isAdmin.js')
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['isAuthenticated'] = require('../src/middleware/isAuthenticated.js')
middleware['isAuthenticated'] = middleware['isAuthenticated'].default || middleware['isAuthenticated']

middleware['isUserActivate'] = require('../src/middleware/isUserActivate.js')
middleware['isUserActivate'] = middleware['isUserActivate'].default || middleware['isUserActivate']

export default middleware
