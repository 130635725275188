/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'payment_visa-icon': {
    width: 50,
    height: 18,
    viewBox: '0 0 50 18',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M21.1 16.96h-4.131l2.584-15.87h4.13L21.1 16.96zM36.077 1.48A10.242 10.242 0 0032.37.803c-4.08 0-6.953 2.16-6.97 5.25-.034 2.279 2.056 3.545 3.62 4.305 1.599.776 2.142 1.283 2.142 1.975-.016 1.063-1.291 1.553-2.481 1.553-1.65 0-2.533-.252-3.876-.844l-.544-.253-.578 3.562c.969.438 2.754.828 4.607.845 4.335 0 7.157-2.128 7.19-5.42.017-1.806-1.087-3.19-3.467-4.321-1.445-.727-2.33-1.216-2.33-1.959.017-.675.748-1.367 2.38-1.367a7.06 7.06 0 013.077.607l.374.169.562-3.427zM41.567 11.338c.34-.912 1.65-4.44 1.65-4.44-.017.034.34-.929.543-1.52l.29 1.368s.782 3.798.951 4.592h-3.434zm5.1-10.247h-3.196c-.985 0-1.734.287-2.159 1.316L35.176 16.96h4.334l.867-2.38h5.305c.118.557.493 2.38.493 2.38H50L46.667 1.091zM13.518 1.09L9.472 11.913 9.03 9.717c-.748-2.532-3.094-5.283-5.712-6.651l3.706 13.877h4.369L17.887 1.09h-4.369z" _fill="#00579F"/><path pid="1" d="M5.715 1.09H-.932L-1 1.412C4.185 2.728 7.619 5.903 9.03 9.718L7.585 2.425c-.238-1.014-.97-1.3-1.87-1.334z" _fill="#FAA61A"/></g><defs><clipPath id="clip0"><path pid="2" _fill="#fff" d="M0 0h50v18H0z"/></clipPath></defs>'
  }
})
