<template>
  <b-modal
    id="modalRestoreCode"
    v-model="show"
    class="modalAuth"
    centered
    @hide="resetForm">
    <div
      slot="modal-header"
      :header-border-variant="0"
      class="modalHeader">
      <svgicon
        name="close"
        width="14"
        height="14"
        class="closeModal"
        @click="show=false"/>
      <img
        src="/img/logoBlack.svg"
        alt="Gobuffy">
    </div>
    <div class="modalContent">
      <br>
      <h3>Введите код</h3>

      <div class="modalDescription">
        Введите код который Вы получили в письме восстановления
      </div>

      <baseAuthForm
        :on-submit="onSubmit"
        :inputs="inputs"
        :url="url"
        :submit-text="submitText"
        :active="show"/>
    </div>

    <div
      slot="modal-footer"
      class="modalFooter">
      <p>
        <a
          v-b-modal.modalRegistrationSocial
          class="modalFooterLink"
          @click="show = false">
          Вернуться назад
        </a>
      </p>
    </div>
  </b-modal>
</template>

<script>
  import baseAuthForm from './_base-auth-form';
  export default {
    components: {
      baseAuthForm
    },
    props: {
      restoreStatus: {
        type: Boolean,
        default: false
      },
      restoreEmail: {
        type: String,
        default: null
      },
      onRestore: {
        type: Function,
        default: () => {},
      },
      onClose: {
        type: Function,
        default: () => {},
      }
    },
    data() {
      return {
        inputs: [
          {
            name: 'email',
            value: this.$route.query.user_email,
            type: 'email',
            errorFlag: false,
            hidden: true,
            errorText: 'Поле обязательно для заполнения'
          },
          {
            name: 'code',
            value: this.$route.query.forgot_code,
            placeholder: 'Код из письма',
            type: 'text',
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          },
          {
            name: 'password',
            value: '',
            placeholder: 'Новый пароль',
            type: 'password',
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          },
          {
            name: 'repeatPassword',
            value: '',
            placeholder: 'Повторите новый пароль',
            type: 'password',
            errorFlag: false,
            errorText: 'Поле обязательно для заполнения'
          }
        ],
        submitText: 'Отправить',
        url: 'auth/CHANGE_PASSWORD',
        formSubmit: false,
        show: false,
      }
    },
    watch: {
      "$route.query.auth_state": {
        handler: function (auth_state) {
          if (auth_state == 'forgotPassword') {
            this.show = true;
            return;
          }
        },
        deep: true,
        immediate: true
      },
      restoreStatus: function () {
        this.show = this.restoreStatus;
      },
      restoreEmail: function () {
        this.inputs[0].value = this.restoreEmail;
      }
    },
    methods: {
      resetForm: function(){
        for(let i = 0; i < this.inputs.length; i++){
          this.inputs[i].value = '';
          this.inputs[i].errorFlag = false;
        }
        this.onClose();
      },
      onSubmit (data) {
        this.formSubmit = data;
        this.show = false;
        this.onRestore();
      }
    }
  }
</script>
<style lang="scss" scoped>
  .restoreCode{
    width: 192px;
    max-width: 100%;
    margin: 0 auto 10px;
  }
  .modalInput{
    text-align: center;
  }
</style>
