import axios from "axios";

export const state = () => ({
  notifiers: []  
})

export const mutations = {
  setNotifiers(state, notifiers) {
    state.notifiers = notifiers
  }
};
export const getters = {
  getNotifiers(state) {
    return state.notifiers
  },
  isHasNotifiers(state) {
    return state.notifiers && state.notifiers.length !== 0
  }
};

export const actions = {
  GIVE_BONUS({commit}, data){
    return axios.post('/users/' + data.objectId + '/give-bonuses', data.data)
      .then(response => {
        return response.data;
      })
      .catch(exception => {
        return exception.response.data;
      });
  },
  GET_IS_SELLER({ state, commit, dispatch, rootState }, data) {
    return axios.get('/users/' + rootState.auth.user.jti + '/is-seller')
      .then(response => {
        return response.data;
      })
      .catch(exception => {
        return exception.response
      })
  },
  async GET_NOTIFIERS({ commit }, { userId }) {
    try {
      const { data: res } = await this.$api.users.notifiers(userId)

      if (res?.data?.items) {
        commit('setNotifiers', res.data.items)  
      }
    } catch (e) {}
  }
}
