export function dateFormatChat(unixTime) {
  let date = '';

  // Check type of date
  if (typeof unixTime != Number){
    date = new Date(parseInt(unixTime));
  }
  else{
    date = new Date(unixTime);
  }
  let dateFormat = '';
  let dateNow = new Date(parseInt(Date.now()));
  let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  let min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

  // Check today or not today
  if(date.toDateString() === dateNow.toDateString()){
    dateFormat = hour + ':' + min;
  }
  else{
    dateFormat = date.getDate() + ' ' + date.toLocaleString('ru', {month: "short"}) + ' ' + hour + ':' + min;
  }
  return dateFormat;
}

export function compareDate(time1, time2){
  let date1 = new Date(parseInt(time1));
  let date2 = new Date(parseInt(time2));
  if (date1.toDateString() === date2.toDateString()){
    return true;
  }
  else{
    return false;
  }
}

export function scrollToBottomChat(chatId){
  if(document.getElementById(chatId)){
    let scrollWrapper = document.getElementById(chatId).getElementsByClassName('vb-content')[0];
    let scrollContent = document.getElementById(chatId).getElementsByClassName('list')[0];
    setTimeout(function () {
      scrollWrapper.scrollTop = scrollContent.scrollHeight;
    }, 0);
  }
}
