import slugify from 'slugify';
import { mapGetters } from "vuex";
import { socket } from "~/plugins/socket-io.js";
export default {
  mounted() {
    let _this = this;
    document.body.onclick = function (e) {
      if (
        e.target.tagName.toLowerCase() !== 'a' ||
        !e.target.href
      ) {
        return
      }

      const isSameHost = e.target.host && e.target.host == location.host
      const isAllowedHref = e.target.href && e.target.href.includes('t.me/GobuffyBot')

      if (!isSameHost && !isAllowedHref) {
        e.preventDefault();
        _this.$store.dispatch("global/SET_OUT_LINK", e.target.href).then(() => {
          _this.$bvModal.show("modalConfirmationOutLink");
        });
      }
    };

    document.body.oncontextmenu = function (e) {
      if (
        e.target.tagName.toLowerCase() !== 'a' ||
        !e.target.href
      ) {
        return
      }

      const isSameHost = e.target.host && e.target.host == location.host
      const isAllowedHref = e.target.href && e.target.href.includes('t.me/GobuffyBot')

      if (!isSameHost && !isAllowedHref) {
        e.preventDefault();
        _this.$store.dispatch("global/SET_OUT_LINK", e.target.href).then(() => {
          _this.$bvModal.show("modalConfirmationOutLink");
        });
      }
    };
  },
  computed: {
    ...mapGetters({
      outLink: "global/getOutLink",
      isIdle: "global/getIsIdle",
    }),
  },
  methods: {
    slugify(text) {
      return slugify(text, {
        lower: true,
        locale: 'ru'
      });
    },
    adminListenChat: function (userId, anotherUserId) {
      this.$store.dispatch('chat/ADMIN_LISTEN_CHAT', {
        userId: userId,
        anotherUserId: anotherUserId
      }).then(listenResponse => {
        if (listenResponse == null) {
          return this.$noty.error('Между пользователями нет диалога');
        }
      }).catch(err => console.log(err));
    },
    startChat: function (anotherUserId) {
      return this.$store.dispatch('chat/START_CHAT', { id: anotherUserId }).then(res => {
        return true;
      }).catch(err => {
        if (err == 'selfchat') {

          return this.$noty.error('Нельзя начать чат с самим собой');
        } else if (err == 'auth') {

          return this.$root.$emit("bv::show::modal", "modalLoginSocial");
        } else {
          if (err && err.error) {
            return this.$noty.error(err.error);
          }
          return this.$noty.error('Возникла неизвестная ошибка');
        }
      });
    },
    goToLink() {
      if (this.outLink != null) {
        let link = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
        link.href = this.outLink;
        link.target = '_blank';
        let event = new MouseEvent('click', {
          'view': window,
          'bubbles': false,
          'cancelable': true
        });
        link.dispatchEvent(event);
        this.$store.dispatch("global/SET_OUT_LINK", null);
      }
    },
    replaceGameIconByDefault(e) {
      e.target.src = '/img/game-no-icon.png';
    },
    replaceGameCoverByDefault(e) {
      e.target.src = '/img/game-no-cover.png';
    },
  }
}
