<template>
  <nav class="nav ads-nav">
    <ul class="list container">
      <li
        v-for="(link, index) in links"
        :key="index"
        class="item">
        <nuxt-link
          :to="$i18n.path(link.link)"
          :class="link.main ? 'link main' : 'link'">
          {{ link.name }}
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<script>
  import {mapGetters} from 'vuex';
  export default {
    data() {
      return {
        links: [
          {
            name: 'Объявления',
            link: 'administration/ads/list'
          },
          {
            name: 'Клики',
            link: 'administration/ads/clicks'
          },
          {
            name: 'Настройки',
            link: 'administration/ads/settings'
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        user: 'auth/getUser'
      }),
    },
  }
</script>

<style lang="scss" scoped>
  .nav{
    position: fixed;
    top: 64px;
    left: 0;
    right: 64px;
    background: $side-bar-color;
    z-index: 1000;
  }
  .list{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
    padding: 0 30px;
    list-style: none;
    font-size: 14px;
  }
  .item{
    margin-right: 48px;
  }
  .link{
    position: relative;
    display: inline-block;
    padding-top: 13px;
    padding-bottom: 14px;
    color: #A8AAAF;
    &:hover{
      color: #ffffff;
      &:after{
        background: #6E7279;
        opacity: 1;
      }
    }
    &:after{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background: $blue;
      content: "";
      transition: $link-transition;
      opacity: 0;
    }
    &.nuxt-link-active{
      color: $blue;
      &:after{
        opacity: 1;
      }
      &.main{
        color: #A8AAAF;
        &:after{
          opacity: 0;
        }
      }
    }
    &.main.nuxt-link-exact-active{
      color: $blue;
      &:after{
        opacity: 1;
      }
    }
  }
</style>