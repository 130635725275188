import serializeQueryParams from './serializeQueryParams';

export default ($axios) => ({
  async services(queryParams) {
    let params = queryParams ? '?' + serializeQueryParams(queryParams) : '';

    return await $axios.get(`/notifiers/services` + params);
  },
  async link(serviceName) {

    return await $axios.get(`/notifiers/${serviceName}/link`)
  },
  async unlink(serviceName) {

    return await $axios.get(`/notifiers/${serviceName}/unlink`)
  },
  async active(serviceName, isActive = true) {

    return await $axios.post(`/notifiers/${serviceName}/active`, { isActive })
  }
})