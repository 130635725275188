import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { setAuthToken } from '~/utils/auth';
import { changeFavicon, getPhoto } from '~/services/base.js';
import { socket } from '~/plugins/socket-io.js';

export const state = () => ({
  user: null,
  isAlreadyFetchingAccessToken: false,
  isNeedChatReconnect: false,
  accessToken: null,
  refreshToken: null,
  isPushSubscribed: false,
  isPushDismissed: false,
  isPushwooshAlreadyInited: false,
  gettedUser: null,
  isOuterValidated: false,
  refreshTokenAttempt: 0

});

export const mutations = {
  setUser(state, data) {

    if (state.user) {
      Object.keys(data.user).forEach(function (
        item,
        index
      ) {
        if (state.user) {
          state.user[item] = data.user[item];
        }

      });
    }
    else {
      state.user = data.user;
    }

  },
  setIsWithdrawBlocked(state, isBlocked) {
    state.user.is_withdraw_blocked = isBlocked;
  },
  setPremium(state, data) {
    if (state.user) {
      state.user.premium_expired_at = data.premium_expired_at;
      state.user.premium_type = data.premium_type;
    }
  },
  resetUser(state) {
    state.user = null;
  },
  setBalance(state, data) {
    state.user.balance = parseFloat(data.balance).toFixed(2);
    state.user.balance_freeze = parseFloat(data.balance_freeze).toFixed(2);
    state.user.adsBalance = parseFloat(data.adsBalance).toFixed(2);
  },
  setRating(state, data) {
    state.user.rating = data.rating;
  },
  confirmEmail(state) {
    state.user.is_active = true;
  },
  setIsOuterValidated(state, isValidated) {
    state.user.is_outer_validated = isValidated;
  },
  setDiscordUserId(state, discord_user_id) {
    state.user.discord_user_id = discord_user_id;
  },
  setIsAlreadyFetchingAccessToken(state, status) {
    state.isAlreadyFetchingAccessToken = status;
  },
  setIsOuterBonusesReceived(state, status) {
    state.user.is_outer_bonuses_received = status;
  },
  setTokens(state, payload) {
    state.accessToken = payload.accessToken;
    state.refreshToken = payload.refreshToken;
  },
  setIsPushSubscribed(state, isSubscribed) {
    state.isPushSubscribed = isSubscribed;
  },
  setIsPushDismissed(state, isDismissed) {
    state.isPushDismissed = isDismissed;
  },
  setIsPushwooshAlreadyInited(state, isInited) {
    state.isPushwooshAlreadyInited = isInited;
  },
  setUserAgreementStatus(state, isSubmited) {
    state.user.user_agreement = isSubmited ? 1 : 0;
  },
  setIsActive(state, payload) {
    state.user.is_active = payload.isActive;
  },
  setIsPhoneVerified(state, payload) {
    state.user.is_phone_verified = payload.isPhoneVerified;
  },
  setPhone(state, payload) {
    state.user.phone = payload.phone;
  },
  setUserPhoto(state, payload) {
    state.user.photo = payload.photo;
  },
  setIsNeedChatReconnect(state, status) {
    state.isNeedChatReconnect = status;
  },
  setUsernameChangedAt(state, payload) {
    state.user.username_changed_at = payload.usernameChangedAt;
  },
  setGettedUser(state, user) {
    state.gettedUser = user;
  },
  setRefreshTokenAttempt(state, attempt) {
    state.refreshTokenAttempt = attempt;
  }
};

export const getters = {
  getUser: (state) => {
    return state.user;
  },
  getIsAlreadyFetchingAccessToken: (state) => {
    return state.isAlreadyFetchingAccessToken;
  },
  getAccessToken: (state) => {
    return state.accessToken;
  },
  getRefreshToken: (state) => {
    return state.refreshToken;
  },
  getIsPushSubscribed: (state) => {
    return state.isPushSubscribed;
  },
  getIsPushDismissed: (state) => {
    return state.isPushDismissed;
  },
  getIsPushwooshAlreadyInited: (state) => {
    return state.isPushwooshAlreadyInited;
  },
  getGettedUser: (state) => {
    return state.gettedUser;
  },
  getDiscordUserId: (state) => {
    if (state.user) {
      return state.user.discord_user_id;
    }
    else {
      return null;
    }
  },
  getIsPhoneVerified: (state) => {
    return state.user.is_phone_verified;
  },
};
export const actions = {

  fetch({ commit }, data) {
    if (data) {
      let user = jwtDecode(data);
      user.balance_freeze = 0;
      commit('setUser', { user: user });
      return true;
    }
    else {
      commit('resetUser');
      return false;
    }
  },
  PUSH_SUBSCRIBE({ commit, dispatch }, data) {
    pwInstance.push(['init', {
      logLevel: 'debug', // possible values: error, info, debug
      applicationCode: 'E5AFB-97701',
      safariWebsitePushID: 'gobuffy.com',
      defaultNotificationTitle: 'Новое уведомление!',
      defaultNotificationImage: 'https://gobuffy.com/img/G-logo.png',
      autoSubscribe: false,
    }]);
  },
  SET_PHONE({ commit }, payload) {
    commit('setPhone', { phone: payload.phone });
    commit('setIsPhoneVerified', { isPhoneVerified: payload.isPhoneVerified });
  },
  ON_LOGIN({ commit, dispatch }, data) {
    let user = jwtDecode(data.access_token);
    setAuthToken(data.access_token);
    commit('setUser', { user: user, token: data.access_token });
    commit('setTokens', {
      accessToken: data.access_token,
      refreshToken: data.refresh_token
    });

    this.$cookiz.set('access-token', data.access_token, {
      maxAge: 60 * 60 * 24 * 7,
      path: '/',
      domain: '.' + (process.server ? req.headers.host : window.location.hostname),
    });
    this.$cookiz.set('refresh-token', data.refresh_token, {
      maxAge: 60 * 60 * 24 * 7,
      path: '/',
      domain: '.' + (process.server ? req.headers.host : window.location.hostname),
    });
    this.$cookiz.remove('guest-token');

    socket.emit('login', { jwt: data.access_token });
    dispatch('auth/GET_BALANCE', {}, { root: true });
    dispatch('notifications/INIT_NOTIFICATIONS', {}, { root: true });
    dispatch('notifications/GET_NOTIFICATIONS', {}, { root: true });
    dispatch('chat/INIT_CHAT', data.access_token, { root: true });
    this.$yandexMetrika.reachGoal('user_auth');
    this.$yandexMetrika.userParams(user);
  },
  ON_REGISTER({ commit, dispatch }, data) {
    let user = jwtDecode(data.access_token);
    commit('setUser', { user: user, token: data.access_token });
    this.$cookiz.set('access-token', data.access_token, {
      maxAge: 60 * 60 * 24 * 7,
      path: '/',
      domain: '.' + (process.server ? req.headers.host : window.location.hostname),
    });
    this.$cookiz.set('refresh-token', data.refresh_token, {
      maxAge: 60 * 60 * 24 * 7,
      path: '/',
      domain: '.' + (process.server ? req.headers.host : window.location.hostname),
    });
    setAuthToken(data.access_token);
    this.$cookiz.remove('guest-token');
    socket.emit('login', { jwt: data.access_token });
    dispatch('notifications/INIT_NOTIFICATIONS', {}, { root: true });
    dispatch('chat/INIT_CHAT', data.access_token, { root: true });
    this.$yandexMetrika.reachGoal('user_registration');
    this.$yandexMetrika.userParams(user);
  },
  EMIT_LOGIN({ commit, dispatch }, token) {
    socket.emit('login', { jwt: token });
  },
  LOGOUT({ commit, dispatch }) {
    commit('resetUser');
    delete axios.defaults.headers.Authorization;
    this.$cookiz.remove('access-token', {
      path: '/',
      domain: '.' + (process.server ? req.headers.host : window.location.hostname),
    });
    this.$cookiz.remove('refresh-token', {
      path: '/',
      domain: '.' + (process.server ? req.headers.host : window.location.hostname),
    });
    this.$cookiz.remove('isAlreadyFetchingAccessToken', {
      path: '/',
      domain: '.' + (process.server ? req.headers.host : window.location.hostname),
    });
    dispatch('chat/DEACTIVATION_CHAT', {}, { root: true });
    dispatch('notifications/RESET_NOTIFICATIONS', {}, { root: true });
    changeFavicon(false);
    return Promise.resolve();
  },

  LOGIN({ commit, dispatch }, data) {
    return axios.post('/auth/login', data)
      .then(response => {
        if (response.data.success) {
          dispatch('ON_LOGIN', response.data.data);
        }
        else {
          dispatch('LOGOUT')
        }
        return response.data;
      })
      .catch(exception => {

        return exception.response;
      })
  },

  REFRESH_TOKEN({ commit, dispatch, state }) {
    commit('setRefreshTokenAttempt', state.refreshTokenAttempt + 1);
    if (state.isAlreadyFetchingAccessToken) {
      commit('isNeedChatReconnect', true);
      return;
    }
    this.$cookiz.set('isAlreadyFetchingAccessToken', true, {
      maxAge: 5 * 60,
      path: '/',
      domain: '.' + (process.server ? req.headers.host : window.location.hostname),
    });

    let currentRefreshToken = this.$cookiz.get('refresh-token');

    return axios.post('/auth/refresh-token', { token: currentRefreshToken })
      .then(response => {
        commit('setRefreshTokenAttempt', 0);
        if (response.data.success) {
          setAuthToken(response.data.data.access_token);
          commit('setUser', {
            user: jwtDecode(response.data.data.access_token),
          });
          this.$cookiz.set('access-token', response.data.data.access_token, {
            maxAge: 60 * 60 * 24 * 7,
            path: '/',
            domain: '.' + (process.server ? req.headers.host : window.location.hostname),
          });
          this.$cookiz.set('refresh-token', response.data.data.refresh_token, {
            maxAge: 60 * 60 * 24 * 7,
            path: '/',
            domain: '.' + (process.server ? req.headers.host : window.location.hostname),
          });
          commit('setTokens', {
            accessToken: response.data.data.access_token,
            refreshToken: response.data.data.refresh_token
          });

          dispatch('chat/CONNECT_CHAT', {}, { root: true });
          this.$cookiz.remove('isAlreadyFetchingAccessToken');

          return response.data.data.access_token;
        }
        else {
          this.$cookiz.remove('isAlreadyFetchingAccessToken');
          commit('resetUser');
          dispatch('chat/DEACTIVATION_CHAT', {}, { root: true });
          dispatch('notifications/RESET_NOTIFICATIONS', {}, { root: true });
          changeFavicon(false);
          return undefined;
        }
      })
      .catch(exception => {

        if (exception.response.status == 422) {
          if (state.refreshTokenAttempt < 11) {
            setTimeout(() => {
              commit('setRefreshTokenAttempt', state.refreshTokenAttempt + 1);
              return dispatch('auth/REFRESH_TOKEN', {}, { root: true });
            }, 2000);
          }
          else {
            commit('setRefreshTokenAttempt', 0);
            return exception.response
          }
        }
        else {
          commit('setRefreshTokenAttempt', 0);
          console.log('[REFRESH_TOKEN] Error', exception.response);
          return exception.response
        }
      }
      )
  },
  REGISTRATION({ commit, dispatch }, data) {
    return axios.post('/auth/register', data)
      .then(response => {
        if (response.data.success) {
          dispatch('ON_REGISTER', response.data.data);
        }
        return response.data
      })
      .catch(exception => {
        return exception.response
      }
      )
  },

  REGISTRATION_CODE({ commit, dispatch }, data) {
    return axios.post('/auth/activate', data)
      .then(response => {
        this.$yandexMetrika.reachGoal('user_activate-account');
        dispatch('ON_LOGIN', response.data.data);
        return response.data
      })
      .catch(exception => {
        return exception.response
      }
      )
  },

  FORGOT_PASSWORD({ commit }, data) {
    return axios.post('/auth/forget', data)
      .then(response => {
        return response.data
      })
      .catch(exception => {
        return exception.response
      }
      )
  },

  CHANGE_PASSWORD({ commit }, data) {
    return axios.post('/auth/change-password', data)
      .then(response => {
        return response.data
      })
      .catch(exception => {
        return exception.response
      }
      )
  },

  GET_BALANCE({ state, commit, dispatch }, data) {
    return axios.get('/users/' + state.user.jti)
      .then(response => {
        commit('setBalance', {
          balance: response.data.data.balance,
          balance_freeze: response.data.data.balance_freeze,
          adsBalance: response.data.data.ads_balance
        });
        commit('setIsWithdrawBlocked', parseInt(response.data.data.is_withdraw_blocked));
        commit('setIsActive', { isActive: parseInt(response.data.data.is_active) });

        commit('setIsOuterValidated', parseInt(response.data.data.is_outer_validated));
        commit('setDiscordUserId', response.data.data.discord_user_id);

        commit('setPremium', { premium_expired_at: parseInt(response.data.data.premium_expired_at), premium_type: response.data.data.premium_type });

        commit('setUserAgreementStatus', parseInt(response.data.data.user_agreement));
        commit('setIsOuterBonusesReceived', parseInt(response.data.data.is_outer_bonuses_received));
        let photo = getPhoto(response.data.data.id, response.data.data.photo_uploaded_at);
        commit('setUserPhoto', {
          photo: photo ? process.env.backendUrl + photo : null
        });
        commit('setRating', { rating: response.data.data.rating });
        commit('setUsernameChangedAt', {
          usernameChangedAt: parseInt(response.data.data.username_changed_at)
        });
        dispatch('SET_PHONE', {
          phone: response.data.data.phone,
          isPhoneVerified: response.data.data.is_phone_verified
        });
        return response.data
      })
      .catch(exception => {
        return exception.response
      })
  },
  GET_USER_BY_ID({ state, commit, dispatch }, id) {
    return axios
      .get("/users/" + id)
      .then(response => {
        if (response.data.success) {
          commit('setGettedUser', response.data.data);
          this.user = response.data.data;
        }
      })
      .catch(exception => {
        return exception.response;
      });
  }
}
