/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashboard': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M0 10h8V0H0v10zm0 8h8v-6H0v6zm10 0h8V8h-8v10zm0-18v6h8V0h-8z" _fill="#6E7279"/>'
  }
})
