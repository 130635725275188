<template>
  <div>
    <header class="header">
      Уведомления
    </header>

    <div
      id="notificationsScroll"
      v-bar
      class="scroll-wrap scroll-notification noScrollDragger">
      <div>
        <div
          v-if="notifications.length == 0"
          class="no-notifications">
          <p>
            <svgicon
              name="bell"
              width="107"
              height="122"
              class="icon-bell"/>
          </p>
          <span>Уведомлений нет</span>
          Новые уведомления будут <br>появляться тут
        </div>
        <div class="items">
          <div
            v-for="(notification, index) in notifications"
            :key="index"
            class="item"
            :style="notification.url ? 'cursor: pointer' : ''"
            @click="openNotificationLink(notification)">
            <template v-if="!notification.is_read">
              <svgicon
                name="close"
                width="8"
                height="8"
                class="item-close"
                @click.stop="read(notification.id)"/>
              <div class="item-header">
                <div
                  v-if="notification.icon"
                  :class="iconType[notification.icon]"
                  class="item-icon">
                  <svgicon
                    :name="iconType[notification.icon]"
                    class="item-svg"/>
                </div>
                <h3 class="item-title">
                  {{ notification.title }}
                </h3>
              </div>
              <div class="item-content">
                {{ notification.text }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import '../../icons/chat-full';
  import '../../icons/notification-danger';
  import '../../icons/notification-success';
  import '../../icons/notification-info';
  import '../../icons/notification-warning';
  import '../../icons/close';
  import '../../icons/bell';
  import {mapGetters} from 'vuex';
  export default {
    data() {
      return {
        iconType: {
          1: 'notification-success',
          2: 'notification-danger',
          3: 'notification-warning',
          4: 'notification-info',
        },
        notificationsHeight: 0
      }
    },
    computed: {
      ...mapGetters({
        notifications: 'notifications/getNotifications',
        notificationsScrollTrigger: 'notifications/notificationsScrollTrigger',
      })
    },
    watch: {
      notificationsScrollTrigger: function () {
        setTimeout(()=>{
          if(document.getElementById('notificationsScroll').getElementsByClassName('items')[0]){
            this.notificationsHeight = document.getElementById('notificationsScroll').getElementsByClassName('items')[0].scrollHeight;
          }
        }, 0);
      },
    },
    mounted: function () {
      this.$nextTick(()=>{
        if(document.getElementById('notificationsScroll').getElementsByClassName('items')[0]){
          let scrollWrapper = document.getElementById('notificationsScroll').getElementsByClassName('vb-content')[0];
          this.dialogsHeight = document.getElementById('notificationsScroll').getElementsByClassName('items')[0].scrollHeight;
          scrollWrapper.addEventListener('scroll',() => {
            if((scrollWrapper.scrollTop + scrollWrapper.offsetHeight) == this.dialogsHeight){
              this.uploadMoreNotifications();
            }
          });
        }
      })
    },
    methods: {
      read: function(id){
        this.$store.dispatch('notifications/READ_NOTIFICATION', id);
      },
      uploadMoreNotifications: function(){
        this.$store.dispatch('notifications/GET_NOTIFICATIONS')
          .then((response) =>{
            if(!response.success){
              this.$noty.error('Ошибка сервера. ' + response.data);
            }
          })
          .catch((exception) => {
            this.$noty.error('Ошибка сервера. ' + exception.response);
          });
      },
      openNotificationLink(notification){
        if(notification.url){
          let gbUrl = 'https://gobuffy.com';
          let n = notification.url.indexOf(gbUrl);

          if(n !== -1){
            let link = notification.url.slice(n + gbUrl.length);
            this.$router.push(link);
          } else {
            window.location.replace(notification.url);
          }

          this.read(notification.id);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .scroll-wrap{
    position: absolute !important;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .header{
    position: relative;
    padding-top: 20px;
    padding-bottom: 19px;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid $sidebar-separator;
  }
  .header-icon{
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -8px;
    fill: rgba(#ffffff, .3)
  }
  .items{
    padding: 16px;
  }
  .item{
    position: relative;
    padding: 14px 16px;
    background: #181A28;
    border-radius: 4px;
    & + &{
      margin-top: 8px;
    }
  }
  .item-header{
    position: relative;
    margin-bottom: 7px;
  }
  .item-title{
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    line-height: calc(18/14);
    color: #FDFDFD;
    font-weight: 500;
  }
  .item-icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    text-align: center;
    + .item-title{
      padding-left: 43px;
      min-height: 32px;
    }
  }
  .item-svg{
    fill: #ffffff;
  }
  .notification-success{
    background: $green;
    .item-svg{
      width: 14px;
      height: 13px;
    }
  }
  .notification-danger{
    background: $red;
    .item-svg{
      width: 18px;
      height: 18px;
    }
  }
  .notification-warning{
    background: $yellow;
    .item-svg{
      width: 20px;
      height: 18px;
    }
  }
  .notification-info{
    background: $blue;
    .item-svg{
      width: 6px;
      height: 16px;
    }
  }
  .item-content{
    color: #8A8B92;
    font-size: 14px;
    line-height: calc(17/14);
  }
  .item-close{
    position: absolute;
    top: 10px;
    right: 10px;
    fill: #A8AAAF;
    cursor: pointer;
    z-index: 10;
    path{
      //stroke-width: 2;
    }
  }

  .no-notifications{
    padding-top: 55px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    line-height: 17px;
    font-weight: 500;
    color: #8A8B92;
    span{
      margin-bottom: 6px;
      font-size: 14px;
      color: #FDFDFD;
    }
  }
  .icon-bell{
    margin-bottom: 25px;
    fill: #181A28;
  }
</style>
