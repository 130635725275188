<template>
  <div class="user-avatar">
    <img
      v-if="user.photo && user.photo != ''"
      :src="user.photo"
      alt="">
    <span
      v-else
      class="no-avatar">
      {{ user.username.charAt(0) }}
    </span>
    <nav class="user-nav">
      <ul class="user-nav-list">
        <li
          v-if="user && parseInt(user.premium_expired_at) >= Math.floor(Date.now() / 1000)"
          class="user-nav-item">
          <nuxt-link
            :to="$i18n.path('profile/'+ user.jti + '/' )"
            class="user-nav-link">
            <span class="user-nav-icon">
              <svgicon
                name="profile"
                width="16"
                height="16"/>
            </span>
            Профиль
          </nuxt-link>
        </li>
        <li class="user-nav-item">
          <nuxt-link
            :to="$i18n.path('profile/settings')"
            class="user-nav-link">
            <span class="user-nav-icon">
              <svgicon
                name="settings"
                width="16"
                height="16"/>
            </span>
            Настройки
          </nuxt-link>
        </li>
        <li class="user-nav-item">
          <nuxt-link
            :to="$i18n.path('profile/offers')"
            class="user-nav-link">
            <span class="user-nav-icon">
              <svgicon
                name="portfolio"
                width="20"
                height="18"/>
            </span>
            Предложения
          </nuxt-link>
        </li>
        <li class="user-nav-item">
          <nuxt-link
            :to="$i18n.path('profile/purchases')"
            class="user-nav-link">
            <span class="user-nav-icon">
              <svgicon
                name="basket"
                width="20"
                height="20"/>
            </span>
            Покупки
          </nuxt-link>
        </li>
        <li class="user-nav-item">
          <nuxt-link
            :to="$i18n.path('profile/sales')"
            class="user-nav-link">
            <span class="user-nav-icon">
              <svgicon
                name="graph"
                width="22"
                height="12"/>
            </span>
            Продажи
          </nuxt-link>
        </li>
        <li class="user-nav-item">
          <nuxt-link
            :to="$i18n.path('profile/transactions')"
            class="user-nav-link">
            <span class="user-nav-icon">
              <svgicon
                name="check"
                width="18"
                height="20"/>
            </span>
            Операции
          </nuxt-link>
        </li>
        <li class="user-nav-item">
          <nuxt-link
            :to="$i18n.path('profile/reviews')"
            class="user-nav-link">
            <span class="user-nav-icon">
              <svgicon
                name="profile"
                width="16"
                height="16"/>
            </span>
            Отзывы
          </nuxt-link>
        </li>
        <li class="user-nav-item">
          <nuxt-link
            :to="$i18n.path('profile/disputes')"
            class="user-nav-link">
            <span class="user-nav-icon">
              <svgicon
                name="profile"
                width="16"
                height="16"/>
            </span>
            Споры
          </nuxt-link>
        </li>
        <li
          v-if="user && parseInt(user.premium_expired_at) >= Math.floor(Date.now() / 1000)" 
          class="user-nav-item">
          <nuxt-link
            :to="$i18n.path('profile/direct-buff')"
            class="user-nav-link">
            <span class="user-nav-icon">
              <svgicon
                name="ads"
                width="20"
                height="20"/>
            </span>
            Реклама
          </nuxt-link>
        </li>
        <li class="user-nav-item user-nav-item-exit">
          <a
            class="user-nav-link"
            @click="logOut">
            <span class="user-nav-icon">
              <svgicon
                name="exit"
                width="18"
                height="18"/>
            </span>
            Выйти
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>


<script>
  export default {
    props: {
      user: {
        type: Object,
        default: () => ({}),
      }
    },
    methods: {
      logOut: function(){
        this.$store.dispatch('auth/LOGOUT').then( success => {
          window.location.href = '/'
        });
      },
    }
  }
</script>

<style lang="scss" scoped>
  .user-avatar{
    position: relative;
    margin-left: 16px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    &:hover{
      .user-nav{
        display: block;
      }
    }
  }
  .no-avatar{
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #ffffff;
    text-align: center;
    font-weight: 600;
    line-height: 32px;
    text-transform: uppercase;
    font-size: 15px;
    color: $body-color;
  }
  .user-nav{
    position: absolute;
    top: 100%;
    right: -10px;
    width: 240px;
    padding-top: 20px;
    display: none;
    transition: $link-transition;
    z-index: 101;
  }
  .user-nav-list{
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
    list-style: none;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  .user-nav-link{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: $body-color;
    &:not([href]):not([tabindex]){
      color: $body-color;
    }
  }
  .user-nav-icon{
    margin-right: 17px;
    display: flex;
    width: 22px;
    height: 36px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .user-nav-icon svg{
    fill: #A8AAAF
  }
  .user-nav-item-exit{
    margin-top: 10px;
    padding-top: 9px;
    border-top: 1px solid #EFF1F4;
  }
</style>