/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification-danger': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M17.101 5.574a8.876 8.876 0 00-1.873-2.804A8.791 8.791 0 0012.42.897 8.625 8.625 0 009 .2a8.63 8.63 0 00-3.42.697A8.792 8.792 0 002.773 2.77 8.88 8.88 0 00.899 5.574 8.58 8.58 0 00.2 8.994a8.62 8.62 0 00.7 3.427 8.866 8.866 0 001.873 2.81 8.788 8.788 0 002.807 1.872A8.625 8.625 0 009 17.8a8.622 8.622 0 003.42-.697 8.79 8.79 0 002.808-1.873 8.862 8.862 0 001.873-2.81 8.618 8.618 0 00.7-3.426 8.582 8.582 0 00-.7-3.42zM3.787 12.409a6.128 6.128 0 01-1.02-3.415c0-1.126.279-2.17.837-3.129a6.179 6.179 0 012.268-2.273A6.108 6.108 0 019 2.758a6.03 6.03 0 013.438 1.04l-8.651 8.611zm10.954-.987a6.209 6.209 0 01-1.33 1.993c-.557.556-1.22 1-1.987 1.33A6.056 6.056 0 019 15.242a6.14 6.14 0 01-3.403-1.017l8.64-8.6a6.044 6.044 0 01.997 3.37c0 .845-.165 1.654-.493 2.427z" _fill="#fff"/>'
  }
})
