/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'portfolio': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<path pid="0" opacity=".7" d="M8 13v-1H1.01L1 16c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1H8zm10-9h-4.01V2l-2-2h-4l-2 2v2H2C.9 4 0 4.9 0 6v3c0 1.11.89 2 2 2h6V9h4v2h6c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-6 0H8V2h4v2z" _fill="#A8AAAF"/>'
  }
})
