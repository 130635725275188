export const state = () => ({
    outLink: null,
    isIdle: false,
});
export const mutations = {
    setOutLink(state, outLink) {
        state.outLink = outLink;
    },
    setIsIdle(state, isIdle) {
        state.isIdle = isIdle;
    }
};
export const getters = {
    getOutLink: (state) => {
        return state.outLink;
    },
    getIsIdle: (state) => {
        return state.isIdle;
    },
};
export const actions = {
    SET_OUT_LINK({ commit }, data) {
        commit('setOutLink', data);
    },
    SET_IS_IDLE({ commit }, data) {
        commit('setIsIdle', data);
    },
};