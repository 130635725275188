<template>
  <b-modal
    id="modalRestoreMail"
    v-model="show"
    :hide-footer="true"
    centered
    class="modalAuth">
    <div
      slot="modal-header"
      :header-border-variant="0"
      class="modalHeader">
      <svgicon
        name="close"
        width="14"
        height="14"
        class="closeModal"
        @click="show=false"/>
      <img
        src="/img/logoBlack.svg"
        alt="Gobuffy">
    </div>
    <div class="modalContent">
      <div class="modalIcon">
        <svgicon
          name="dialogs"
          width="26"
          height="21"
          class="iconMail"
          @click="show=false"/>
      </div>

      <h3>Письмо отправленно</h3>

      <div class="modalDescription">
        Мы отправили письма для восстановления доступа. Проверьте Ваш почту.
      </div>
    </div>
  </b-modal>
</template>

<script>
  import '../../../icons/dialogs';
  export default {
    data() {
      return {
        show: false,
      }
    }
  }
</script>
<style lang="scss" scoped>
  .modalIcon{
    margin: 15px auto 25px;
    width: 64px;
    height: 64px;
    text-align: center;
    line-height: 64px;
    background: $green;
    border-radius: 50%;
  }
  .iconMail{
    fill: #ffffff;
  }
</style>