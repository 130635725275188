<template>
  <div>
    <header
      v-if="showHeader"
      class="header header-base">
      <div class="content">
        <nuxt-link
          :to="$i18n.path('')"
          class="logo">
          <img
            src="/img/logo.svg"
            class="logo-full"
            alt="">
          <img
            src="/img/logo-short.svg"
            class="logo-short"
            alt="">
        </nuxt-link>
        <headerSearch/>
        <headerLang/>
        <headerBonus v-if="needHideBonusBanner"/>
        <headerNav/>
      </div>
      <headerUser/>
    </header>
    <modalLoginSocial ref="loginSocial"/>
    <template v-if="!user">
      <modalLogin :login-show="loginShow"/>
      <modalRegistrationSocial/>

      <modalRegistration :on-register="onRegister"/>
      <modalRestore :on-restore="onRestore"/>
      <modalRestoreMail v-if="!user"/>
      <modalRestoreCode
        :restore-status="restoreStatus"
        :restore-email="restoreEmail"
        :on-restore="onRestoreCode"
        :on-close="onCloseRestoreCodeModal"/>
      <modalRegistrationSuccess :register-activation="registerActivation"/>
      <modalNoEmail/>
      <modalProfileActivation/>
    </template>
    <modalConfirmPhone :redirect-to-rules="redirectToRules"/>
    <modalRegistrationCode
      :email="user && user.email ? user.email : registerEmail"
      :register-status="registerStatus"
      :on-register-activation="onRegisterActivation"/>
    <modalPaymentResult
      v-if="typeof $route.query.paymentResult != 'undefined'"
      :variant="$route.query.paymentResult"/>
    <modalPayment
      title="Пополнение баланса рекламного кабинета"
      pay-button-text="Пополнить"
      :show-price-changer="true"
      :is-ads="true"/>
  </div>
</template>

<script>
import headerUser from "~/components/header/headerUser.vue";
import headerSearch from "~/components/header/headerSearch.vue";
import headerNav from "~/components/header/headerNav.vue";
import headerLang from "~/components/header/headerLang.vue";
import headerBonus from "~/components/header/headerBonus.vue";
import { mapGetters } from "vuex";
import modalPayment from "~/components/modals/payment.vue";
import modalLoginSocial from "~/components/modals/auth/loginSocial";
import modalLogin from "~/components/modals/auth/login";
import modalRegistrationSocial from "~/components/modals/auth/registrationSocial";
import modalRegistration from "~/components/modals/auth/registration";
import modalRestore from "~/components/modals/auth/restore";
import modalRestoreMail from "~/components/modals/auth/restoreMail";
import modalRestoreCode from "~/components/modals/auth/restoreCode";
import modalRegistrationCode from "~/components/modals/auth/registrationCode";
import modalRegistrationSuccess from "~/components/modals/auth/registrationSuccess";
import modalNoEmail from "~/components/modals/auth/noEmail";
import modalProfileActivation from "~/components/modals/auth/profileActivation";
import modalConfirmPhone from "~/components/modals/phoneConfirm/modalPhone";
import modalPaymentResult from "~/components/modals/payment/paymentResult";

export default {
  components: {
    headerUser,
    headerSearch,
    headerNav,
    headerLang,
    modalLoginSocial,
    modalLogin,
    modalRegistrationSocial,
    modalRegistration,
    modalRestore,
    modalRestoreMail,
    modalRestoreCode,
    modalRegistrationCode,
    modalRegistrationSuccess,
    modalNoEmail,
    modalProfileActivation,
    modalConfirmPhone,
    modalPaymentResult,
    modalPayment,
    headerBonus,
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
    redirectToRules: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      registerStatus: false,
      registerActivation: false,
      restoreStatus: false,
      registerEmail: "",
      restoreEmail: "",
      loginShow: false,
      needHideBonusBanner: false,
      userLogin: false, //this.$store.getters.getUser
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  mounted() {
    let paymentResult = this.$route.query.paymentResult;
    if (
      typeof paymentResult != "undefined" &&
      ["success", "fail"].includes(paymentResult)
    ) {
      this.$bvModal.show("modalPaymentResult");
    }

    let timerId = setInterval(() => {
      if (this.$cookiz.get('hideBonusBanner')) {
        this.needHideBonusBanner = true;
        clearInterval(timerId);
      }
    }, 1000);
  },
  methods: {
    onRegister: function (data) {
      console.log(data);
      this.registerStatus = data.data;
      this.registerEmail = data.email;
      console.log(this.registerStatus);
      console.log(this.registerEmail);
    },
    onRegisterActivation: function (data) {
      this.registerActivation = data;
    },
    onRestore: function (data) {
      this.restoreStatus = data.status;
      this.restoreEmail = data.email;
    },
    onRestoreCode: function () {
      this.loginShow = true;
    },
    onCloseRestoreCodeModal: function () {
      this.restoreStatus = false;
      this.restoreEmail = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 64px;
  padding: 0 16px;
  background: $header-color;
  z-index: 1001;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-container {
  margin: 0 auto;
  width: 1345px;
  max-width: 100%;
}
.logo {
  position: relative;
  margin-right: 20px;
  width: 119px;
  height: 30px;
  overflow: hidden;
}
.content {
  display: flex;
  align-items: center;
}
.logo-full {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
}
.logo-short {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s;
}
</style>
