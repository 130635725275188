<template>
  <div
    :id="'newOfferModal' + _uid"
    class="newOffer">
    <span class="user-start-sale">
      Создать предложение
      <svgicon
        name="chevron-down"
        width="12"
        height="20"
        class="chevron-down"/>
    </span>
    <b-popover
      :id="'newOfferPopover' + _uid"
      :target="'newOfferModal' + _uid"
      triggers="hover focus"
      placement="bottom"
      @shown="onShow()"
      @hidden="onHide()">
      <div class="d-block text-center">
        <div
          v-if="instances < 2"
          class="newOffer-content">
          <b-button
            class="btn btn-success"
            @click="goTo">
            Создать предложение
          </b-button>
          <b-button
            class="btn btn-outline-secondary"
            @click="createAd">
            Создать рекламное объявление
          </b-button>
          <p class="ads-hint text-left">
            Рекламное объявление - это реклама вашего магазина на GB,
            отображаемая в выбранных вами категориях
          </p>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import "~/icons/chevron-down";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      newOfferTrigger: true,
      instances: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  mounted() {},
  methods: {
    onShow() {
      this.instances++;
    },
    onHide() {
      this.instances--;
    },
    goTo() {
      if (this.user.is_active) {
        return this.$router.push({
          path:
            this.user.user_agreement === 1
              ? "/profile/offers/new"
              : "/profile/offers/rules",
        });
      } else {
        this.$bvModal.show("modalRegistrationCode");
      }
    },
    createAd() {
      if (this.user.is_active) {
        return this.$router.push({
          path:
            this.user.user_agreement === 1
              ? "/profile/direct-buff"
              : "/profile/direct-buff/rules",
        });
      } else {
        this.$bvModal.show("modalRegistrationCode");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.newOffer {
  position: relative;
  display: inline-block;
  line-height: 40px;
  font-size: rem(14px);
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
  transition: $link-transition;
  + .avatar {
    margin-left: 15px;
  }
}
.icon-newOffer {
  margin-right: 7px;
  fill: #6e7279;
}

.newOffer-content {
  top: 100%;
  right: 0;
  padding-top: 5px;
  color: #6e7279;
  white-space: normal;
  z-index: 100;
  background: #ffffff;
}
.newOffer-wrap {
  width: 100%;
  padding: 16px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: #6e7279;
}
.newOffer-head {
  display: flex;
  margin-bottom: 8px;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 1;
  color: #6e7279;
  justify-content: space-between;
  border-bottom: 1px solid #eff1f4;
  a {
    color: $blue;
    cursor: pointer;
    &:hover {
      color: $blue;
    }
  }
}

.chevron-down {
  background: transparent;
  margin-left: 10px;
}

.user-start-sale {
  display: inline-block;
  padding: 0 15px;
  line-height: 40px;
  margin-right: 16px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  background: rgba(216, 216, 216, 0.1);
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: rgba(#6e7279, 0.5);
  }
}

.btn {
  width: 100%;
}
.btn-success {
  margin-bottom: 12px;
}

.btn-outline-secondary {
  background: transparent;
  margin-bottom: 10px;
}

p {
  font-size: 11px;
  line-height: 16px;
  color: #6e7279;
  margin-bottom: 0;
}
</style>
