<template>
  <div>
    <div class="bar">
      <div class="bar-nav">
        <div
          v-for="(item, index) in barNav"
          :key="index"
          :class="{ active: activeContent === item.name }"
          class="bar-nav-item"
          @click="showBar(index)">
          <span class="bar-nav-wrap">
            <svgicon
              :name="item.name"
              :width="item.navIcon.width"
              :height="item.navIcon.height"
              class="bar-nav-icon"/>
            <template v-if="item.name == 'dialogs' && allUnreadMessages != 0">
              <span
                v-if="allUnreadMessages < 100"
                class="count">
                {{ allUnreadMessages }}
              </span>
              <span
                v-else
                class="count"> ... </span>
            </template>
            <template
              v-if="item.name == 'notifications' && getNotificationsCount != 0">
              <span
                v-if="getNotificationsCount < 100"
                class="count">
                {{ getNotificationsCount }}
              </span>
              <span
                v-else
                class="count"> ... </span>
            </template>
          </span>
        </div>
      </div>
      <barDialogsMin :on-open-chat="onOpenChat"/>
    </div>

    <div
      :class="{ active: sideBar }"
      class="bar-content">
      <component :is="activeContent"/>
    </div>

    <div
      v-if="!needHideBonusBanner"
      :class="{ active: sideBar }"
      class="bonusBanner">
      <img
        src="/img/wallet-plus.svg"
        height="70">
      <div class="banner-text">
        <p class="text">
          Бонус <span class="yellow">3000 рублей</span><br>опытным продавцам
        </p>
        <nuxt-link
          :to="'/seller-landing'"
          class="link">
          Получить бонус >
        </nuxt-link>
      </div>
      <div
        class="closeButton"
        @click="hideBonusBanner">
        <img src="/img/bannerClose.svg">
      </div>
    </div>
  </div>
</template>
<script>
import "../../icons/notifications";
import "../../icons/dialogs";
import "../../icons/chat";
import dialogs from "../chat/bar";
import barDialogsMin from "../chat/barDialogsMin";
import notifications from "../notifications/notifications";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    dialogs,
    notifications,
    barDialogsMin,
  },
  data() {
    return {
      needHideBonusBanner: true,
      barNav: [
        {
          name: "notifications",
          navIcon: {
            width: "16",
            height: "20",
          },
          active: false,
        },
        {
          name: "dialogs",
          navIcon: {
            width: "20",
            height: "16",
          },
          active: false,
        },
        /*
          {
            name: 'chat',
            navIcon: {
              width: '20',
              height: '20'
            },
            active: false
          }
          */
      ],
    };
  },
  computed: {
    ...mapGetters({
      chatFull: "chat/chatFull",
      allUnreadMessages: "chat/allUnreadMessages",
      getNotificationsCount: "notifications/getNotificationsCount",
      sideBar: "sidebar/sideBarTrigger",
    }),
    ...mapState({
      activeContent: (state) => state.sidebar.sideBarContent,
    }),
  },
  created(){
    this.needHideBonusBanner = this.$cookiz.get("hideBonusBanner")?this.$cookiz.get("hideBonusBanner"):false;
  },
  methods: {
    showBar(index) {
      if (this.sideBar && this.activeContent == this.barNav[index].name) {
        this.$store.dispatch("sidebar/SIDEBAR_TRIGGER", false);
        this.$store.dispatch("chat/CLOSE_CHAT");
        setTimeout(() => {
          this.$store.dispatch("sidebar/SIDEBAR_CONTENT", "");
        }, 300);
      } else {
        this.$store.dispatch("sidebar/SIDEBAR_TRIGGER", true);
        this.$store.dispatch(
          "sidebar/SIDEBAR_CONTENT",
          this.barNav[index].name
        );
      }
    },
    onOpenChat() {
      this.$store.dispatch("sidebar/SIDEBAR_TRIGGER", true);
      this.$store.dispatch("sidebar/SIDEBAR_CONTENT", this.barNav[1].name);
    },
    hideBonusBanner: function() {
      this.$cookiz.set('hideBonusBanner', true, {
        path: '/',
        domain: '.' + (process.server ? req.headers.host : window.location.hostname),
        maxAge: 2147483647
      });
      this.needHideBonusBanner = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.bar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: rem(64px);
  background: $side-bar-color;
  z-index: 1002;
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: rem(2px);
    background: rgba(#3e4356, 0.5);
    content: "";
  }
}
.bar-nav {
  position: relative;
  padding-top: 8px;
  padding-bottom: rem(11px);
  &:after {
    position: absolute;
    bottom: 0;
    left: rem(19px);
    right: rem(15px);
    height: rem(1px);
    background: $sidebar-separator;
    content: "";
  }
}
.bar-nav-item {
  display: block;
  padding-top: rem(10px);
  padding-bottom: rem(10px);
  text-align: center;
  cursor: pointer;
  &.active {
    .bar-nav-icon {
      fill: #ffffff;
    }
  }
}
.bar-nav-icon {
  display: block;
  fill: $grey-light;
  transition: $link-transition;
  &:hover {
    fill: #ffffff;
  }
}

.bar-content {
  position: fixed;
  right: -400px;
  top: 0;
  bottom: 0;
  width: 320px;
  background: $side-bar-color;
  transition: 0.5s;
  z-index: 1002;
  &.active {
    right: 64px;
  }
}
.bar-nav-wrap {
  position: relative;
  display: inline-block;
}
.count {
  position: absolute;
  bottom: 100%;
  left: 100%;
  margin-bottom: -9px;
  margin-left: -9px;
  padding: 0 3px;
  min-width: 19px;
  height: 18px;
  background: $red;
  color: #ffffff;
  border: 2px solid $side-bar-color;
  border-radius: 24px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  line-height: 14px;
}

.bonusBanner {
  transition: 0.5s;
  position: fixed;
  bottom: 20px;
  right: calc(4rem + 20px);
  z-index: 9999;
  background: #202532;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 26px;
  padding-bottom: 26px;
  padding-left: 16px;
  padding-right: 64px;
  &.active{
    right: calc(4rem + 320px + 20px);
  }
}
.banner-text {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  .text{
    font-size: 18px;
    line-height: 110%;
    color: #FFFFFF;
    margin-bottom: 9px;
    .yellow{
      font-weight: 800;
      color: #FFB000;
    }
  }
}
.closeButton{
  position: absolute;
  top: 24px;
  right: 16px;
  cursor: pointer;
}
</style>
