import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5288b4d5 = () => interopDefault(import('../src/pages/administration.vue' /* webpackChunkName: "pages/administration" */))
const _458d8798 = () => interopDefault(import('../src/pages/administration/index.vue' /* webpackChunkName: "pages/administration/index" */))
const _149d61f9 = () => interopDefault(import('../src/pages/administration/ads/index.vue' /* webpackChunkName: "pages/administration/ads/index" */))
const _7091c862 = () => interopDefault(import('../src/pages/administration/blog/index.vue' /* webpackChunkName: "pages/administration/blog/index" */))
const _4718a31c = () => interopDefault(import('../src/pages/administration/disputes/index.vue' /* webpackChunkName: "pages/administration/disputes/index" */))
const _5bb5a74a = () => interopDefault(import('../src/pages/administration/games/index.vue' /* webpackChunkName: "pages/administration/games/index" */))
const _4ce98141 = () => interopDefault(import('../src/pages/administration/offers.vue' /* webpackChunkName: "pages/administration/offers" */))
const _7f7359f8 = () => interopDefault(import('../src/pages/administration/offers/index.vue' /* webpackChunkName: "pages/administration/offers/index" */))
const _e2bb66f2 = () => interopDefault(import('../src/pages/administration/offers/id/_id/index.vue' /* webpackChunkName: "pages/administration/offers/id/_id/index" */))
const _7f2aac36 = () => interopDefault(import('../src/pages/administration/offers/id/_id/edit.vue' /* webpackChunkName: "pages/administration/offers/id/_id/edit" */))
const _35c9afc6 = () => interopDefault(import('../src/pages/administration/offers/_game/index.vue' /* webpackChunkName: "pages/administration/offers/_game/index" */))
const _345660d8 = () => interopDefault(import('../src/pages/administration/offers/_game/_category/index.vue' /* webpackChunkName: "pages/administration/offers/_game/_category/index" */))
const _705c5b8f = () => interopDefault(import('../src/pages/administration/orders.vue' /* webpackChunkName: "pages/administration/orders" */))
const _32d3bad2 = () => interopDefault(import('../src/pages/administration/orders/index.vue' /* webpackChunkName: "pages/administration/orders/index" */))
const _7f5649ba = () => interopDefault(import('../src/pages/administration/orders/_id.vue' /* webpackChunkName: "pages/administration/orders/_id" */))
const _66c07fc2 = () => interopDefault(import('../src/pages/administration/transactions/index.vue' /* webpackChunkName: "pages/administration/transactions/index" */))
const _ea113c64 = () => interopDefault(import('../src/pages/administration/users.vue' /* webpackChunkName: "pages/administration/users" */))
const _173ad5d1 = () => interopDefault(import('../src/pages/administration/users/index.vue' /* webpackChunkName: "pages/administration/users/index" */))
const _2622a03c = () => interopDefault(import('../src/pages/administration/users/_id/index.vue' /* webpackChunkName: "pages/administration/users/_id/index" */))
const _4db20390 = () => interopDefault(import('../src/pages/administration/users/_id/adslist.vue' /* webpackChunkName: "pages/administration/users/_id/adslist" */))
const _c069fa10 = () => interopDefault(import('../src/pages/administration/users/_id/disputes/index.vue' /* webpackChunkName: "pages/administration/users/_id/disputes/index" */))
const _b2f20c40 = () => interopDefault(import('../src/pages/administration/users/_id/offers/index.vue' /* webpackChunkName: "pages/administration/users/_id/offers/index" */))
const _713ed902 = () => interopDefault(import('../src/pages/administration/users/_id/purchases/index.vue' /* webpackChunkName: "pages/administration/users/_id/purchases/index" */))
const _6e424148 = () => interopDefault(import('../src/pages/administration/users/_id/reviews/index.vue' /* webpackChunkName: "pages/administration/users/_id/reviews/index" */))
const _795ca979 = () => interopDefault(import('../src/pages/administration/users/_id/sales/index.vue' /* webpackChunkName: "pages/administration/users/_id/sales/index" */))
const _5d47919e = () => interopDefault(import('../src/pages/administration/users/_id/transactions/index.vue' /* webpackChunkName: "pages/administration/users/_id/transactions/index" */))
const _27e36354 = () => interopDefault(import('../src/pages/administration/ads/clicks.vue' /* webpackChunkName: "pages/administration/ads/clicks" */))
const _3995f6c7 = () => interopDefault(import('../src/pages/administration/ads/list.vue' /* webpackChunkName: "pages/administration/ads/list" */))
const _05dd074c = () => interopDefault(import('../src/pages/administration/ads/settings.vue' /* webpackChunkName: "pages/administration/ads/settings" */))
const _56e0a8cf = () => interopDefault(import('../src/pages/administration/blog/create.vue' /* webpackChunkName: "pages/administration/blog/create" */))
const _377d2f99 = () => interopDefault(import('../src/pages/administration/games/add.vue' /* webpackChunkName: "pages/administration/games/add" */))
const _04ef08c8 = () => interopDefault(import('../src/pages/administration/blog/edit/_id.vue' /* webpackChunkName: "pages/administration/blog/edit/_id" */))
const _07fe16dc = () => interopDefault(import('../src/pages/administration/games/_name/categories/index.vue' /* webpackChunkName: "pages/administration/games/_name/categories/index" */))
const _8b70c6c8 = () => interopDefault(import('../src/pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _a71b15cc = () => interopDefault(import('../src/pages/games.vue' /* webpackChunkName: "pages/games" */))
const _89957fc6 = () => interopDefault(import('../src/pages/games/index.vue' /* webpackChunkName: "pages/games/index" */))
const _158ea61f = () => interopDefault(import('../src/pages/games/_game/index.vue' /* webpackChunkName: "pages/games/_game/index" */))
const _67e06aad = () => interopDefault(import('../src/pages/games/_game/_category/index.vue' /* webpackChunkName: "pages/games/_game/_category/index" */))
const _f5260cdc = () => interopDefault(import('../src/pages/games/_game/_category/_offer.vue' /* webpackChunkName: "pages/games/_game/_category/_offer" */))
const _9285e87c = () => interopDefault(import('../src/pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _03ce38c5 = () => interopDefault(import('../src/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _0f8633ed = () => interopDefault(import('../src/pages/profile/direct-buff/index.vue' /* webpackChunkName: "pages/profile/direct-buff/index" */))
const _48f7f48f = () => interopDefault(import('../src/pages/profile/disputes/index.vue' /* webpackChunkName: "pages/profile/disputes/index" */))
const _6ca16537 = () => interopDefault(import('../src/pages/profile/offers/index.vue' /* webpackChunkName: "pages/profile/offers/index" */))
const _43d1d4c8 = () => interopDefault(import('../src/pages/profile/purchases/index.vue' /* webpackChunkName: "pages/profile/purchases/index" */))
const _6b7540d1 = () => interopDefault(import('../src/pages/profile/reviews/index.vue' /* webpackChunkName: "pages/profile/reviews/index" */))
const _2d2bf742 = () => interopDefault(import('../src/pages/profile/sales/index.vue' /* webpackChunkName: "pages/profile/sales/index" */))
const _947018fa = () => interopDefault(import('../src/pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _46384096 = () => interopDefault(import('../src/pages/profile/transactions/index.vue' /* webpackChunkName: "pages/profile/transactions/index" */))
const _c1a56ad6 = () => interopDefault(import('../src/pages/profile/direct-buff/rules/index.vue' /* webpackChunkName: "pages/profile/direct-buff/rules/index" */))
const _16c9c376 = () => interopDefault(import('../src/pages/profile/offers/new.vue' /* webpackChunkName: "pages/profile/offers/new" */))
const _3c298dad = () => interopDefault(import('../src/pages/profile/offers/old_new.vue' /* webpackChunkName: "pages/profile/offers/old_new" */))
const _211ceadc = () => interopDefault(import('../src/pages/profile/offers/rules.vue' /* webpackChunkName: "pages/profile/offers/rules" */))
const _48913b99 = () => interopDefault(import('../src/pages/profile/settings/notifications.vue' /* webpackChunkName: "pages/profile/settings/notifications" */))
const _61236660 = () => interopDefault(import('../src/pages/profile/offers/edit/_id.vue' /* webpackChunkName: "pages/profile/offers/edit/_id" */))
const _11de6930 = () => interopDefault(import('../src/pages/profile/purchases/_id.vue' /* webpackChunkName: "pages/profile/purchases/_id" */))
const _631a3bac = () => interopDefault(import('../src/pages/profile/sales/_id.vue' /* webpackChunkName: "pages/profile/sales/_id" */))
const _177938ca = () => interopDefault(import('../src/pages/profile/_userId/index.vue' /* webpackChunkName: "pages/profile/_userId/index" */))
const _06df61c2 = () => interopDefault(import('../src/pages/profile/_userId/_offerId.vue' /* webpackChunkName: "pages/profile/_userId/_offerId" */))
const _573be762 = () => interopDefault(import('../src/pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _15dffb43 = () => interopDefault(import('../src/pages/seller-landing/index.vue' /* webpackChunkName: "pages/seller-landing/index" */))
const _20ec53e0 = () => interopDefault(import('../src/pages/seller-rules.vue' /* webpackChunkName: "pages/seller-rules" */))
const _69025e19 = () => interopDefault(import('../src/pages/blog/_gameId/index.vue' /* webpackChunkName: "pages/blog/_gameId/index" */))
const _ea88230a = () => interopDefault(import('../src/pages/blog/_gameId/_articleId.vue' /* webpackChunkName: "pages/blog/_gameId/_articleId" */))
const _3f98faab = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1e5eb129 = () => interopDefault(import('../src/pages/_LANG/index.vue' /* webpackChunkName: "pages/_LANG/index" */))
const _2bc2b35a = () => interopDefault(import('../src/pages/_LANG/profile/old_settings.vue' /* webpackChunkName: "pages/_LANG/profile/old_settings" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/administration",
    component: _5288b4d5,
    children: [{
      path: "",
      component: _458d8798,
      name: "administration"
    }, {
      path: "ads",
      component: _149d61f9,
      name: "administration-ads"
    }, {
      path: "blog",
      component: _7091c862,
      name: "administration-blog"
    }, {
      path: "disputes",
      component: _4718a31c,
      name: "administration-disputes"
    }, {
      path: "games",
      component: _5bb5a74a,
      name: "administration-games"
    }, {
      path: "offers",
      component: _4ce98141,
      children: [{
        path: "",
        component: _7f7359f8,
        name: "administration-offers"
      }, {
        path: "id/:id",
        component: _e2bb66f2,
        name: "administration-offers-id-id"
      }, {
        path: "id/:id?/edit",
        component: _7f2aac36,
        name: "administration-offers-id-id-edit"
      }, {
        path: ":game",
        component: _35c9afc6,
        name: "administration-offers-game"
      }, {
        path: ":game/:category",
        component: _345660d8,
        name: "administration-offers-game-category"
      }]
    }, {
      path: "orders",
      component: _705c5b8f,
      children: [{
        path: "",
        component: _32d3bad2,
        name: "administration-orders"
      }, {
        path: ":id",
        component: _7f5649ba,
        name: "administration-orders-id"
      }]
    }, {
      path: "transactions",
      component: _66c07fc2,
      name: "administration-transactions"
    }, {
      path: "users",
      component: _ea113c64,
      children: [{
        path: "",
        component: _173ad5d1,
        name: "administration-users"
      }, {
        path: ":id",
        component: _2622a03c,
        name: "administration-users-id"
      }, {
        path: ":id/adslist",
        component: _4db20390,
        name: "administration-users-id-adslist"
      }, {
        path: ":id/disputes",
        component: _c069fa10,
        name: "administration-users-id-disputes"
      }, {
        path: ":id/offers",
        component: _b2f20c40,
        name: "administration-users-id-offers"
      }, {
        path: ":id/purchases",
        component: _713ed902,
        name: "administration-users-id-purchases"
      }, {
        path: ":id/reviews",
        component: _6e424148,
        name: "administration-users-id-reviews"
      }, {
        path: ":id/sales",
        component: _795ca979,
        name: "administration-users-id-sales"
      }, {
        path: ":id/transactions",
        component: _5d47919e,
        name: "administration-users-id-transactions"
      }]
    }, {
      path: "ads/clicks",
      component: _27e36354,
      name: "administration-ads-clicks"
    }, {
      path: "ads/list",
      component: _3995f6c7,
      name: "administration-ads-list"
    }, {
      path: "ads/settings",
      component: _05dd074c,
      name: "administration-ads-settings"
    }, {
      path: "blog/create",
      component: _56e0a8cf,
      name: "administration-blog-create"
    }, {
      path: "games/add",
      component: _377d2f99,
      name: "administration-games-add"
    }, {
      path: "blog/edit/:id?",
      component: _04ef08c8,
      name: "administration-blog-edit-id"
    }, {
      path: "games/:name/categories",
      component: _07fe16dc,
      name: "administration-games-name-categories"
    }]
  }, {
    path: "/blog",
    component: _8b70c6c8,
    name: "blog"
  }, {
    path: "/games",
    component: _a71b15cc,
    children: [{
      path: "",
      component: _89957fc6,
      name: "games"
    }, {
      path: ":game",
      component: _158ea61f,
      name: "games-game"
    }, {
      path: ":game/:category",
      component: _67e06aad,
      name: "games-game-category"
    }, {
      path: ":game/:category/:offer",
      component: _f5260cdc,
      name: "games-game-category-offer"
    }]
  }, {
    path: "/profile",
    component: _9285e87c,
    children: [{
      path: "",
      component: _03ce38c5,
      name: "profile"
    }, {
      path: "direct-buff",
      component: _0f8633ed,
      name: "profile-direct-buff"
    }, {
      path: "disputes",
      component: _48f7f48f,
      name: "profile-disputes"
    }, {
      path: "offers",
      component: _6ca16537,
      name: "profile-offers"
    }, {
      path: "purchases",
      component: _43d1d4c8,
      name: "profile-purchases"
    }, {
      path: "reviews",
      component: _6b7540d1,
      name: "profile-reviews"
    }, {
      path: "sales",
      component: _2d2bf742,
      name: "profile-sales"
    }, {
      path: "settings",
      component: _947018fa,
      name: "profile-settings"
    }, {
      path: "transactions",
      component: _46384096,
      name: "profile-transactions"
    }, {
      path: "direct-buff/rules",
      component: _c1a56ad6,
      name: "profile-direct-buff-rules"
    }, {
      path: "offers/new",
      component: _16c9c376,
      name: "profile-offers-new"
    }, {
      path: "offers/old_new",
      component: _3c298dad,
      name: "profile-offers-old_new"
    }, {
      path: "offers/rules",
      component: _211ceadc,
      name: "profile-offers-rules"
    }, {
      path: "settings/notifications",
      component: _48913b99,
      name: "profile-settings-notifications"
    }, {
      path: "offers/edit/:id?",
      component: _61236660,
      name: "profile-offers-edit-id"
    }, {
      path: "purchases/:id",
      component: _11de6930,
      name: "profile-purchases-id"
    }, {
      path: "sales/:id",
      component: _631a3bac,
      name: "profile-sales-id"
    }, {
      path: ":userId",
      component: _177938ca,
      name: "profile-userId"
    }, {
      path: ":userId/:offerId",
      component: _06df61c2,
      name: "profile-userId-offerId"
    }]
  }, {
    path: "/search",
    component: _573be762,
    name: "search"
  }, {
    path: "/seller-landing",
    component: _15dffb43,
    name: "seller-landing"
  }, {
    path: "/seller-rules",
    component: _20ec53e0,
    name: "seller-rules"
  }, {
    path: "/blog/:gameId",
    component: _69025e19,
    name: "blog-gameId"
  }, {
    path: "/blog/:gameId/:articleId",
    component: _ea88230a,
    name: "blog-gameId-articleId"
  }, {
    path: "/",
    component: _3f98faab,
    name: "index"
  }, {
    path: "/:LANG",
    component: _1e5eb129,
    name: "LANG"
  }, {
    path: "/:LANG/profile/old_settings",
    component: _2bc2b35a,
    name: "LANG-profile-old_settings"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
