<template>
  <div>
    <template v-if="user && (user.is_active == null || user.is_active == 0)">
      <div class="confirm-mail-banner">
        <i class="confirm-mail-banner-icon"/>
        Ваш аккаунт не активирован, нужно подтвердить почту
        <span v-b-modal.modalRegistrationCode>Подтвердить</span>
      </div>
    </template>
    <div :class="['wrapper', sideBar ? 'sideBarActive' : '']">
      <headerBase/>
      <sideBar/>
      <!-- TODO: Вынести в отдельный шаблон блога -->
      <div 
        v-if="showDarkBgTop()"
        :class="{
          'dark-bg-top': true, 
          'dark-bg-top_absolute': darkBgPositionAbsolute()
        }">
        <div class="main-container">
          <breadcrumbs
            v-if="!darkBgPositionAbsolute()"
            :class="showDarkBgTop ? 'white-breadcrumbs' : ''"/>
          <template v-if="darkBgRoutes.includes($route.name)">
            <template v-if="$route.name == 'blog'">
              <h1>
                GOBUFFY блог
              </h1>
              <span>Новости, статьи, советы, гайды</span>
            </template>
            <template v-if="$route.name == 'blog-gameId'">
              <h1>
                Блог {{ blogGameName }}
              </h1>
              <span>Новости, статьи, советы, гайды</span>  
            </template>           
          </template>  
        </div>
      </div>
      <div class="page">
        <div class="main-container">
          <breadcrumbs
            v-if="!showDarkBgTop() || darkBgPositionAbsolute()"
            :class="showDarkBgTop ? 'white-breadcrumbs' : ''"/>
          <nuxt/>
        </div>
      </div>
      <footerBase/>
    </div>
    <audio
      id="newMessageSound"
      volume="1"
      src="/sounds/message.wav?v=GB-141"/>
    <confirmation
      :text="'Вы переходите на сторонний сайт, который никак не связан с GOBUFFY. Сделки проведенные вне нашего сайта никак не защищены.'"
      unique="OutLink"
      @callback="goToLink()"/>
  </div>
</template>

<script>

import headerBase from "~/components/header/headerBase.vue";
import sideBar from "~/components/sidebar/sideBar.vue";
import footerBase from "~/components/footer/footerBase.vue";
import breadcrumbs from "~/components/breadcrumbs/breadcrumbs";
import Vue from "vue";
import globalMixin from "~/mixins/global";
import confirmation from "~/components/modals/confirmation.vue";
Vue.mixin(globalMixin);

import { mapState } from "vuex";

export default {
  middleware: ["breadcrumbs"],
  components: {
    headerBase,
    sideBar,
    footerBase,
    breadcrumbs,
    confirmation,
  },
  data() {
    return {
      darkBgRoutes: [
        'blog-gameId-articleId',
        'blog-gameId',
        'blog'
      ],
      darkBgPositionAbsoulteRoutes: [
        'blog-gameId-articleId'
      ]
    }
  },
  computed: mapState({
    user: state => state.auth.user,
    sideBar: state => state.sidebar.sideBar,
    blogGameName: state => state.blog.gameName
  }),
  methods: {
    showDarkBgTop() {
      return this.darkBgRoutes.indexOf(this.$route.name) !== -1;
    },
    darkBgPositionAbsolute() {
      return this.darkBgPositionAbsoulteRoutes.indexOf(this.$route.name) !== -1;
    },
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  min-height: calc(100vh - 64px);
  flex-direction: column;
}
.dark-bg-top {
  display: block;
  float: left;
  padding-top: 20px;
  background: #202532;
  width:100%;
  height: auto;
  h1 {
    margin-top: 60px;
    margin-bottom: 11px;
    display: block;
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
    span {
      text-transform: uppercase;
    }
  }
  span {
    display: block;
    width: 100%;
    float: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $grey-light;
    opacity: 0.7;
    margin-bottom: 60px;
    text-align: center;
  }
}
.dark-bg-top_absolute {
  position: absolute;
  top:0;
  left:0;
  z-index: 0;
  height: calc(225px + 64px);
}
.white-breadcrumbs {
  color: $grey-light;
}
</style>
