let serialize = function(obj, prefix) {
  var str = [],
    property;
  for (property in obj) {
    if (obj.hasOwnProperty(property)) {
      var key,
          value;

      if (Array.isArray(obj)) {
        key = prefix ? prefix + "[]" : property;
      } else {
        key = prefix ? prefix + "[" + property + "]" : property
      }
      
      value = obj[property];

      str.push((value !== null && typeof value === "object") ?
        serialize(value, key) :
        encodeURIComponent(key) + "=" + encodeURIComponent(value));
    }
  }
  return str.join("&");
}

export default serialize;