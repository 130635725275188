
import { setAuthToken } from '~/utils/auth'

export const actions = {
  nuxtServerInit({ dispatch, commit }, context) {
    return new Promise((resolve, reject) => {
      let accessToken = context.app.$cookiz.get('access-token') || null;
      let refreshToken = context.app.$cookiz.get('refresh-token') || null;

      commit('auth/setTokens', {
        accessToken: accessToken,
        refreshToken: refreshToken
      });

      if (accessToken !== null) {
        setAuthToken(accessToken);
        dispatch('auth/fetch', accessToken)
          .then(result => {
            console.log('[nuxtServerInit] Provided token is OK, token: ' + accessToken);
            return resolve(true);
          })
          .catch(error => {
            console.log('[nuxtServerInit] Provided token is invalid:', error);
            return resolve(false);
          })
      }
      else {
        console.log('[nuxtServerInit] cookies problem');
        return resolve(false);
      }
    });
  }
};