<template>
  <div class="wrapper">
    <div
      class="chat">
      <div
        v-if="!chatActive"
        class="content-wrapper">
        <svgicon
          name="chat-full-inside"
          width="264"
          height="212"
          class="icon-full-inside"/>
        <span>Выберите диалог для начала переписки</span>
      </div>
      <template
        v-if="chatActive">
        <chat/>
      </template>
    </div>
    <sendMessage v-if="chatActive"/>
  </div>
</template>


<script>
  import '../../icons/chat-full-inside';
  import {mapGetters} from 'vuex';
  import {dateFormatChat} from '~/services/chat'
  import {compareDate} from '~/services/chat'
  import sendMessage from './sendMessage';
  import chat from './chat';
  export default {
    components: {
      sendMessage,
      chat
    },
    data() {
      return{}
    },
    computed: {
      ...mapGetters({
        chat: 'chat/getChat',
        chatActive: 'chat/chatFullActive'
      }),
    },
    methods: {
      dateFormat: function(data){
        //console.log(Date.now().toDateString());
        return dateFormatChat(data);
      },
      compareTime: function(time1, time2){
        return compareDate(time1, time2);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .chat{
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100px;
  }
  .chat-wrapper{
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .wrapper{
    position: fixed;
    top: 64px;
    right: 384px;
    left: 0;
    bottom: 0;
    padding-bottom: 19px;
    background: $side-bar-color;
    border-right: 2px solid $sidebar-separator;
    overflow: hidden;
  }
  .list{
    position: relative;
    width: 736px;
    max-width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .content-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    span{
      width: 100%;
      margin-top: 22px;
      color: #A8AAAF;
    }
  }

  .icon-full-inside{
    fill: #181A28;
  }
  .avatar{
    margin-right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    img{
      display: block;
    }
  }
  .no-avatar{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #ffffff;
    text-align: center;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    font-size: 12px;
  }

  .head{
    padding-top: 21px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    padding-right: 16px;
  }
  .time{
    font-size: 12px;
    color: #A8AAAF;
  }

  .name{
    margin-right: 10px;
    color: $side-bar-user-color;
    font-size: 14px;
    font-weight: 500;
  }

  .item-content{
    margin-top: 6px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    line-height: calc(20/14);
    font-weight: 500;
    color: #A8AAAF;
  }
</style>