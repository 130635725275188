/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron-down': {
    width: 12,
    height: 20,
    viewBox: '0 0 12 8',
    data: '<path d="M1 1L6 6L11 1" stroke="#A8AAAF" fill="none" stroke-width="2"/>'
  }
})
