import io from 'socket.io-client';
import { changeFavicon } from '~/services/base.js';
import cookies from 'js-cookie';


export const state = () => ({
  chatList: [],
  chat: {
    messages: [],
    users: {}
  },
  chatInitiators: [],
  chatId: null,
  chatNumber: null,
  dialogsPage: 1,
  chatPage: 1,
  activeUser: null,
  activeUserId: null,
  activeUserAvatar: null,
  activeUserOnline: null,
  chatFull: false,
  chatMin: false,
  chatBar: false,
  chatFullActive: false,
  allUnreadMessages: 0,
  newMessageTrigger: false,
  scrollMessagesTrigger: false,
  scrollDialogsTrigger: false,
  makeNewChat: false,
  showNewMessageArea: false,
  isFirstInit: true,
  isFinalPage: false,
  isSocketConnected: false,
});

const SET_SHOW_NEW_MESSAGE_AREA = 'setShowNewMessageArea';
let socketChat;
if (process.env.PRODUCTION) {
  socketChat = io(process.env.socketChatURL, { reconnection: true, transports: ['websocket'] });
}
else {
  socketChat = io(process.env.socketChatURL, { reconnection: true });
}

export const mutations = {
  setChatList(state, data) {
    if (state.dialogsPage == 1) {
      state.chatList = data;
    }
    else {
      for (let i = 0; i < data.length; i++) {
        state.chatList.push(data[i]);
      }
      state.scrollDialogsTrigger = !state.scrollDialogsTrigger
    }

    if (data.length > 0) {
      state.dialogsPage++;
    } else {
      state.isFinalPage = true;
    }

  },
  [SET_SHOW_NEW_MESSAGE_AREA](state, status) {
    state.showNewMessageArea = status;
  },
  setChatId(state, id) {
    state.chatId = id;
  },
  setChatInitiators(state, initiators) {
    state.chatInitiators = initiators;
  },
  setActiveUserOnline(state, status) {
    state.activeUserOnline = status;
  },
  setNewChat(state, data) {
    state.chat.messages = [];
    state.chat.messages.unshift({
      id: 0,
      message: "Если вы проводите сделку вне сайта, то мы не сможем вернуть вам средства, если продавец окажется мошенником!",
      header: "Сделки вне сайта небезопасны и несут риски",
      role: "bot"

    });
    state.chat.users = data;
    state.makeNewChat = true;
  },
  resetNewChat(state) {
    state.makeNewChat = false;
  },
  setNewInChatList(store, data) {
    store.chatList.unshift(data);
    if (!store.makeNewChat) {
      store.allUnreadMessages++;
    }
  },
  updateChatList(store, data) {
    store.chatList[data.message.n].lastMessage = data.message.lastMessage;
    store.chatList[data.message.n].lastMessageTime = data.message.lastMessageTime;
    if (data.count) {
      store.chatList[data.message.n].unreadCounter++;
      store.allUnreadMessages++;
    }
    let lastMessage = store.chatList[data.message.n];
    store.chatList.splice(data.message.n, 1);
    store.chatList.unshift(lastMessage);
  },
  setChat(state, data) {
    if (state.chatPage == 0) {
      state.chat = data;
    }
    else {
      for (let i = data.messages.length; i > 0; i--) {
        state.chat.messages.unshift(data.messages[i - 1]);
      }
      state.scrollMessagesTrigger = !state.scrollMessagesTrigger
    }

    if (data.messages && data.messages.length > 0) {
      state.chatPage++;
    }
    else {
      state.isFinalPage = true;
      state.scrollMessagesTrigger = false;
      let botMessage = state.chat.messages.filter(m => m.role == "bot")[0];
      if (!botMessage) {
        state.chat.messages.unshift({
          id: 0,
          message: "Если вы проводите сделку вне сайта, то мы не сможем вернуть вам средства, если продавец окажется мошенником!",
          header: "Сделки вне сайта небезопасны и несут риски",
          role: "bot"

        });
      }

    }



  },
  setChatOnline(state, data) {
    console.log('Active userId:' + state.activeUserId);
    state.chatList[data.i].online = data.status;
    if (data.id == state.activeUserId) {
      state.activeUserOnline = data.status;
    }
  },
  setChatMessage(state, data) {
    state.chat.messages.push(data);
    state.newMessageTrigger = !state.newMessageTrigger
  },
  setChatInfo(state, data) {
    state.activeUser = data.username;
    state.chatId = data.chatId;
    state.activeUserAvatar = data.avatar;
    state.activeUserId = data.id;
    state.activeUserOnline = data.online;
  },
  removeChatFromList(state, payload) {
    state.chatList.find((item, index) => {
      console.log(item, index);
      if (parseInt(item.chatId) == parseInt(payload.chatId)) {

        return state.chatList.splice(index, 1);
      }
    });
  },
  resetChat(store) {
    store.chat = {
      messages: [],
      users: {}
    };
    store.chatId = null;
    store.activeUser = null;
    store.activeUserAvatar = null;
    store.activeUserOnline = null;
    store.activeUserId = null;
    store.chatPage = 0;
  },
  openChat(store) {
    store.chatBar = true;
    store.chatFull = false;
    store.showNewMessageArea = true;
  },
  readMessages(store, index) {
    if (store.chatList[index]) {
      store.allUnreadMessages -= store.chatList[index].unreadCounter;
      if (store.allUnreadMessages < 0) {
        store.allUnreadMessages = 0;
      }
      store.chatList[index].unreadCounter = 0;
    }
  },
  chatFullActive(store, data) {
    store.chatFullActive = data;
  },
  toggleChatFull(store) {
    if (store.chatBar) {
      store.chatFullActive = true;
    }
    store.chatBar = !store.chatBar;
    store.chatFull = !store.chatFull;
    store.scrollMessagesTrigger = !store.scrollMessagesTrigger;
  },
  chatBack(store) {
    store.chatBar = false;
  },
  closeChat(store) {
    store.chatFull = false;
    store.chatBar = false;
  },
  allUnreadMessages(store, data) {
    store.allUnreadMessages = data;
  },
  deactivationChat(store) {
    store.chatList = [];
    store.chat = {
      messages: [],
      users: {}
    };
    store.chatId = null;
    store.chatNumber = null;
    store.chatPage = 0;
    store.activeUser = null;
    store.activeUserId = null;
    store.activeUserAvatar = null;
    store.activeUserOnline = null;
    store.chatFull = false;
    store.chatMin = false;
    store.chatBar = false;
    store.chatFullActive = false;
    store.allUnreadMessages = 0;
    store.newMessageTrigger = false;
    store.scrollMessagesTrigger = false;
    store.dialogsPage = 1;
  },
  resetDialogsPage(state) {
    state.dialogsPage = 1;
  },
  addChatUser(store, user) {
    store.chat.users[user.id] = user;
  },
  setChatUserActiveStatus(store, payload) {
    if (store.chat.users[payload.userId]) {
      store.chat.users[payload.userId].isActive = payload.isActive;
    }
  },
  setIsFirstInit(store, isFirst) {
    store.isFirstInit = isFirst;
  },
  setChatUserOnlineStatus(store, payload) {
    if (store.chat.users && store.chat.users[payload.userId]) {
      store.chat.users[payload.userId].online = payload.isOnline;
      store.chat.users[payload.userId].disconnectedAt = payload.disconnectedAt;
    }
  },
  setIsSocketConnected(store, isConnected) {
    store.isSocketConnected = isConnected;
  }
};
export const getters = {
  getChatList: (state) => {
    return state.chatList;
  },
  getChat: (state) => {
    return state.chat;
  },
  chatFull: (state) => {
    return state.chatFull;
  },
  chatMin: (state) => {
    return state.chatMin;
  },
  chatBar: (state) => {
    return state.chatBar;
  },
  chatFullActive: (state) => {
    return state.chatFullActive;
  },
  getChatInfo(state) {
    return {
      user: state.activeUser,
      id: state.chatId,
      avatar: state.activeUserAvatar,
      online: state.activeUserOnline,
      initiators: state.chatInitiators
    }
  },
  allUnreadMessages(state) {
    return state.allUnreadMessages;
  },
  newMessageTrigger(state) {
    return state.newMessageTrigger;
  },
  scrollMessagesTrigger(state) {
    return state.scrollMessagesTrigger;
  },
  scrollDialogsTrigger(state) {
    return state.scrollDialogsTrigger;
  },
  getChatId(state) {
    return state.chatId;
  },
  getShowNewMessageArea(state) {
    return state.showNewMessageArea;
  },
  getChatUsers(state) {
    return state.chat.users;
  },
  getIsSocketConnected(state) {
    return state.isSocketConnected;
  }
};

export const actions = {
  INIT_CHAT({ commit, dispatch, state, rootState }) {
    let audioNewMessage = document.getElementById('newMessageSound');

    if (state.isFirstInit) {
      socketChat.on('user online', function (data) {
        for (let i = 0; i < state.chatList.length; i++) {
          if (state.chatList[i].chatWith == data.userId) {
            commit("setChatOnline", { i: i, status: true, id: data.userId });
          }
        }
        if (state.makeNewChat && state.activeUserId === data.userId) {
          commit("setActiveUserOnline", true);
        }
        commit("setChatUserOnlineStatus", { userId: data.userId, isOnline: true });
      });
      socketChat.on('user offline', function (data) {
        for (let i = 0; i < state.chatList.length; i++) {
          if (state.chatList[i].chatWith == data.userId) {
            commit('setChatOnline', { i: i, status: false, id: data.userId });
          }
        }
        if (state.makeNewChat && state.activeUserId === data.userId) {
          commit('setActiveUserOnline', false);
        }
        console.log(data);
        commit("setChatUserOnlineStatus", { 
          userId: data.userId, 
          isOnline: false, 
          disconnectedAt: data.disconnectedAt 
        });
      });
      socketChat.on('chat message', function (data) {
        let message = {};
        if (data.userInfo.jti != rootState.auth.user.jti) {
          audioNewMessage.play();
        }

        if (data.userInfo.jti != rootState.auth.user.jti && data.userInfo.jti == state.activeUserId) {
          if (state.makeNewChat && typeof (data.isNewChat) !== 'undefined' && data.isNewChat) {
            let newChatData = {};
            newChatData.chatId = data.chatId;
            newChatData.username = data.userInfo.username;
            newChatData.id = data.userInfo.jti;
            newChatData.avatar = data.userInfo.photo;
            newChatData.online = true;

            commit('setChatInfo', newChatData);

            message.title = data.userInfo.username;
            message.lastMessage = data.message;
            message.chatId = data.chatId;
            message.picture = data.userInfo.photo;
            message.unreadCounter = 0; //!!! проверить - сделать в других случаях
            commit('setNewInChatList', message);
            commit('setChatOnline', { status: true, i: 0, });
            changeFavicon(true);
          }
        }

        if (data.chatId == state.chatId) {
          message.id = data.messageId;
          message.message = data.message;
          message.userId = data.userInfo.jti;
          message.time = data.messageTime;
          commit('setChatMessage', message);
          socketChat.emit('read messages', { chatId: state.chatId }, () => { });
          if (!state.makeNewChat) {
            for (let i = 0; i < state.chatList.length; i++) {
              if (data.chatId == state.chatList[i].chatId) {
                message.n = i;
                message.lastMessage = data.message;
                message.lastMessageTime = Date.now();
                commit('updateChatList', { message: message, count: false });
                return;
              }
            }
          }
        }
        else {
          let findChat = false;
          message.lastMessageTime = Date.now();
          for (let i = 0; i < state.chatList.length; i++) {
            if (data.chatId == state.chatList[i].chatId) {
              message.n = i;
              message.lastMessage = data.message;
              commit('updateChatList', { message: message, count: true });
              changeFavicon(true);
              findChat = true;
              return;
            }
          }
          if (!findChat) {
            if (data.userInfo.jti != rootState.auth.user.jti) {
              message.title = data.userInfo.username;
              message.lastMessage = data.message;
              message.chatId = data.chatId;
              message.picture = data.userInfo.photo;
              message.unreadCounter = 1; //!!! проверить - сделать в других случаях
              commit('setNewInChatList', message);
              commit('setChatOnline', { status: true, i: 0, });
              changeFavicon(true);
            }

          }
        }
      });
      socketChat.on('disconnect', function () {
        commit('setIsSocketConnected', false);
        commit('resetDialogsPage');
      });
      socketChat.on('new user', data => {
        if (state.chatId == data.chatId) {
          commit('addChatUser', data.user);
        }
      });
      socketChat.on('connect', () => {
        dispatch('CONNECT_CHAT');
      });
      socketChat.on('admin leave chat', (payload) => {
        if (state.chatId == payload.chatId) {
          commit('setChatUserActiveStatus', {
            userId: payload.userId,
            isActive: false
          });
        }
      });
      commit('setIsFirstInit', false)
    }

    if (socketChat.connected) {
      dispatch('CONNECT_CHAT');
    }
    else {
      socketChat.open();
    }
  },
  CONNECT_CHAT({ state, rootState, commit, dispatch, }) {
    let accessToken = cookies.get('access-token');
    socketChat.emit('connect to chat', { jwt: accessToken }, function (data) {
      if (data.success) {
          commit('setIsSocketConnected', true);
          dispatch('GET_RECENT_CHATS').then(chatsResponse => {
            let countUnreadMessages = 0;

            for (let i = 0; i < chatsResponse.chats.length; i++) {
              countUnreadMessages += parseInt(chatsResponse.chats[i].unreadCounter);
            }

            commit('setChatList', chatsResponse.chats);
            commit('allUnreadMessages', countUnreadMessages);
            if(state.chatId)
            {
              dispatch('chat/GET_CHAT', state.chatId, { root: true })
            }
          })
      } else {
        dispatch('auth/REFRESH_TOKEN', {}, { root: true })
      }
    })
  },
  GET_CHAT({ state, rootState, commit, dispatch }, chatId) {
    commit('resetChat');
    let chatIndex = null;
    let userOnline = false;
    for (let i = 0; i < state.chatList.length; i++) {
      if (state.chatList[i].chatId == chatId) {
        chatIndex = i;
        userOnline = state.chatList[i].online;
        break;
      }
    }
    socketChat.emit('get chat messages', { chatId: chatId, page: 0 }, (data) => {
      if (data.success) {
        for (let key in data.users) {
          if (key != rootState.auth.user.jti) {
            data.users[key].chatId = chatId;
            commit('setChatInfo', data.users[key]);
            break;
          }
        }
        //if(data.messages.length == 0){
        //    return;
        //}
        let chat = {};
        chat.messages = data.messages;
        chat.users = data.users;
        commit('setChat', chat);
        commit('setChatInitiators', data.chatInfo.initiators);
      } else {
        //dispatch('auth/REFRESH_TOKEN');
      }
    });

    socketChat.emit('read messages', { chatId: chatId }, res => {
      if (res.success) {
        commit('readMessages', chatIndex);
      }
    });
  },

  MAKE_NEW_CHAT({ state, rootState, commit }, data) {
    data.chatId = '';
    commit('setChatInfo', data);
    commit('openChat');

    let users = {};
    users[data.id] = {};
    users[data.id].avatar = data.avatar;
    users[data.id].id = data.id;
    users[data.id].username = data.username;
    users[data.id].online = data.online;
    users[data.id].role = data.role;
    users[data.id].isActive = true;
    users[data.id].disconnectedAt = data.disconnectedAt ?? null;

    let user = rootState.auth.user;
    users[user.jti] = {};
    users[user.jti].avatar = user.photo;
    users[user.jti].id = user.jti;
    users[user.jti].username = user.username;
    users[user.jti].online = true;
    users[user.jti].role = user.role;
    users[data.id].isActive = true;

    commit('setNewChat', users);
  },
  GET_MORE_MESSAGES({ state, rootState, commit }, chatId) {
    socketChat.emit('get chat messages', { chatId: chatId, page: state.chatPage }, messagesResponse => {
      if (!messagesResponse || !messagesResponse.messages || !messagesResponse.messages.length === 0) {
        return;
      }

      let chat = {};
      chat.messages = messagesResponse.messages;
      chat.users = messagesResponse.users

      commit('setChat', chat);
    });
  },
  GET_MORE_DIALOGS({ state, commit, dispatch }) {
    if (!state.isFinalPage) {
      dispatch('GET_RECENT_CHATS').then(chatsResponse => {
        let countUnreadMessages = 0;

        for (let i = 0; i < chatsResponse.chats.length; i++) {
          countUnreadMessages += parseInt(chatsResponse.chats[i].unreadCounter);
        }

        commit('setChatList', chatsResponse.chats);
        commit('allUnreadMessages', countUnreadMessages);
      }).catch(chatsResponse => {
        console.log(chatsResponse);
      });
    }
  },
  GET_RECENT_CHATS({ state }) {
    return new Promise((resolve, reject) => {
      socketChat.emit('get recent chats', { page: state.dialogsPage }, chatsResponse => {
        if (chatsResponse.success) {

          return resolve(chatsResponse);
        }

        return reject(chatsResponse);
      });
    });
  },
  RESET_CHAT({ commit }) {
    commit('resetChat');
  },
  SEND_MESSAGE({ state, rootState, commit }, data) {
    if (state.chatId != '' && state.chatId != undefined) {
      socketChat.emit('chat message', { message: data, chatId: state.chatId }, () => {
      });
    }
    else {
      socketChat.emit('start private chat', { userId: rootState.auth.user.jti, chatWith: state.activeUserId, message: data }, data => {
        commit('setChatId', data.chatId);
        commit('setActiveUserOnline', data.online);
        let message = {};
        message.id = data.message.id;
        message.message = data.message.text;
        message.userId = data.message.userId;
        message.time = data.message.time;
        commit('setChatMessage', message);
        commit('setNewInChatList', {
          chatId: data.chatId,
          chatWith: state.activeUserId,
          lastMessage: data.message.text,
          lastMessageTime: data.message.time,
          online: data.online,
          picture: state.activeUserAvatar,
          title: state.activeUser,
          unreadCounter: 0
        })
      });
    }
  },
  OPEN_CHAT({ commit, state, rootState }) {
    if (!state.chatFull) {
      commit('openChat');
    }
    else {
      commit('chatFullActive', true)
    }
    if (rootState.notifications.notificationsCount == 0 && state.allUnreadMessages == 0) {
      changeFavicon(false);
    }
  },
  CHAT_BACK({ commit }) {
    commit('chatBack');
    commit('chatFullActive', false)
  },
  TOGGLE_CHAT_FULL({ commit }) {
    commit('toggleChatFull');
  },
  CLOSE_CHAT({ commit }) {
    return new Promise(resolve => {
      commit('resetChat');
      commit('closeChat');
      commit(SET_SHOW_NEW_MESSAGE_AREA, true);

      return resolve(true);
    });
  },
  DEACTIVATION_CHAT({ commit }) {
    socketChat.emit('disconnect from chat', {}, disconnectResponse => {
      if (disconnectResponse.success) {
        commit('deactivationChat');
      } else {
      }
    });
  },
  START_CHAT({ dispatch, rootState, commit }, user) {
    return new Promise(async (resolve, reject) => {
      if (!rootState.auth.user || !rootState.auth.user.jti) {
        await dispatch('auth/LOGOUT', null, { root: true });
        return reject('auth');
      }

      if (rootState.auth.user.jti == user.id) {
        return reject('selfchat');
      }

      return socketChat.emit('get chat id', { userId: rootState.auth.user.jti, anotherUserId: parseInt(user.id) }, (data) => {
        if (data.success) {
          if (data.chatId == null) {
            dispatch('MAKE_NEW_CHAT', data.anotherUser);
            dispatch('sidebar/SIDEBAR_TRIGGER', true, { root: true });
            dispatch('sidebar/SIDEBAR_CONTENT', 'dialogs', { root: true });

            return resolve(true);
          } else {
            commit('setChatInfo', user);
            dispatch('GET_CHAT', data.chatId);
            dispatch('OPEN_CHAT');
            dispatch('sidebar/SIDEBAR_TRIGGER', true, { root: true });
            dispatch('sidebar/SIDEBAR_CONTENT', 'dialogs', { root: true });

            return resolve(true);
          }
        }
        else {
          return reject(data);
        }
      });

    });
  },
  SOCKET_GET_CHAT_ID({ }, payload) {
    let userId = payload.userId,
      anotherUserId = payload.anotherUserId;

    return new Promise((resolve, reject) => {
      socketChat.emit('get chat id', { userId: userId, anotherUserId: anotherUserId }, (data) => {
        if (data.success) {

          return resolve(data);
        }

        return reject(data);
      });
    });
  },
  ADMIN_LISTEN_CHAT({ dispatch, rootState, commit }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('SOCKET_GET_CHAT_ID', { userId: payload.userId, anotherUserId: payload.anotherUserId }).then(chatIdResponse => {
        if (chatIdResponse.success == true) {
          if (chatIdResponse.chatId == null) {

            return resolve(null);
          } else {
            socketChat.emit('admin listen chat', { chatId: chatIdResponse.chatId }, listenResponse => {
              if (listenResponse.success) {
                dispatch('GET_CHAT', chatIdResponse.chatId);
                dispatch('OPEN_CHAT');
                dispatch('sidebar/SIDEBAR_TRIGGER', true, { root: true });
                dispatch('sidebar/SIDEBAR_CONTENT', 'dialogs', { root: true });
                commit(SET_SHOW_NEW_MESSAGE_AREA, false);

                return resolve(listenResponse);
              }

              return reject(listenResponse);
            });
          }
        }

        return reject(chatIdResponse);
      }).catch(err => {

        return reject(err);
      })
    });
  },
  ADMIN_JOIN_CHAT({ state, dispatch, rootState, commit }) {
    let initiators = rootState.chat.chatInitiators;

    socketChat.emit('admin join chat', { userId: initiators[0], anotherUserId: initiators[1], id: rootState.auth.user.jti }, joinResponse => {

      if (joinResponse.success) {
        dispatch('GET_CHAT', joinResponse.chatId);
        dispatch('OPEN_CHAT');
        commit(SET_SHOW_NEW_MESSAGE_AREA, true);
        commit('setNewInChatList', {
          chatId: state.chatId,
          chatWith: state.activeUserId,
          lastMessage: state.chat.messages[state.chat.messages.length - 1].text,
          lastMessageTime: state.chat.messages[state.chat.messages.length - 1].time,
          online: true,
          picture: state.activeUserAvatar,
          title: state.activeUser,
          unreadCounter: 0
        })
      }
    });
  },
  ADMIN_LEAVE_CHAT({ dispatch, rootState, commit }, payload) {
    socketChat.emit('admin leave chat', { chatId: payload.chatId }, leaveResponse => {
      if (leaveResponse.success) {
        commit(SET_SHOW_NEW_MESSAGE_AREA, false);
        commit('removeChatFromList', { chatId: payload.chatId });

        dispatch('CLOSE_CHAT').then(res => {
          dispatch('sidebar/SIDEBAR_TRIGGER', false, { root: true });
          setTimeout(() => {
            dispatch('sidebar/SIDEBAR_CONTENT', {}, { root: true });
          }, 300);
        });
      }
    });
  },
  SOCKET_RECONNECT(){
    socketChat.close();
    socketChat.open();
  },
}
