<template>
  <div>
    <template v-if="user && (user.is_active == null || user.is_active == 0)">
      <div class="confirm-mail-banner">
        <i class="confirm-mail-banner-icon"/>
        Ваш аккаунт не активирован, нужно подтвердить почту
        <span v-b-modal.modalRegistrationCode>Подтвердить</span>
      </div>
    </template>
    <div :class="['wrapper', sideBar ? 'sideBarActive' : '']">
      <headerBase ref="headerBase"/>
      <sideBar/>

      <profileNav
        v-if="
          user &&
            (user.jti == $route.params.userId ||
            profileLinks.some((r) => $route.path.indexOf(r.link) != -1))
        "
        :links="profileLinks"/>
      <div class="page">
        <div class="main-container">
          <breadcrumbs/>
          <nuxt/>
        </div>
      </div>

      <footerBase/>
    </div>
    <audio
      id="newMessageSound"
      volume="1"
      src="/sounds/message.wav?v=GB-141"/>
    <confirmation
      :text="'Вы переходите на сторонний сайт, который никак не связан с GOBUFFY. Сделки проведенные вне нашего сайта никак не защищены.'"
      unique="OutLink"
      @callback="goToLink()"/>
  </div>
</template>

<script>
import headerBase from "~/components/header/headerBase.vue";
import sideBar from "~/components/sidebar/sideBar.vue";
import footerBase from "~/components/footer/footerBase.vue";
import profileNav from "~/components/profile/profileNav.vue";
import breadcrumbs from "~/components/breadcrumbs/breadcrumbs";
import confirmation from "~/components/modals/confirmation.vue";

import { mapState } from "vuex";
import Vue from "vue";
import globalMixin from "~/mixins/global";
Vue.mixin(globalMixin);

export default {
  middleware: ["breadcrumbs"],
  components: {
    headerBase,
    sideBar,
    footerBase,
    profileNav,
    breadcrumbs,
    confirmation
  },
  data() {
    return {};
  },
  computed: mapState({
    user: (state) => state.auth.user,
    sideBar: (state) => state.sidebar.sideBar,
    profileLinks() {
      return [
        {
          id: 1,
          name: "Профиль",
          link: "profile/" + (this.user ? this.user.jti : "") + "/",
        },
        {
          id: 2,
          name: "Настройки",
          link: "profile/settings",
        },
        {
          id: 3,
          name: "Предложения",
          link: "profile/offers",
        },
        {
          id: 4,
          name: "Покупки",
          link: "profile/purchases",
        },
        {
          id: 5,
          name: "Продажи",
          link: "profile/sales",
        },
        {
          id: 6,
          name: "Операции",
          link: "profile/transactions",
        },
        {
          id: 7,
          name: "Отзывы",
          link: "profile/reviews",
        },
        {
          id: 8,
          name: "Споры",
          link: "profile/disputes",
        },
        {
          id: 9,
          name: "Реклама",
          link: "profile/direct-buff",
        },
      ];
    },
  }),
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  min-height: calc(100vh - 64px);
  flex-direction: column;
}
</style>
