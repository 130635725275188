/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bell': {
    width: 107,
    height: 122,
    viewBox: '0 0 107 122',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M50.5 0c-5.57 0-10.1 4.56-10.1 10.167v9.104C25.817 23.659 15.152 37.29 15.152 53.374v12.744c0 7.834-2.116 15.52-6.116 22.235L.361 102.9a2.556 2.556 0 00-.036 2.557 2.532 2.532 0 002.202 1.292h35.604a12.78 12.78 0 00-.255 2.542c0 7.01 5.66 12.709 12.624 12.709s12.625-5.699 12.635-12.709c0-.858-.086-1.71-.255-2.542h2.61a24.895 24.895 0 01-2.293-5.084H6.987l6.377-10.7a48.526 48.526 0 006.837-24.848V53.374c0-16.82 13.59-30.5 30.3-30.5 16.709 0 30.298 13.68 30.298 30.5v12.744c0 1.212.045 2.42.135 3.623a25 25 0 015.011-.72 43.512 43.512 0 01-.096-2.903V53.374c0-16.085-10.666-29.716-25.25-34.103v-9.104C60.6 4.56 56.07 0 50.5 0zm7.143 106.75H43.367a7.705 7.705 0 00-.436 2.542c0 4.204 3.398 7.625 7.574 7.625s7.575-3.421 7.575-7.625c0-.861-.151-1.731-.437-2.542zM50.5 17.791c-1.714 0-3.4.124-5.05.362v-7.986c0-2.806 2.262-5.084 5.05-5.084 2.787 0 5.05 2.278 5.05 5.084v7.986a35.244 35.244 0 00-5.05-.362z" _fill="#181A28"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M87 114c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20zm10.407-24.579a2 2 0 10-2.814-2.842l-9.704 9.607-3.482-3.447a2 2 0 10-2.814 2.842l4.889 4.84 1.407 1.393 1.407-1.393 11.111-11z" _fill="#181A28"/>'
  }
})
