<template>
  <div :class="{ chatFull: chatFull }">
    <div
      v-if="showNewMessageArea"
      class="block">
      <textarea
        id="areaMessage"
        v-model="message"
        class="message"
        placeholder="Напишите сообщение"
        @keydown="autoSize"
        @keyup.enter="sendMessage"/>
      <button
        class="submit btn-primary"
        @click="sendMessage">
        <svgicon
          name="chat-send"
          width="13"
          height="11"
          class="submit-icon"/>
      </button>
      <span
        v-if="error"
        class="error">
        {{ errorText }}
      </span>
    </div>
    <div
      v-else
      class="block text-center">
      <!-- TODO: добавить дизайн из макета-->
      <span
        class="btn btn-link btm-sm"
        style="cursor: pointer"
        @click="joinChat()">
        Присоединиться к чату
      </span>
    </div>
  </div>
</template>

<script>
import "../../icons/chat-send";
import { mapGetters } from "vuex";
import { scrollToBottomChat } from "~/services/chat";

export default {
  data() {
    return {
      message: "",
      error: false,
      errorText: ""
    };
  },
  computed: {
    ...mapGetters({
      chatFull: "chat/chatFull",
      chatBar: "chat/chatBar",
      showNewMessageArea: "chat/getShowNewMessageArea",
      chatInfo: "chat/getChatInfo"
    })
  },
  methods: {
    sendMessage: function(event) {
      if (this.message.length == 0 || this.message.trim().length == 0) {
        return;
      }
      this.$store.dispatch("chat/SEND_MESSAGE", this.message);
      this.message = "";
      document.getElementById("areaMessage").style.cssText = "height:28px";
      document.getElementById("chat-bar-wrapper").style.bottom = "64px";
    },
    autoSize: function(event) {
      let area = event.target;
      /*
        if(area.value.length > 999){
          this.error = true;
          this.errorText = 'Размер сообщения не должен привышать 1000 символов'
        }
        */
      if ((event.keyCode || event.which) == 13) {
        event.preventDefault();
        return;
      }
      setTimeout(() => {
        area.style.cssText = "height:0px";
        let height = Math.min(20 * 5, area.scrollHeight);
        area.style.cssText = "height:" + height + "px";

        if (!this.chatFull) {
          document.getElementById("chat-bar-wrapper").style.bottom =
            36 + height + "px";
          scrollToBottomChat("chatBarScroll");
        } else {
        }
      }, 0);
    },
    joinChat: function() {
      this.$store.dispatch("chat/ADMIN_JOIN_CHAT");
    }
  }
};
</script>

<style lang="scss" scoped>
.chatFull {
  position: absolute;
  bottom: 19px;
  left: 0;
  right: 0;
  .block {
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    width: 736px;
    max-width: 100%;
    margin: 0 auto;
  }
}
.block {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 18px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 60px;
  background: #181a28;
}
.message {
  width: 100%;
  height: 28px;
  border: none;
  background: transparent;
  color: #d0d4e0 !important;
  resize: none;
  overflow: auto;
  &:active,
  &:focus {
    outline: 1px solid transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #3e4356;
    margin: 5px 0px 5px 0;
    cursor: pointer;
    border: 5px solid rgba(0, 0, 0, 0);
    border-radius: 50px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    height: calc(100% - 10px);
    margin-right: 10px;
    padding-right: 5px;
  }
}
.submit {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.submit-icon {
  fill: #ffffff;
}
.error {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  text-align: center;
  color: $red;
  font-size: 8px;
}
.message-is-not-allowed {
  color: #a8aaaf;
  font-size: 12px;
  line-height: 20px;
}

</style>
