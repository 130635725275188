/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 18,
    height: 20,
    viewBox: '0 0 18 20',
    data: '<path pid="0" opacity=".7" d="M15 15H3v-2h12v2zm0-4H3V9h12v2zm0-4H3V5h12v2zM0 20l1.5-1.5L3 20l1.5-1.5L6 20l1.5-1.5L9 20l1.5-1.5L12 20l1.5-1.5L15 20l1.5-1.5L18 20V0l-1.5 1.5L15 0l-1.5 1.5L12 0l-1.5 1.5L9 0 7.5 1.5 6 0 4.5 1.5 3 0 1.5 1.5 0 0v20z" _fill="#A8AAAF"/>'
  }
})
