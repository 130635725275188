/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'instagram': {
    width: 26,
    height: 26,
    viewBox: '0 0 26 26',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.001.2C9.525.2 9.09.215 7.723.278 6.361.34 5.43.556 4.617.872a6.268 6.268 0 00-2.268 1.476A6.284 6.284 0 00.872 4.615C.554 5.43.338 6.36.277 7.722.216 9.087.2 9.524.2 13c0 3.477.015 3.912.077 5.277.063 1.363.279 2.293.595 3.107a6.272 6.272 0 001.476 2.267 6.269 6.269 0 002.267 1.477c.814.317 1.745.533 3.107.595 1.366.062 1.802.078 5.278.078 3.476 0 3.911-.016 5.276-.078 1.363-.062 2.294-.278 3.109-.595a6.265 6.265 0 002.266-1.477 6.284 6.284 0 001.477-2.267c.315-.814.53-1.745.595-3.107.061-1.365.077-1.8.077-5.277 0-3.476-.016-3.912-.077-5.278-.064-1.362-.28-2.292-.595-3.106a6.282 6.282 0 00-1.477-2.268A6.257 6.257 0 0021.384.872c-.816-.316-1.747-.532-3.11-.594C16.91.215 16.475.2 12.998.2h.004zm-1.148 2.306H13c3.418 0 3.823.012 5.173.074 1.248.057 1.925.265 2.376.44.597.233 1.023.51 1.471.958.448.448.725.875.958 1.472.175.45.384 1.128.44 2.376.062 1.35.075 1.755.075 5.17 0 3.417-.013 3.822-.074 5.171-.057 1.248-.266 1.926-.441 2.376a3.958 3.958 0 01-.958 1.47 3.958 3.958 0 01-1.47.958c-.452.176-1.13.384-2.377.441-1.35.062-1.755.075-5.173.075s-3.823-.013-5.172-.075c-1.248-.057-1.925-.266-2.377-.441a3.965 3.965 0 01-1.472-.957 3.967 3.967 0 01-.958-1.472c-.175-.45-.384-1.128-.44-2.376-.062-1.35-.074-1.754-.074-5.173 0-3.418.012-3.82.073-5.17.057-1.248.266-1.926.441-2.377.232-.597.51-1.024.958-1.472a3.973 3.973 0 011.472-.958c.451-.176 1.129-.384 2.377-.441 1.18-.053 1.638-.07 4.024-.072v.003zm7.98 2.125a1.536 1.536 0 100 3.073 1.536 1.536 0 000-3.073zm-6.832 1.796a6.574 6.574 0 10.001 13.147 6.574 6.574 0 00-.001-13.147zm0 2.306a4.267 4.267 0 110 8.534 4.267 4.267 0 010-8.534z" _fill="#292E3B"/>'
  }
})
