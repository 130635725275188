<template>
  <div>
    <div class="bar">
      <div class="bar-nav">
        <nuxt-link
          v-for="(item, index) in barNav"
          :key="index"
          v-b-popover.hover.top="item.placeholder"
          :to="$i18n.path(item.url)"
          :class="{ active: activeContent === item.name }"
          class="bar-nav-item"
          @click.native="clearCategoriesState()">
          <span
            class="bar-nav-wrap">
            <svgicon
              :name="item.name"
              :width="item.navIcon.width"
              :height="item.navIcon.height"
              class="bar-nav-icon"/>
          </span>
        </nuxt-link>
      </div>
    </div>

    <div
      :class="{ active: barActive }"
      class="bar-content">
      <component :is="activeContent"/>
    </div>
  </div>
</template>
<script>
  import '~/icons/dashboard';
  import '~/icons/users';
  import '~/icons/withdraw';
  import '~/icons/arbitration';
  import '~/icons/dialogs';
  import '~/icons/graph';
  import '~/icons/basket';
  import '~/icons/ads';
  import '~/icons/blog';
  
  import dialogs from '../chat/bar';
  import {mapState} from 'vuex';
  export default {
    components: {
    },
    data(){
      return {
        barNav: [
          {
            name: 'dashboard',
            placeholder: 'Дашбоард',
            url: 'administration/games?page=1',
            navIcon: {
              width: '18',
              height: '18'
            },
            active: false
          },
          {
            name: 'users',
            placeholder: 'Пользователи',
            url: 'administration/users',
            navIcon: {
              width: '22',
              height: '14'
            },
            active: false,
          },
          {
            name: 'withdraw',
            placeholder: 'Запросы на вывод',
            url: 'administration/transactions',
            navIcon: {
              width: '20',
              height: '20'
            },
            active: false,
          },
          {
            name: 'arbitration',
            placeholder: 'Арбитраж',
            url: 'administration/disputes',
            navIcon: {
              width: '22',
              height: '22'
            },
            active: false,
          },
          {
            name: 'dialogs',
            placeholder: 'Сообщения',
            url: 'administration',
            navIcon: {
              width: '20',
              height: '16'
            },
            active: false,
          },
          {
            name: 'graph',
            placeholder: 'Продажи',
            url: 'administration/orders',
            navIcon: {
              width: '22',
              height: '12'
            },
            active: false,
          },
          {
            name: 'basket',
            placeholder: 'Товары',
            url: 'administration/offers',
            navIcon: {
              width: '20',
              height: '20'
            },
            active: false,
          },
          {
            name: 'ads',
            placeholder: 'Direct buff',
            url: 'administration/ads/list',
            navIcon: {
              width: '24',
              height: '24'
            },
            active: false
          },
          {
            name: 'blog',
            placeholder: 'Blog Articles',
            url: 'administration/blog',
            navIcon: {
              width: '24',
              height: '24'
            },
            activle: false
          }
        ],
        barActive: false,
        activeContent: ''
      }
    },
    methods:{
      showBar(index){
        if(this.barActive && this.activeContent == this.barNav[index].name){
          this.barActive = false;
          this.$store.dispatch('chat/CLOSE_CHAT');
          setTimeout(()=>{
            this.activeContent = '';
          }, 300)
        }
        else{
          this.barActive = true;
          this.activeContent = this.barNav[index].name;
        }
      },
      clearCategoriesState: function() {
      this.$store
        .dispatch("games/CLEAR_STATE")
        .then(response => {
          this.$forceUpdate();
        });
      },
      onOpenChat(){
        this.barActive = true;
        this.activeContent = this.barNav[1].name;
      }
    }
  }
</script>
<style lang="scss" scoped>
  .bar{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: rem(64px);
    background: $side-bar-color;
    z-index: 1002;
  }
  .bar-nav{
    position: relative;
    padding-top: 14px;
    padding-bottom: rem(11px);
  }
  .bar-nav-item{
    display: block;
    padding-top: rem(10px);
    padding-bottom: rem(10px);
    text-align: center;
    cursor: pointer;
    &.active{
      .bar-nav-icon{
        fill: #ffffff;
      }
    }
  }
  .bar-nav-icon{
    display: block;
    fill: $grey-light;
    transition: $link-transition;
    &:hover{
      fill: #ffffff;
    }
  }

  .bar-content{
    position: fixed;
    right: -400px;
    top: 0;
    bottom: 0;
    width: 320px;
    background: $side-bar-color;
    transition: .5s;
    z-index: 101;
    &.active{
      right: 64px;
    }
  }
  .bar-nav-wrap{
    position: relative;
    display: inline-block;
  }
  .count{
    position: absolute;
    bottom: 100%;
    left: 100%;
    margin-bottom: -9px;
    margin-left: -9px;
    padding: 0 3px;
    min-width: 19px;
    height: 18px;
    background: $red;
    color: #ffffff;
    border: 2px solid $side-bar-color;
    border-radius: 24px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    line-height: 14px;
  }
</style>