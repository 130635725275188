<template>
  <nav
    v-if="user"
    class="nav profile-nav">
    <ul class="list container">
      <li
        v-for="(link, index) in links"
        :key="index"
        class="item">
        <nuxt-link
          v-if="$route.name.indexOf('profile') == -1"
          :to="
            $i18n.path(
              'administration/users/' +
                userId +
                '/' +
                link.link.split('/')[link.link.split('/').length - 1]
            )
          "
          :class="link.main ? 'link main' : 'link'">
          {{ link.name }}
        </nuxt-link>
        <nuxt-link
          v-else
          :to="$i18n.path(link.link)"
          :class="link.main ? 'link main' : 'link'">
          {{ link.name }}
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import axios from "axios";
export default {
  props: {
    links: {
      type: Array,
      default: () => ({}),
    },
  },
  data() {
    return {
      user: null,
      userId: null,
    };
  },
  watch: {
    userId: {
      handler: function (id) {
        if (id == undefined || !id) {
          return;
        }
        axios
          .get("/users/" + id)
          .then((response) => {
            if (response.data.success) {
              this.user = response.data.data;
            }
          })
          .catch((exception) => {
            console.log("Что-то пошло не так. " + exception.response);
            return exception.response;
          });
      },
      deep: true,
      immediate: true,
    },
  },
  created: async function () {
    if (this.$route.name == "administration-orders-id") {
      let orderResponse = await axios.get(
        "/orders/" + this.$route.params.id + "?expand=user"
      );
      if (
        orderResponse &&
        orderResponse.data &&
        orderResponse.data.data &&
        orderResponse.data.data.user
      ) {
        this.userId = orderResponse.data.data.user.id;
      }
    } else {
      if (this.$route.params.userId) {
        this.userId = parseInt(this.$route.params.userId);
      } else {
        this.userId = parseInt(this.$route.params.id);
      }

      if (!this.userId) {
        this.userId = parseInt(this.$store.state.auth.user.jti);
      }
    }
    if (this.userId) {
      await axios
        .get("/users/" + this.userId)
        .then((response) => {
          if (response.data.success) {
            this.user = response.data.data;
          }
        })
        .catch((exception) => {
          console.log("Что-то пошло не так. " + exception.response);
          return exception.response;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  top: 64px;
  left: 0;
  right: 64px;
  background: $side-bar-color;
  z-index: 1000;
}
.list {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;
  padding: 0 30px;
  list-style: none;
  font-size: 14px;
}
.item {
  margin-right: 48px;
}
.link {
  position: relative;
  display: inline-block;
  padding-top: 13px;
  padding-bottom: 14px;
  color: #a8aaaf;
  &:hover {
    color: #ffffff;
    &:after {
      background: #6e7279;
      opacity: 1;
    }
  }
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: $blue;
    content: "";
    transition: $link-transition;
    opacity: 0;
  }
  &.nuxt-link-active {
    color: $blue;
    &:after {
      opacity: 1;
    }
    &.main {
      color: #a8aaaf;
      &:after {
        opacity: 0;
      }
    }
  }
  &.main.nuxt-link-exact-active {
    color: $blue;
    &:after {
      opacity: 1;
    }
  }
}
</style>