/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification-warning': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.772 14.07L8.064 1.567c.907-1.556 2.964-1.556 3.872 0l7.292 12.503c.99 1.696-.111 3.93-1.936 3.93H2.708C.883 18-.218 15.766.772 14.07zm9.876-2.47l.152-2.67V5.2H9.2v3.73l.185 2.67h1.263zm-.446 1.267h-.37c-.21 0-.36.051-.447.152l-.024.03c-.066.08-.106.13-.106.37v.663c0 .267.04.456.12.566.086.101.235.152.446.152h.37c.21 0 .355-.05.435-.152.087-.11.13-.299.13-.566v-.663c0-.26-.03-.297-.113-.392l-.006-.008c-.08-.101-.225-.152-.435-.152z" _fill="#fff"/>'
  }
})
