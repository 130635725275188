/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification-success': {
    width: 14,
    height: 13,
    viewBox: '0 0 14 13',
    data: '<path pid="0" d="M.583 12.25h2.334v-7H.583v7zm12.834-6.417a1.17 1.17 0 00-1.167-1.167H8.57l.553-2.665.018-.187c0-.24-.1-.461-.257-.618L8.266.583 4.428 4.427a1.14 1.14 0 00-.345.823v5.833A1.17 1.17 0 005.25 12.25h5.25c.484 0 .899-.292 1.073-.712l1.762-4.112c.053-.135.082-.275.082-.426V5.833z" _fill="#fff"/>'
  }
})
